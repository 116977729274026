import React from 'react';
import { useHistory } from "react-router-dom";
import { Button }      from 'antd';
import { useMutation } from "@apollo/client";
import { GET_ROLES }   from 'graphql/query/role-gql';
import { ROLE_DELETE } from "graphql/mutation/role-gql";
import UserGroupConst     from '../../usergroup-const';
import {EntityRemoveButton, Localize} from 'components/service';
import { errorNotification, successNotification } from "components/request-result";



const basePath = UserGroupConst.basePath;

const UserGroupDeleteForm = ( { role } ) => {

    let history = useHistory();

    const [roleDeleteRequest, { loading }] = useMutation( ROLE_DELETE,
        {
            refetchQueries: [ GET_ROLES ],

            update( cache, { data } ) {

                const {
                    roleDelete : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title       : label,
                    description : message
                });

                history.push( `${ basePath }` );


            },
            onError( error ){
                errorNotification( error );
            }
        }

    );

    return(
        <>
            <EntityRemoveButton
                modalButton    = {
                    <Button className="ant-btn-secondary"><Localize>GROUP.Button_Text_Delete</Localize> </Button>
                }
                nameEntity     = { 'group' }
                dataNameEntity = { role.label }
                loading        = { loading }
                deleteMutation = { roleDeleteRequest }
                variables      = { {
                    id: role.id,
                } }
            />
        </>
    );

};

export default UserGroupDeleteForm;