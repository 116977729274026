import React from "react";
import { Form, Button, DatePicker, Radio } from 'antd';
import { craftUseMutation } from '../../hooks';
import CraftFormItems from '../fields';
import CraftConst from '../../craft-const';
import Stands from 'components/stands';
import Hall from 'components/hall';
import { Localize } from 'components/service';
import { timeFormat } from "utils/helper-functions";


const CraftFinishTimeForm = ({ event, standsSelectAll, stands, variables, hallID, action }) => {

    const [ form ] = Form.useForm();
    const valueFieldWhere = Form.useWatch('where', form);
    const valueFieldRadio = Form.useWatch('radio', form);
    const allCrafts = CraftConst.craftTypes.map(({ type }) => type);

    const {
        _setCraftActions,
        loadingMutationCraftActions,
    } = craftUseMutation.actions({ variables });


    return (
        <Form
            layout="vertical"
            form={ form }
            className="model-form"
            onFinish={ (values) => {

                _setCraftActions({
                    variables: {
                        action: 'finishing_time',
                        ee_id: event?.id,
                        stand_id: valueFieldWhere === 'stands_checkboxes' && stands.length > 0 ? stands : [],
                        hall_id: valueFieldWhere === 'stands_hall' && hallID ? hallID : undefined,
                        ...values,
                        craft_type: valueFieldRadio === 'choosen' ? values.craft_type : allCrafts,
                        value: timeFormat(values.value, 'YYYY-MM-DD HH:mm'),
                    }
                });

                action();
                form.resetFields();
            } }>

            <Stands.Forms.Fields.SelectWere standsSelectAll={ standsSelectAll } form={ form } stands={ stands } hallID={ hallID } />

            { valueFieldWhere === 'stands_hall' && !hallID &&
                <Hall.Forms.Fields.Select
                    form={ form }
                    ee_id={ event?.id } />
            }

            <Form.Item name='radio' initialValue={ 'choosen' }>
                <Radio.Group className="radio-modal">
                    <Radio value="choosen">
                        <Localize>FORMS.Radio_Label_ChoosenCraft</Localize>
                    </Radio>
                    <Radio value="all">
                        <Localize>FORMS.Radio_Label_AllCrafts</Localize>
                    </Radio>
                </Radio.Group>
            </Form.Item>

            { valueFieldRadio === 'choosen' &&
                <CraftFormItems.SelectType
                    mode="multiple"
                    name="craft_type"
                    form={ form }
                /> }


            <Form.Item
                name="value"
                label={ <Localize>FORMS.Input_Label_NewFinishingTime</Localize> }
                rules={ [ { required: true, message: <Localize>FORM_RULES.Required_DeliveryTime</Localize> } ] }
            >
                <DatePicker
                    minuteStep={ 15 }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                    showTime format="DD.MM.YYYY HH:mm"
                />
            </Form.Item>

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationCraftActions }
                >
                    <Localize>GLOBAL.Button_Text_Save</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default CraftFinishTimeForm;


