import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";

import Stands from "components/stands";
import Events from "components/events";
import { Tables } from "components/layout";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";
import { gqlBuilderWhere } from 'utils';


const basePath = Events.Const.basePath;
const tableHelper = Stands.Helpers.TableMain;


const EventStandsSubPage = ({ event }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: event?.title,
            path: `${ basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ basePath }/${ event?.id }${ Stands.Const.basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ event?.id }${ Stands.Const.basePath }`;

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const standsFilters = JSON.parse(localStorage.getItem(`standsFilters_eeID-${ event?.id }`));
    const standsSorting = JSON.parse(localStorage.getItem(`standsSorting_eeID-${ event?.id }`));
    const standsSearch = JSON.parse(localStorage.getItem(`standsSearch_eeID-${ event?.id }`));

    const [ searchText, setSearchText ] = useState(standsSearch);
    const [ filters, setFilters ] = useState(gqlBuilderWhere(standsFilters));
    const [ arrStands, setArrStands ] = useState([]);
    const [ arrSelectAll, setArrSelectAll ] = useState(false);
    const [ variables, setVariables ] = useState({});
    const [ resetSorting, setResetSorting ] = useState(false);

    const rowSelection = {
        onChange: (selectedRowKeys) => setArrStands(selectedRowKeys),
        onSelectAll: (allSelected) => {
            setArrSelectAll(allSelected);
        }
    };


    return (
        <Tables.Cursor
            extraClass="table-stands"
            tableLayout=""
            model="standsCursor"
            query={ GET_STANDS_CURSOR }
            varSet={ {
                perPage: 30,
                ee_id: +event?.id,
            } }
            routeUrl={ routeUrl }
            searchText={ searchText }
            objFilter={ filters }
            objOrderBy={ standsSorting || objOrderBy }
            sortingName={ `standsSorting_eeID-${ event?.id }` }
            setVariables={ setVariables }
            tableHelper={ tableHelper }
            rowSelection={ rowSelection }
            resetSorting={ resetSorting }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Stands.Filters.Table
                        event={ event }
                        setSearchText={ setSearchText }
                        setFilters={ setFilters }
                        filters={ filters }
                        standsFilters={ standsFilters }
                        searchText={ searchText }
                        setResetSorting={ setResetSorting }
                        objOrderBy={ objOrderBy }
                    />

                    <Stands.Actions.Dropdown event={ event } arrSelectAll={ arrSelectAll } arrStands={ arrStands } variables={ variables } />
                </div>

                <div className="col">
                    <Link
                        className="ant-btn ant-btn-primary"
                        to={ `${ routeUrl }/create` }
                    >
                        <PlusOutlined /> <Localize>STAND.Button_Text_Create</Localize>
                    </Link>
                </div>
            </div>
        </Tables.Cursor>
    );
};

export default EventStandsSubPage;