import { Switch } from 'antd';
import { eventUseMutation } from '../hooks/index';

const EventRadioField = ({ id, status }) => {

    const {
        _setEventCreateUpdate,
    } = eventUseMutation.createUpdate(id, false);

    return (
        <Switch
            checked={ status }
            onChange={
                (checked) => {
                    _setEventCreateUpdate({
                        variables: {
                            input: {
                                id,
                                status: checked
                            }
                        }
                    })
                }
            } />
    );
};

export default EventRadioField;
