import React, { useState } from "react";
import { Form, Input, Button, Radio } from 'antd';
import { userUseMutation } from '../../hooks';
import UserFormItems from '../fields'
import UserGroup from "components/usergroup";
import Subcontractor from "components/subcontractor";
import Events from "components/events";
import { Localize, EntityRemoveButton } from "components/service";


const UserEditForm = ({ user }) => {

    const [ form ] = Form.useForm();

    const {
        _setUserCreateUpdate,
        loadingMutationUserCreateUpdate: loading,
    } = userUseMutation.createUpdate(user?.id);

    const {
        _setMutationUserDelete,
        loadingMutationUserDelete,
    } = userUseMutation.delete(user?.id);


    const initRadioVal = user?.subcontractor_id ? 'subcontractor' : 'company';
    const [ radioValue, setRadioValue ] = useState(initRadioVal);


    const radioOnChange = e => {
        setRadioValue(e.target.value);
    };


    return (

        <Form
            key="edit-user-form"
            layout="vertical"
            form={ form }
            className="model-form edit-user-form"
            onFinish={ (values) => {

                _setUserCreateUpdate({
                    variables: {
                        input: {
                            id: user?.id ? +user.id : undefined,
                            ...values,
                            subcontractor_id: radioValue === 'subcontractor' ? (values.subcontractor_id?.value || values.subcontractor_id) : undefined,
                        }
                    }
                })
            } }>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name="name"
                    label={ <Localize>FORMS.Input_Label_Name</Localize> }
                    initialValue={ user?.name }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Name</Localize> }
                    ] }
                >
                    <Input name="name"  /*placeholder="Christian" */ />
                </Form.Item>

                <Form.Item
                    name="surname"
                    label={ <Localize>FORMS.Input_Label_Surname</Localize> }
                    initialValue={ user?.surname }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Surname</Localize> }
                    ] }
                >
                    <Input name="surname" /*placeholder="Glodek"*/ />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={ <Localize>FORMS.Input_Label_Phone</Localize> }
                    initialValue={ user?.phone }
                >
                    <Input name="phone"  /*placeholder="01728559198" */ />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={ <Localize>FORMS.Input_Label_Email</Localize> }
                    initialValue={ user?.email }
                    rules={ user?.id && [ { type: 'email', required: true, message: <Localize>FORM_RULES.Required_Email</Localize> } ] }
                >
                    <Input name="email"  /*placeholder="christin.glodek@fairnet.de" */ />
                </Form.Item>

                <UserFormItems.LanguageSelect initialValue={ user?.language_slug } />
            </div>

            <Form.Item
                initialValue={ radioValue }>
                <Radio.Group
                    value={ radioValue }
                    className="radio-main" onChange={ radioOnChange }
                >
                    <Radio value="company"><Localize>FORMS.Radio_Label_Company</Localize></Radio>
                    <Radio value="subcontractor"><Localize>FORMS.Radio_Label_Subcontractor</Localize></Radio>
                </Radio.Group>
            </Form.Item>

            { radioValue === 'company' ?
                <Form.Item
                    name="company"
                    initialValue={ user?.company }
                >
                    <Input name="company"  /*placeholder="Fairnet GmbH" */ />
                </Form.Item>
                :

                <Subcontractor.Forms.Fields.Select
                    label=''
                    form={ form }
                    initialValue={ user?.subcontractor_id ? { label: user?.subcontractor?.title, value: user?.subcontractor_id } : null } />
            }

            <UserGroup.Forms.Fields.GroupSelect
                form={ form }
                initialValue={ user?.role_id } />

            <Events.Forms.Fields.Select
                form={ form }
                initialValue={ user?.exhibitionEvent } />

            <div className="form-btn-holder">
                { user?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button className="ant-btn-secondary"><Localize>USER.Button_Text_Delete</Localize> </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                        dataNameEntity={ `${ user?.name } ${ user?.surname }` }
                        loading={ loadingMutationUserDelete }
                        deleteMutation={ _setMutationUserDelete }
                        variables={ {
                            id: user?.id,
                        } }
                    /> }

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { user?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>USER.Button_Text_Create</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default UserEditForm;


