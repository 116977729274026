import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { Menu, Dropdown } from 'antd';

import { SUBCONTRACTOR_DELETE } from "graphql/mutation/subcontractor-gql";
import { errorNotification, successNotification } from "components/request-result";

import SubcontractorConst from '../subcontractor-const';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';

import { EllipsisOutlined } from '@ant-design/icons';


const basePath = SubcontractorConst.basePath;

const SubcontractorActionField = ({ subcontractor }) => {

    const history = useHistory();

    const [ subcontractorDelete, { loading } ] = useMutation(SUBCONTRACTOR_DELETE,
        {

            update(cache, { data }) {

                const {
                    subcontractorDelete: {
                        label,
                        message
                    }
                } = data;

                cache.evict({
                    id: cache.identify({ id: subcontractor?.id, __typename: "Subcontractor" })
                });
                cache.gc();

                successNotification({
                    title: label,
                    description: message
                });
            },
            onError(error) {
                errorNotification(error);
            }
        }

    );

    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ basePath }/${ subcontractor.id }`)
                },
                {
                    key: '2',
                    icon: <EntityRemoveButton
                        modalButton={
                            <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Subcontractor" }).props.children }
                        dataNameEntity={ subcontractor.title }
                        loading={ loading }
                        deleteMutation={ subcontractorDelete }
                        variables={ {
                            id: subcontractor.id,
                        } }
                    />,
                },
            ] }
        />
    );

    return (
        <>
            <Dropdown
                overlay={ menu }
                placement="bottomRight"
                overlayClassName="drop-more-menu"
                arrow
            >
                <EllipsisOutlined className="btn-more" />
            </Dropdown>
        </>
    );

};

export default SubcontractorActionField;