import { useMyLanguageSlug } from "components/use-hooks";


const StandMessage = ({ log }) => {

    const { language } = useMyLanguageSlug();

    if (language === 'en') return log.msg_en
    if (language === 'de') return log.msg_de
};


export default StandMessage;