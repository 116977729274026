import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { STAND_DELETE } from "graphql/mutation/stand-gql";

import Events from 'components/events';
import Stands from 'components/stands';
import { successNotification, errorNotification } from "components/request-result";



const useMutationStandDelete = ({ standID, eventID }) => {


  const history = useHistory();

  const [ _setMutationStandDelete, { loading } ] = useMutation(STAND_DELETE,
    {
      update(cache, { data }) {

        const {
          standDelete: {
            label,
            message
          }
        } = data;

        history.push(`${ Events.Const.basePath }/${ eventID }${ Stands.Const.basePath }`);

        cache.evict({
          id: cache.identify({id: standID, __typename: "Stand" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationStandDelete,
    loadingMutationStandDelete: loading,
  }
};

export default useMutationStandDelete;