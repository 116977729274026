import React from "react";
import UserGroupForms from "components/usergroup/forms";
import UserGroups from "components/usergroup";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";


const basePath = UserGroups.Const.basePath;

const UserGroupOverViewSubPage = ({ role }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_UserGroups" }).props.children,
            path: `${ basePath }`
        },
        {
            label: role?.label,
            path: `${ basePath }/${ role?.id }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <div className="row-grid col-lg-2">
            <div className="col-left">
                <UserGroupForms.Edit role={ role } />
            </div>
        </div>
    );
};

export default UserGroupOverViewSubPage;