import JobFields from "./fields";
import JobForms from "./forms";
import JobConst from "./job-const";
import JobHelpers from "./helpers";
import JobFilters from "./filters";
import { jobUseMutation } from "./hooks";



const Job = {
  Fields: JobFields,
  Hooks: jobUseMutation,
  Forms: JobForms,
  Filters: JobFilters,
  Const: JobConst,
  Helpers: JobHelpers
}

export default Job;