import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useRouteMatch, Link } from "react-router-dom";

import { GET_HALLS } from "graphql/query/hall-gql";

import Hall from "components/hall";
import { Tables } from "components/layout";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const parrentPath = Hall.Const.parrentPath;
const basePath = Hall.Const.basePath;
const tableHelper = Hall.Helpers.TableMain;

const EventHallsSubPage = ({ event }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ parrentPath }`
        },
        {
            label: event?.title,
            path: `${ parrentPath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Halls" }).props.children,
            path: `${ parrentPath }/${ event?.id }${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ parrentPath }/${ event?.id }${ basePath }/page/:pageNum`);

    return (

        <Tables.Main
            model="halls"
            query={ GET_HALLS }
            varSet={ { perPage: 30, ee_id: event.id } }
            currentObj={ event }
            routeUrl={ `${ parrentPath }/${ event.id }${ basePath }` }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ "" }
            objFilter={ {} }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">
                <div className="col">
                    <Link
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        to={ `${ parrentPath }/${ event.id }${ basePath }/create` }
                    >
                        <PlusOutlined /> <Localize>HALL.Button_Text_Create</Localize>
                    </Link>
                </div>
            </div>
        </Tables.Main>
    );

}


export default EventHallsSubPage;