import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import HallForms from "../forms";
import HallConst from '../hall-const';
import Event from 'components/events';
import Icons from 'components/icons';
import {  Localize, ModalStandard } from "components/service";


const HallActionField = ({ hall }) => {

    const history = useHistory();


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ Event.Const.basePath }/${ hall.ee_id }${ HallConst.basePath }/${ hall.id }`)
                },
                {
                    key: '3',
                    icon:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>GLOBAL.Button_Text_Delete</Localize></span>
                            }>

                            <strong className="modal-title"><Localize>FORMS.Title_DeleteHall</Localize></strong>

                            <HallForms.Delete hall={ hall } />

                        </ModalStandard>
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default HallActionField;