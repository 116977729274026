import React from "react";

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import Subcontractor from "components/subcontractor";
import { Localize } from "components/service";


const basePath = Subcontractor.Const.basePath;

const SubcontractorCreatePage = () => {

    useActiveMenuItem([ "subcontractor" ], [ "subcontractor" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Subcontractors" }).props.children,
            path: `${ basePath }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: `${ basePath }/create`
        }
    ]
    
    useBreadCrumbs(breadcrumbs);

    return (

        <PageWrapDefault
            className="page-subcontractor"
            dataExist={ true }
            title={ <Localize>PAGES.Title_SubcontractorCreate</Localize> }
            staticPath={ `${ basePath }/create` }
        >
            <div className="row-grid col-lg-2">
                <div className="col-left">
                    <Subcontractor.Forms.Create />
                </div>
            </div>

        </PageWrapDefault>
    );
};

export default SubcontractorCreatePage;