import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function StandsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } >
                { ({ match, history }) => <Pages.Stand match={ match } history={ history } /> }
            </Route>

            <Route path={ `${ path }/create` }>
                <Pages.Stand />
            </Route>
        </Switch>
    );
}


