import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Input } from 'antd';
import { PlusOutlined, SearchOutlined, SnippetsOutlined } from "@ant-design/icons";

import { GET_USERS } from "graphql/query/user-gql";
import { EVENT_SYNC_USERS } from "graphql/mutation/event-gql";

import Events from 'components/events';
import Users from "components/users";
import { Tables } from "components/layout";
import { ModalStandard, Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";

const { Search } = Input;

const basePath = Events.Const.basePath;
const tableHelper = Events.Helpers.TableUsers;


const EventUsersSubPage = ({ event }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: event?.title,
            path: `${ basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ basePath }/${ event?.id }${ Users.Const.basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/${ event.id }/users/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");


    return (
        <Tables.Main
            model="users"
            query={ GET_USERS }
            varSet={ {
                ee_id: event?.id,
                perPage: 30,
            } }
            currentObj={ event }
            routeUrl={ `${ basePath }/${ event.id }/users` }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ {} }

            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Search
                        className="filter-search"
                        prefix={ <SearchOutlined /> }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_User" }).props.children }
                        onChange={ (e) => setSearchText(e.target.value) }
                    />
                </div>

                <div className="col">
                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <SnippetsOutlined /> <Localize>MODAL.Button_Text_CopyFromEvent</Localize>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_CopyFromEvent</Localize></strong>

                        <Users.Forms.CopyEvent
                            propsObj={ {
                                query: GET_USERS,
                                queryVar: {
                                    first: 10000,
                                    ee_id: event?.id,
                                },
                                mutation: EVENT_SYNC_USERS,
                                mutationName: "eeSyncToUsers",
                                model: "ee_id",
                                modelID: event?.id,
                            }
                            } />
                    </ModalStandard>

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <PlusOutlined /> <Localize>MODAL.Button_Text_AddUserEvent</Localize>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_AddUsers</Localize></strong>

                        <Users.Forms.Add
                            propsObj={ {
                                query: GET_USERS,
                                queryVar: {
                                    first: 10000,
                                    ee_id: event?.id,
                                },
                                mutation: EVENT_SYNC_USERS,
                                mutationName: "eeSyncToUsers",
                                model: "ee_id",
                                modelID: event?.id,
                            }
                            } />
                    </ModalStandard>

                </div>
            </div>
        </Tables.Main>
    );
};

export default EventUsersSubPage;
