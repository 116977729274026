import { gql } from '@apollo/client';


export const GET_JOBS = gql`
    query CraftJobs(
        $text: String
        $stand_id: ID
        $craft_id: ID
        $where: QueryCraftJobsWhereWhereConditions
        $orderBy: [QueryCraftJobsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ) {
        craftJobs(
        text: $text
        stand_id: $stand_id
        craft_id: $craft_id
        where: $where
        orderBy: $orderBy
        first: $first
        page: $page
        ) {
        paginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
        data {
            id
            type
            craft_id
            craft_type
            status
            number
            description
            attachment
            completor_id
            completed_at
            creator_id
            taken_id
            taken_at
            taken {
              id
              name
              surname
              role {
                id
                label
              }
            }
            completor {
              id
              name
              surname
              role {
                id
                label
              }
            }
            created_at
            creator {
              id
              name
              surname
              role {
                id
                label
              }
            }
            craft {
              id
              title
              type
            }
            updated_at
          }
       }
    }
`;
