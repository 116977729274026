import { useMutation } from "@apollo/client";

import { JOB_DELETE } from "graphql/mutation/job-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationJobDelete = ({ jobID }) => {

  const [ _setMutationJobDelete, { loading } ] = useMutation(JOB_DELETE,
    {
      update(cache, { data }) {

        const {
          craftJobDelete: {
            label,
            message
          }
        } = data;


        cache.evict({
          id: cache.identify({ id: jobID, __typename: "CraftJob" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },
    });

  return {
    _setMutationJobDelete,
    loadingMutationJobDelete: loading,
  }
};

export default useMutationJobDelete;