import React from "react";
import { useParams } from "react-router-dom";
import { Form, Input, DatePicker, Button } from "antd";
import moment from 'moment';
import Hall from 'components/hall';
import { Localize } from "components/service";

const { RangePicker } = DatePicker;


const StandFiltersForm = ({ action, event, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere, standsFilters, form }) => {

    const { eeID, hallID } = useParams();


    const initialDate = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return [ moment(val[ 0 ]), moment(val[ 1 ]) ];
    }


    const initialValFormatted = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return val.replace(/[^a-z0-9]/gi, '');
    }


    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        if (name === 'AQUISE_NUMBER' || name === 'PROAUF_NUMBER' || name === 'BLOCK' || name === 'CAD_NUMBER')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        } else
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }
    }

    return (
        <div className="ant-form ant-form-vertical filters-form" >
            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'AQUISE_NUMBER') }
                name="AQUISE_NUMBER"
                label={ <Localize>FORMS.Input_Label_Acquisition</Localize> }
                onChange={ onChangeSetFilter }>

                <Input name="AQUISE_NUMBER" placeholder={ Localize({ children: "FORMS.Input_Placeholder_AcqstnNumber" }).props.children
                } />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'PROAUF_NUMBER') }
                name="PROAUF_NUMBER"
                label={ <Localize>FORMS.Input_Label_Proauf</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="PROAUF_NUMBER" placeholder={ Localize({ children: "FORMS.Input_Placeholder_ProaufNumber" }).props.children } />
            </Form.Item>

            { !hallID && <Hall.Forms.Fields.Select
                initialValue={ initialValFormatted(standsFilters, 'HALL_ID') }
                form={ form }
                ee_id={ +eeID }
                name="HALL_ID"
                onChangeSetFilter={ onChangeSetFilter }
                rules={ false } /> }

            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'CORRIDOR') }
                name="CORRIDOR"
                label={ <Localize>FORMS.Input_Label_Corridor</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="CORRIDOR" placeholder={ Localize({ children: "FORMS.Input_Placeholder_CorridorNumber" }).props.children } />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'BLOCK') }
                name="BLOCK"
                label={ <Localize>FORMS.Input_Label_Block</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="BLOCK" placeholder={ Localize({ children: "FORMS.Input_Placeholder_BlockNumber" }).props.children } />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'STAND_NUMBER') }
                name="STAND_NUMBER"
                label={ <Localize>FORMS.Input_Label_StandNumber</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="STAND_NUMBER" placeholder={ Localize({ children: "FORMS.Input_Placeholder_StandNumber" }).props.children } />
            </Form.Item>

            <Form.Item
                initialValue={ initialValFormatted(standsFilters, 'CAD_NUMBER') }
                name="CAD_NUMBER"
                label={ <Localize>FORMS.Input_Label_CadNumber</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="CAD_NUMBER" placeholder={ Localize({ children: "FORMS.Input_Placeholder_CadNumber" }).props.children } />
            </Form.Item>

            <Form.Item
                initialValue={ initialDate(standsFilters, 'DELIVERY_TIME_AT') }
                name="DELIVERY_TIME_AT"
                label={ <Localize>FORMS.Input_Label_DeliveryTime</Localize> }
            >
                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                DELIVERY_TIME_AT: {
                                    column: "DELIVERY_TIME_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                DELIVERY_TIME_AT: {}
                            }) } />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...standsFilters, ...filterList }));
                        setCount({ ...standsFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(`standsFilters_eeID-${ event?.id }`);
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default StandFiltersForm;