import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { Form, Radio, Skeleton } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";
import Icons from "components/icons";
import MultiSelect from 'components/layout/form-elements/multi-select';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";


const StandRadioSelect = ({
    form,
    extraFields = false,
    valueFieldStandID = undefined,
    valueFieldHallID = undefined,
    valueFieldEventID = undefined,
    setBtnConfirm = () => { },
    rules = false,
    initialValue,
    placeholder = Localize({ children: "FORMS.Input_Placeholder_ChooseStand" }).props.children,
    name = 'stand_id',
    model = "standsCursor",
    label = ''
}) => {


    const [ searchStand, setSearchStand ] = useState("");

    let { data, loading, fetchMore } = useQuery(GET_STANDS_CURSOR, {
        variables: {
            text: useDebounce(searchStand),
            ee_id: valueFieldEventID,
            hall_id: valueFieldHallID ? valueFieldHallID : undefined,
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const {
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data,
            loading,
            fetchMore,
            label: 'exhibitor'
        });


    return (
        <Form.Item
            initialValue={ initialValue }
            name={ name }
            label={ label }
            rules={ rules && [ { required: true, message: `Please ${ placeholder }` } ] }
        >

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                form={ form }
                modalButton={

                    <span className="select-model-modal-button">
                        <span>
                            { valueFieldStandID ? nodes?.filter(
                                ({ value }) => value === valueFieldStandID)[ 0 ]?.label
                                :
                                <span className="placeholder-text">{ placeholder }</span>
                            }
                        </span>
                        { valueFieldStandID ? <SyncOutlined /> : <Icons.Arrow /> }
                    </span>
                }>

                <strong className="modal-title">{ placeholder }</strong>

                <MultiSelect
                    name={ name }
                    setBtnConfirm={ setBtnConfirm }
                    formReset={ () => form.resetFields([ name, 'hall_id' ]) }
                    extraFields={ extraFields }
                    setSearch={ setSearchStand }
                    search={ searchStand }
                    placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Stand" }).props.children }
                >

                    { searchStand && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                        <Radio.Group
                            className='group-radio radio-dark'
                            options={ nodes }
                            onChange={ e => form.setFieldsValue({ [ name ]: e.target.value }) }
                        />
                    }


                    { pageInfo?.hasNextPage &&

                        <>
                            { loading && <Loader style={ { marginTop: '-30px' } } /> }
                            <Waypoint onEnter={ () => fetchMoreAction() } />
                        </>
                    }

                </MultiSelect>
            </ModalStandard>
        </Form.Item>
    )
};

export default StandRadioSelect;