import Stand from "components/stands";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";

const standsColumns = [
    {
        title: <Localize>TABLES.Column_Title_Exhibitor</Localize>,
        dataIndex: 'exhibitor',
        columnIndex: 'EXHIBITOR',
        sorter: true,
        width: '14%',
    },
    {
        title: <Localize>TABLES.Column_Title_AcquiseNumber</Localize>,
        dataIndex: 'aquise_number',
        columnIndex: 'AQUISE_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_ProaufNumber</Localize>,
        dataIndex: 'proauf_number',
        columnIndex: 'PROAUF_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Corridor</Localize>,
        dataIndex: 'corridor',
        columnIndex: 'CORRIDOR',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Block</Localize>,
        dataIndex: 'block',
        columnIndex: 'BLOCK',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_StandNumber</Localize>,
        dataIndex: 'stand_number',
        columnIndex: 'STAND_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_CadNumber</Localize>,
        dataIndex: 'cad_number',
        columnIndex: 'CAD_NUMBER',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_DeliveryTime</Localize>,
        dataIndex: 'delivery_time_at',
        columnIndex: 'DELIVERY_TIME_AT',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Crafts</Localize>,
        dataIndex: 'crafts',
        columnIndex: 'CRAFTS',
        width: '10%',
    },
    {
        title: <Localize>TABLES.Column_Title_Materials</Localize>,
        dataIndex: 'materials',
        columnIndex: 'MATERIALS',
        width: '10.6%',

    },
    {
        // title: "action",
        dataIndex: 'action',
        columnIndex: 'ACTION',
        align: 'right',
        width: '5%',
    }
];


const standsData = (stands) => {


    if (!stands)
    {
        return [];
    }

    return stands.map(stand => {


        return {
            key: stand.id,
            exhibitor: <Stand.Fields.Title eeID={ stand.ee_id } id={ stand.id } title={ stand.exhibitor } />,
            aquise_number: stand.aquise_number ?? <strong>&mdash;</strong>,
            proauf_number: stand.proauf_number ?? <strong>&mdash;</strong>,
            corridor: stand.corridor?.length > 0 ? stand.corridor : <strong>&mdash;</strong>,
            block: stand.block?.length > 0 ? stand.block : <strong>&mdash;</strong>,
            stand_number: stand.stand_number?.length > 0 ? stand.stand_number : <strong>&mdash;</strong>,
            cad_number: stand.cad_number?.length > 0 ? stand.cad_number : <strong>&mdash;</strong>,
            delivery_time_at: timeFormat(stand?.delivery_time_at) ?? <strong>&mdash;</strong>,
            crafts: <Stand.Fields.Crafts stand={ stand } crafts={ stand?.crafts } />,
            materials: <Stand.Fields.Materials stand={ stand } />,
            action: <Stand.Fields.Action stand={ stand } />
        };

    });
};


const HallStandsTableHelper = {
    columns: standsColumns,
    data: standsData,
}

export default HallStandsTableHelper;