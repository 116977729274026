import React, { useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { GET_JOBS } from "graphql/query/job-gql";
import Job from 'components/job';
import { Tables } from "components/layout";

const lastMinuteBasePath = Job.Const.basePathLastMinute;
const tableHelper = Job.Helpers.TableMain([ 'type' ]);


const LastMinuteJobsSubPage = ({ stand, actionBarVisible = true, extraHtml, setVariables }) => {

    const routeUrl = lastMinuteBasePath;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);
    const currentPage = match ? parseInt(match.params.pageNum) : 1;
    const craftJobsCount = useLocation();

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();


    return (

        <Tables.Main
            model="craftJobs"
            query={ GET_JOBS }
            varSet={ {
                stand_id: stand?.id,
                perPage: 30,
            } }
            routeUrl={ routeUrl }
            currentPage={ currentPage }
            searchText={ searchText }
            setVariables={ setVariables }
            objFilter={ filters }
            objOrderBy={ [ { column: "CREATED_AT", order: "DESC" } ] }
            tableHelper={ tableHelper }
        >
            { extraHtml }
            { actionBarVisible &&

                <div className="table-action-bar">
                    <div className="col">
                        <Job.Filters.Table
                            standID={ stand?.id }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            craftJobsCount={ craftJobsCount.state } />
                    </div>

                    <div className="col"></div>

                </div>
            }

        </Tables.Main>
    );
};

export default LastMinuteJobsSubPage;

