import React from "react";
import { Form, Input, DatePicker, Button } from "antd";
import Hall from 'components/hall';
import Job from 'components/job';
import Craft from 'components/craft';
import { Localize } from "components/service";

const { RangePicker } = DatePicker;


const UserLogFiltersForm = ({ eeID, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere, form, action }) => {

    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        if (name === 'CRAFT_JOB_ID')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        } else
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "EQ",
                        value: value
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }
    }

    return (
        <div className="ant-form ant-form-vertical filters-form">

            <Form.Item
                name="CREATED_AT"
                label={ <Localize>FORMS.Input_Label_Time</Localize> }
            >
                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                    dateSrings[ 0 ] !== '' ?
                        setFilterList({
                            ...filterList,
                            CREATED_AT: {
                                column: "CREATED_AT",
                                operator: "BETWEEN",
                                value: dateSrings
                            }
                        }) : setFilterList({
                            ...filterList,
                            CREATED_AT: {}
                        }) } />
            </Form.Item>

            <Hall.Forms.Fields.Select
                form={ form }
                ee_id={ +eeID }
                initialValue={ 'Please Choose Hall' }
                name="HALL_ID"
                label={ <Localize>FORMS.Input_Label_Hall</Localize> }
                onChangeSetFilter={ onChangeSetFilter }
                rules={ false } />

            <Form.Item
                name="STAND_NUMBER"
                label={ <Localize>FORMS.Input_Label_StandNum</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="STAND_NUMBER" placeholder="Stand number" />
            </Form.Item>

            <Craft.Forms.Fields.SelectType
                form={ form }
                rules={ false }
                initialValue={ Localize({ children: "FORMS.Input_Placeholder_ChooseType" }).props.children
                }
                name="CRAFT_TYPE"
                onChangeSetFilter={ onChangeSetFilter }
            />

            <Form.Item
                name="CRAFT_JOB_ID"
                label={ <Localize>FORMS.Input_Label_JobNum</Localize> }
                onChange={ onChangeSetFilter }>
                <Input name="CRAFT_JOB_ID" placeholder="Booth number" />
            </Form.Item>

            <Job.Forms.Fields.SelectType
                form={ form }
                rules={ false }
                initialValue='Choose type'
                name="CRAFT_JOB_TYPE"
                onChangeSetFilter={ onChangeSetFilter }
            />

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere(filterList));
                        setCount(filterList);
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        form.resetFields([ 'CRAFT_JOB_TYPE', 'CRAFT_JOB_ID', 'CREATED_AT', 'HALL_ID', 'STAND_NUMBER', 'CRAFT_TYPE' ]);
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default UserLogFiltersForm;