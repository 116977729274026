import { gql } from '@apollo/client';

export const GET_PERMISSIONS = gql`
    query GetPermissions(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryPermissionsOrderByOrderByClause!],
        $where: QueryPermissionsWhereWhereConditions,
    ) {
        permissions(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                label
                panel
                type
                value
                description
                created_at
                updated_at
            }
        }
    }
`;