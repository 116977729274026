// import UserGroupTables from "./table";
import UserGroupFields  from "./fields";
import UserGroupForms   from "./forms";
import UserGroupConst   from "./usergroup-const";
import UserGroupHelpers from "./helpers";

const UserGroup = {
    // Tables  : UserGroupTables,
    Fields  : UserGroupFields,
    Forms   : UserGroupForms,
    Const   : UserGroupConst,
    Helpers : UserGroupHelpers
}

export default UserGroup;