import UserGroupEditForm   from "./usergroup-edit-form";
import UserGroupCreateForm from "./usergroup-create-form";
import UserGroupDeleteForm from "./usergroup-delete-form";
import UserGroupFormItems from "./fields";

import './usergroup.scss';

const UserGroupForms = {
    Edit   : UserGroupEditForm,
    Create : UserGroupCreateForm,
    Delete: UserGroupDeleteForm,
    Fields: UserGroupFormItems,
};

export default UserGroupForms;