import { Loading3QuartersOutlined } from '@ant-design/icons';
import { furnishingUseMutation } from '../hooks';
import Icons from 'components/icons';
import { UploadFile, Localize, ModalStandard } from 'components/service';
import { Link } from 'react-router-dom';



const FurnishingImageField = ({ furnishing }) => {

    const fileListItem = [
        {
            field: 'image',
            label: <Localize>ACTIONS.List_Item_Label_Image</Localize>,
        } ]
        .map(({ field, label }) => {

            const {
                _setFurnishingUploadFile,
                loadingMutationFurnishingUploadFile,
            } = furnishingUseMutation.uploadFile({ id: furnishing?.id });


            return (

                <li key={ field }>

                    { furnishing?.[ field ] ?

                        <div className='download-file'>
                            <ModalStandard
                                width={ 600 }
                                extraClass={ 'modal-form' }
                                modalButton={
                                    <Link to='#'><Icons.Download /> <span className='link-text'>{ label }</span></Link>
                                }>

                                <strong className='modal-title'>{ furnishing?.title }</strong>

                                <img src={ furnishing?.[ field ] } alt="" />

                            </ModalStandard>

                            <span onClick={ () => {
                                _setFurnishingUploadFile({
                                    variables: {
                                        input: {
                                            model: 'Furnishing',
                                            model_id: furnishing.id,
                                            model_field: field,
                                            file: null
                                        }
                                    }
                                })
                            } }>

                                { loadingMutationFurnishingUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }
                            </span>
                        </div>
                        :
                        <UploadFile
                            setMutation={ _setFurnishingUploadFile }
                            loading={ loadingMutationFurnishingUploadFile }
                            accept="image/*"
                            varSetMutation={ {
                                input: {
                                    model: 'Furnishing',
                                    model_id: furnishing.id,
                                    model_field: field,
                                }
                            } }
                            uploadBtn={
                                <div className='upload-file'>
                                    <span className='link-text'>{ label }</span>
                                    { loadingMutationFurnishingUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Import /> }
                                </div>
                            } />
                    }

                </li>
            )
        });


    return (
        <ul className='download-file-list' style={ { width: '140px' } }>

            { fileListItem }

        </ul>

    );
};

export default FurnishingImageField;