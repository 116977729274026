import FurnishingFields from "../fields";
import { Localize } from "components/service";


const furnishingColumns = [
    {
        title: <Localize>TABLES.Column_Title_FurnishingDescription</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Sku</Localize>,
        dataIndex: 'sku',
        columnIndex: 'SKU',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Quantity</Localize>,
        dataIndex: 'qty',
        columnIndex: 'QTY',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_LastMinutePrice</Localize>,
        dataIndex: 'price',
        columnIndex: 'PRICE',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Image</Localize>,
        dataIndex: 'image',
        columnIndex: 'IMAGE',
        // sorter: true,
    },
    {
        // title: "action",
        dataIndex: 'action',
        columnIndex: 'ACTION',
        align: 'right',
        width: '4%',
    }
];



const furnishingData = (furnishings, currentObj, variables) => {


    if (!furnishings)
    {
        return [];
    }

    return furnishings?.map(furnishing => {

        return {
            key: furnishing.id,
            title: <FurnishingFields.Title furnishing={ furnishing } variables={ variables } /> ?? <strong>&mdash;</strong>,
            sku: furnishing.sku ?? <strong>&mdash;</strong>,
            image: <FurnishingFields.Image furnishing={ furnishing } variables={ variables } />,
            price: furnishing.price ? furnishing.price + ' EUR' : <strong>&mdash;</strong>,
            qty: furnishing.qty ? furnishing.qty + (currentObj.language_slug === 'en' ? ' pcs.' : ' st.') : <strong>&mdash;</strong>,
            action: <FurnishingFields.Action furnishing={ furnishing } variables={ variables } />
        };

    });
};


const FurnishingTableHelper = {
    columns: furnishingColumns,
    data: furnishingData,
}

export default FurnishingTableHelper;