import { Button, Dropdown, Menu } from 'antd';
import { useParams } from 'react-router-dom';
import StandForms from '../../forms';
import Icons from "components/icons";
import Craft from 'components/craft';
import { ModalStandard, Localize } from 'components/service';


const StandDropdownActions = ({ event, arrSelectAll, arrStands, variables }) => {

    const { hallID } = useParams();

    const menu = (
        <Menu
            items={ [
                {
                    key: '1',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Stands</Localize>: </strong><Localize>MODAL.Button_Text_DeliveryTime</Localize></span>
                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_DeliveryTime</Localize> </strong>

                            <StandForms.DeliveryTime event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '2',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_CraftTime</Localize></span>
                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_CraftTime</Localize></strong>

                            <Craft.Forms.FinishTime event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '3',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_AssignSubcontractors</Localize></span>
                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_AssignSubcontractors</Localize></strong>

                            <Craft.Forms.AssignCreate event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } operation='assign_sub' hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '4',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_CraftDone</Localize></span>
                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_CraftDone</Localize></strong>

                            <Craft.Forms.Action event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } operation='done' hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '5',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_CraftInProcess</Localize></span>
                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_CraftInProcess</Localize></strong>

                            <Craft.Forms.Action event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } operation='in_process' hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '6',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_CraftAdditional</Localize></span>

                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_CraftAdditional</Localize></strong>

                            <Craft.Forms.AssignCreate event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } operation='additional_craft' hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
                {
                    key: '7',
                    label:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span><strong><Localize>MODAL.Button_Text_Crafts</Localize>: </strong><Localize>MODAL.Button_Text_CraftDelete</Localize></span>

                            } >

                            <strong className='modal-title'><Localize>FORMS.Title_CraftDelete</Localize></strong>

                            <Craft.Forms.Action event={ event } standsSelectAll={ arrSelectAll } stands={ arrStands } operation='delete_craft' hallID={ hallID } variables={ variables } />

                        </ModalStandard>
                },
            ] }
        />
    );

    return (
        <Dropdown overlayClassName="drop-more-menu action-menu" overlay={ menu } placement="bottomLeft">
            <Button>
                <Icons.Gear />
                <span><Localize>GLOBAL.Button_Text_Actions</Localize></span>
                <Icons.Arrow className='arrow-down' />
            </Button>
        </Dropdown>
    );
}

export default StandDropdownActions;
