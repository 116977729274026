import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { Menu, Dropdown } from 'antd';

import { GET_ROLES } from 'graphql/query/role-gql';
import { ROLE_DELETE } from "graphql/mutation/role-gql";
import { errorNotification, successNotification } from "components/request-result";

import UserGroupConst from '../usergroup-const';
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';

import { EllipsisOutlined } from '@ant-design/icons';


const basePath = UserGroupConst.basePath;

const UserGroupActionField = ({ role }) => {

    const history = useHistory();

    const [ roleDeleteRequest, { loading } ] = useMutation(ROLE_DELETE,
        {
            refetchQueries: [ GET_ROLES ],

            update(cache, { data }) {

                const {
                    roleDelete: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                history.push(`${ basePath }`);


            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    const isAdmin = role.id === '1';
    const isDev = role.id === '2';


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ basePath }/${ role.id }`)
                },
                {
                    key: '2',
                    icon: <EntityRemoveButton
                        modalButton={
                            <span className={ `${ (isAdmin || isDev) && 'disabled-entity' }` } style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        disabled={ (isAdmin || isDev) }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_Group" }).props.children }
                        dataNameEntity={ role.label }
                        loading={ loading }
                        deleteMutation={ roleDeleteRequest }
                        variables={ {
                            id: role.id,
                        } }
                    />,
                },
            ] }
        />
    );

    return (
        <>
            <Dropdown
                overlay={ menu }
                overlayClassName="drop-more-menu"
                placement="bottomRight"
                arrow
            >
                <EllipsisOutlined className="btn-more" />
            </Dropdown>
        </>
    );

};

export default UserGroupActionField;