import EventFields from "../fields";
import { Localize } from "components/service";


const attachmentsColumns = [
    {
        title: <Localize>TABLES.Column_Title_FileName</Localize>,
        dataIndex: 'original_name',
        columnIndex: 'ORIGINAL_NAME',
        className: 'drag-visible',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        className: 'drag-visible',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Description</Localize>,
        dataIndex: 'description',
        columnIndex: 'DESCRIPTION',
        className: 'drag-visible',
        // sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];



const attachmentsData = (attachments, _, variables) => {

    if (!attachments)
    {
        return [];
    }

    return attachments.map(attachment => {

        return {
            key: attachment.id,
            original_name: <EventFields.Attachment attachment={ attachment } dragHandle={ true } />,
            title: attachment.title ?? <strong>&mdash;</strong>,
            description: attachment.description ?? <strong>&mdash;</strong>,
            action: <EventFields.AttachmentAction attachment={ attachment } variables={ variables } />
        };
    });
};


const EventAttachmentsTableHelper = {
    columns: attachmentsColumns,
    data: attachmentsData,
}

export default EventAttachmentsTableHelper;