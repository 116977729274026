import React from "react";
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { craftUseMutation } from '../hooks';
import CraftForms from '../forms';

import Icons from 'components/icons';
import { EntityRemoveButton, ModalStandard, Localize } from 'components/service';


const CraftActionField = ({ craft, variables }) => {

    const standID = craft?.stand_id;
    const craftID = craft?.id;


    const {
        _setCraftCreateUpdate,
    } = craftUseMutation.createUpdate({ craftID, variables });


    const {
        _setMutationCraftDelete,
        loadingMutationCraftDelete,
    } = craftUseMutation.delete({ craftID });


    let items = [
        {
            key: '1',
            icon:
                <ModalStandard
                    width={ 380 }
                    extraClass={ 'modal-form' }
                    modalButton={
                        <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize></span>
                    }>

                    <strong className='modal-title'><Localize>FORMS.Title_EditCraft</Localize></strong>
                    <CraftForms.Edit standID={ standID } craft={ craft } variables={ variables } />
                </ModalStandard>
        },
        {
            label: `${ Localize({ children: "MENUS_DROP.Label_Mark" }).props.children } ${ craft?.status === 'completed' ? `${ Localize({ children: "MENUS_DROP.Label_InProgress" }).props.children }` : `${ Localize({ children: "MENUS_DROP.Label_Done" }).props.children }` }`,
            key: '2',
            icon: craft?.status === 'completed' ? <Icons.InProces /> : <Icons.Crafts.MPControl />,
            onClick: () => {

                _setCraftCreateUpdate({
                    variables: {
                        input: {
                            id: craftID,
                            stand_id: standID,
                            status: craft?.status === 'completed' ? 'in_progress' : 'completed',
                        }
                    }
                });
            }
        },
        {
            key: '3',
            icon:
                <EntityRemoveButton
                    modalButton={
                        <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                    }
                    nameEntity={ Localize({ children: "ENTITY.Modal_Title_Craft" }).props.children }
                    dataNameEntity={ craft?.type }
                    loading={ loadingMutationCraftDelete }
                    deleteMutation={ _setMutationCraftDelete }
                    variables={ {
                        id: craftID,
                    } }
                />,
        },
    ]


    items = craft?.status === 'disabled' ? items.filter(({ key }) => key !== '2') : items;


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ <Menu items={ items } /> }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default CraftActionField;