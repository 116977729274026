import React from "react";


const UserGroupRightsAllField = ({ role }) => {

    return (
        <div className="group-rigths">
            { !!role?.permissionsCount.app && <span>{ `App (${ role?.label === 'Developer' ? 'all' : role?.permissionsCount.app })` }</span> }
            { !!role?.permissionsCount.crm && <span>{ `CRM (${ role?.label === 'Developer' || role?.label === 'Administrator' ? 'all' : role?.permissionsCount.crm })` }</span> }

        </div>
    );
};

export default UserGroupRightsAllField;