import React, { useState } from "react";
import { Modal } from "antd";
import { CloseOutlined } from '@ant-design/icons';


const ModalStandard = ({
    modalButton,
    extraClass = "",
    maskClosable = true,
    closable = true,
    defaultShow = false,
    disabled = false,
    width = 380,
    ...props
}) => {

    const [ showModal, setShowModal ] = useState(defaultShow);

    return (
        <>
            { modalButton && React.cloneElement(modalButton, {
                disabled,
                onClick: () => setShowModal(true)
            }) }
            <Modal
                className={ `modal-standard ${ extraClass }` }
                centered
                visible={ showModal }
                maskClosable={ maskClosable }
                width={ width }
                closable={ closable }
                closeIcon={ <CloseOutlined /> }
                destroyOnClose={ true }
                footer={ null }
                onOk={ () => setShowModal(false) }
                onCancel={ () => {
                    setShowModal(false)
                } }
            >
                { Array.isArray(props.children) ?
                    props.children.map(
                        (child, index) => React.cloneElement(child, { key: index, action: () => setShowModal(false) })
                    ) :
                    React.cloneElement(props.children, { action: () => setShowModal(false) })
                }

            </Modal>
        </>
    );
}

export default ModalStandard;

