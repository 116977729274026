import CraftHelpers from '../helpers';
import { helperFunc } from 'utils';


const CraftStatusField = ({ craft }) => {

    const { getTranslationStatus } = helperFunc;

    return (
        <strong className={ `${ CraftHelpers.Functions.craftColor(craft?.status) }` }>{ getTranslationStatus(craft?.status) }</strong>
    );
};


export default CraftStatusField;
