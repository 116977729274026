import { useApolloClient, gql } from '@apollo/client';


const useMyLanguageSlug = () => {

  const client = useApolloClient();
  const cache = client.cache;

  const getMeCashe = cache.readQuery({
    query: gql`
          query GetMe {
            me {
              language_slug
            }
          }`
  });


  return {
    language: getMeCashe.me.language_slug
  }
}

export default useMyLanguageSlug;