import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import { useMutation } from "@apollo/client";
import { HALL_CREATE } from "graphql/mutation/hall-gql";
import { errorNotification, successNotification } from "components/request-result";
import HallConst from "components/hall/hall-const";
import { Localize } from "components/service";


const parrentPath = HallConst.parrentPath;
const basePath = HallConst.basePath;

const HallCreateForm = ({ event }) => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [ hallCreateRequest, { loading } ] = useMutation(HALL_CREATE,
        {
            update(cache, { data }) {

                const {
                    hallCreate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if (data?.hallCreate?.hall.id)
                {

                    history.push(`${ parrentPath }/${ event.id }${ basePath }`);

                }

            },
            onError(error) {
                errorNotification(error);
            }
        }

    );

    return (
        <>
            <Form
                key="create-hall"
                layout="vertical"
                className="hall-form"
                form={ form }
                onFinish={ (values) => {
                    hallCreateRequest({
                        variables: {
                            input: {
                                ee_id: event.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    name="title"
                    label={ <Localize>FORMS.Input_Label_HallName</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_HallName</Localize> }
                    ] }
                >
                    <Input name="label" placeholder={ Localize({ children: "FORM_RULES.Required_HallName" }).props.children } />
                </Form.Item>

                <Form.Item
                    name="number"
                    label={ <Localize>FORMS.Input_Label_HallNumber</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_HallNumber</Localize> }
                    ] }
                >
                    <Input name="label" placeholder={ Localize({ children: "FORM_RULES.Required_HallNumber" }).props.children } />
                </Form.Item>

                <div className="form-btn-holder">

                    <Button
                        className="btn-right"
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize>HALL.Button_Text_Create</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default HallCreateForm;