import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";


export default function UserGroupRoute({ role }) {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/group-users` } >
                <Pages.UserGroup.SubPage.Users role={ role } />
            </Route>
            <Route path={ `${ path }/group-users/page/:pageNum` }>
                <Pages.UserGroup.SubPage.Users role={ role } />
            </Route>
            <Route path={ `${ path }` }>
                <Pages.UserGroup.SubPage.OverView role={ role } />
            </Route>
        </Switch>
    );

}