import StandFormItems from "./fields";
import StandFiltersForm from "./stand-filters-form";
import StandEditForm from "./stand-edit-form";
import StandKeysForm from "./stand-keys-form";
import StandDeliveryTimeForm from "./stand-change-delivery-time-form";
import './forms.scss';


const StandForms = {
    Filter: StandFiltersForm,
    Edit: StandEditForm,
    DeliveryTime: StandDeliveryTimeForm,
    Keys: StandKeysForm,
    Fields: StandFormItems,
};

export default StandForms;
