import { gql } from '@apollo/client';


export const GET_FURNISHINGS = gql`
    query Furnishings(
        $text: String
        $where: QueryFurnishingsWhereWhereConditions
        $orderBy: [QueryFurnishingsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ) {
        furnishings(
        text: $text
        where: $where
        orderBy: $orderBy
        first: $first
        page: $page
        ) {
        paginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
        data {
            id
            title
            sku
            price
            qty
            image
            ee_id
            created_at
            updated_at
           }
        }
    }
`;