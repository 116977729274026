import React from "react";
import { Form, Button } from 'antd';
import HallFormItems from "../fields";
import { hallUseMutation } from "../../hooks";
import { Localize } from "components/service";


const HallDeleteForm = ({ hall, action }) => {

    const [ form ] = Form.useForm();

    const {
        _setMutationHallDelete,
        loadingMutationHallDelete
    } = hallUseMutation.delete(hall.id, hall.ee_id);


    return (
        <div className="delete-form">
            { hall?.stands_count > 0 ?
                <p> <Localize>GLOBAL.Modal_Text_DeleteHallStands</Localize>.</p> :
                <p style={ { textAlign: 'center', fontSize: '16px' } }><strong><Localize>GLOBAL.Modal_Text_DeleteHall</Localize> "{ `${ hall?.title }` }"?</strong></p>
            }

            <Form
                key="delete-hall"
                layout="vertical"
                className="delete-hall-form"
                form={ form }
                onFinish={ (values) => {
                    _setMutationHallDelete({
                        variables: {
                            id: +hall.id,
                            ...values
                        }
                    });

                    action();
                    form.resetFields();
                } }
            >
                { hall?.stands_count > 0 &&
                    <HallFormItems.Select
                        name="assign_hall_id"
                        label={ <Localize>FORMS.Input_Label_HallToMoveStandsTo</Localize> }
                        form={ form }
                        ee_id={ +hall.ee_id }
                        hall_id={ hall.id }
                        rules={ false }
                    />
                }

                <div className="form-btn-holder">

                    <Button
                        className="ant-btn ant-btn-secondary bg-light-gray"
                        onClick={ action }
                    >
                        <Localize>GLOBAL.Button_Text_Cancel</Localize>
                    </Button>

                    <Button
                        className="btn-right"
                        type="primary"
                        htmlType="submit"
                        loading={ loadingMutationHallDelete }
                    >
                        <Localize>GLOBAL.Button_Text_Delete</Localize>
                    </Button>
                </div>

            </Form>
        </div >
    );
};


export default HallDeleteForm;