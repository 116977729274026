import CraftTypeField from "./craft-type-field";
import CraftActionField from "./craft-action-field";
import CraftStatusField from "./craft-status-field";
import CraftViewField from "./craft-view-field";
import CraftLinkField from "./craft-link-field";


const CraftFields = {
    Type: CraftTypeField,
    Action: CraftActionField,
    Status: CraftStatusField,
    View: CraftViewField,
    Link: CraftLinkField
}


export default CraftFields;