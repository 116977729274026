import React, { useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { GET_JOBS } from "graphql/query/job-gql";

import Events from 'components/events';
import Stands from 'components/stands';
import Job from 'components/job';

import { Tables } from "components/layout";
import { ModalStandard, Localize } from 'components/service';
import { useBreadCrumbs } from "components/use-hooks";


const eventBasePath = Events.Const.basePath;
const standBasePath = Stands.Const.basePath;
const jobBasePath = Job.Const.basePath;
const tableHelper = Job.Helpers.TableMain();


const StandJobsSubPage = ({ event, stand, actionBarVisible = true, extraHtml }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ eventBasePath }`
        },
        {
            label: event?.title,
            path: `${ eventBasePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }`
        },
        {
            label: stand?.exhibitor,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Jobs" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }${ jobBasePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }${ jobBasePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);
    const currentPage = match ? parseInt(match.params.pageNum) : 1;
    const craftJobsCount = useLocation();
    const [ variables, setVariables ] = useState({});

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();


    return (

        <Tables.Main
            model="craftJobs"
            query={ GET_JOBS }
            varSet={ {
                stand_id: stand?.id,
                perPage: 30,
            } }
            routeUrl={ routeUrl }
            currentPage={ currentPage }
            searchText={ searchText }
            setVariables={ setVariables }
            objFilter={ filters }
            objOrderBy={ [ { column: "NUMBER", order: "ASC" } ] }
            tableHelper={ tableHelper }
        >
            { extraHtml }
            { actionBarVisible &&

                <div className="table-action-bar">

                    <div className="col">
                        <Job.Filters.Table
                            standID={ stand?.id }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            craftJobsCount={ craftJobsCount.state } />
                    </div>

                    <div className="col">

                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <Button type="primary">
                                    <PlusOutlined /> <Localize>JOB.Button_Text_Add</Localize>
                                </Button>
                            }>

                            <strong className='modal-title'><Localize>FORMS.Title_Text_CreateJob</Localize></strong>

                            <Job.Forms.Edit standID={ stand?.id } variables={ variables } />

                        </ModalStandard>
                    </div>
                </div>
            }

        </Tables.Main>
    );
};

export default StandJobsSubPage;

