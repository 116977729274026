import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { EVENT_UPDATE, EVENT_CREATE } from "graphql/mutation/event-gql";

import EventConst from '../event-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationEventCreateUpdate = (id, redirect = true) => {


    const history = useHistory();

    const EVENT_CREATE_UPDATE = id ? EVENT_UPDATE : EVENT_CREATE;
    const eventCreateUpdate = id ? 'eeUpdate' : 'eeCreate';

    const [ _setEventCreateUpdate, { loading } ] = useMutation(EVENT_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ eventCreateUpdate ]: {
                        label,
                        message,
                    }
                } = data;

                redirect && history.push(EventConst.basePath);

                successNotification({
                    title: label,
                    description: message
                });
            },
            onError(error) {
                errorNotification(error);
            }
        }
    );



    return {
        _setEventCreateUpdate,
        loadingMutationEventCreateUpdate: loading,
    }
};

export default useMutationEventCreateUpdate;

