import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { jobUseMutation } from '../hooks';
import JobForms from '../forms';
import JobConst from '../job-const';
import Icons from 'components/icons';
import { EntityRemoveButton, ModalStandard, Localize } from 'components/service';


const JobActionField = ({ job, variables }) => {

    const { location: { pathname } } = useHistory();
    const arrfieldsHide = pathname === JobConst.basePathLastMinute ? [ 'type' ] : [];

    const craftID = +job?.craft_id;
    const jobID = +job?.id;


    const {
        _setJobCreateUpdate,
    } = jobUseMutation.createUpdate({ jobID, variables });


    const {
        _setMutationJobDelete,
        loadingMutationJobDelete,
    } = jobUseMutation.delete({ jobID });


    const menu = (
        <Menu
            items={ [
                {
                    key: '1',
                    icon:
                        <ModalStandard
                            width={ 380 }
                            extraClass={ 'modal-form' }
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize></span>
                            }>

                            <strong className='modal-title'><Localize>FORMS.Title_EditJob</Localize></strong>
                            <JobForms.Edit fieldsHide={ arrfieldsHide } craftID={ craftID } job={ job } />
                        </ModalStandard>
                },
                {
                    label: `${ Localize({ children: "MENUS_DROP.Label_Mark" }).props.children } ${ job?.status !== 'waiting' ? `${ Localize({ children: "MENUS_DROP.Label_Open" }).props.children }` : `${ Localize({ children: "MENUS_DROP.Label_Done" }).props.children } ` }`,
                    key: '2',
                    icon: job?.status !== 'waiting' ? <Icons.InProces /> : <Icons.Crafts.MPControl />,
                    disabled: job?.status === 'in_progress',
                    onClick: () => {

                        _setJobCreateUpdate({
                            variables: {
                                input: {
                                    id: jobID,
                                    craft_id: !jobID ? craftID : undefined,
                                    status: job?.status === 'waiting' ? 'completed' : 'waiting',
                                }
                            }
                        });
                    }
                },
                {
                    key: '3',
                    icon:
                        <EntityRemoveButton
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center', width: '100%' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                            }
                            nameEntity={ Localize({ children: "ENTITY.Modal_Title_Job" }).props.children }
                            dataNameEntity={ job?.type }
                            loading={ loadingMutationJobDelete }
                            deleteMutation={ _setMutationJobDelete }
                            variables={ {
                                id: jobID,
                            } }
                        />,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default JobActionField;