import { useMutation } from "@apollo/client";
import { STAND_KEY_DELETE } from "graphql/mutation/stand-gql";
import { successNotification, errorNotification } from "components/request-result";



const useMutationStandKeyDelete = (keyID) => {


  const [ _setMutationStandKeyDelete, { loading } ] = useMutation(STAND_KEY_DELETE,
    {
      update(cache, { data }) {

        const {
          standKeyDelete: {
            label,
            message
          }
        } = data;


        cache.evict({
          id: cache.identify({ id: keyID, __typename: "StandKey" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationStandKeyDelete,
    loadingMutationStandKeyDelete: loading,
  }
};

export default useMutationStandKeyDelete;