const eventsBasePath = '/exhibitions';


const EventConst = {
   basePath: eventsBasePath
};

export default EventConst;









