import { useMutation } from "@apollo/client";
import { EVENT_REPORT_EXEL } from "graphql/mutation/event-gql";
import { successNotification, errorNotification } from "components/request-result";
import { saveAs } from "file-saver";


const useMutationEventReportExel = () => {

    function getFileNameFromUrl(url) {
        const value = url.split('/');
        return value[ value.length - 1 ];
    }

    const [ _setEventReportExel, { loading } ] = useMutation(EVENT_REPORT_EXEL,
        {
            update(cache, { data }) {

                const {
                    eeReportExel: {
                        label,
                        message,
                        file
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                })


                saveAs(file, getFileNameFromUrl(file));
            },


            onError(error) {
                errorNotification(error);
            },

        }
    );


    return {
        _setEventReportExel,
        loadingMutationEventReportExel: loading,
    }
};


export default useMutationEventReportExel;

