import Fields from "components/subcontractor/fields";
import { Localize } from "components/service";


const subcontractorsGroupsColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true,
        width: '30%'
    },
    {
        title: <Localize>TABLES.Column_Title_Number</Localize>,
        dataIndex: 'number',
        columnIndex: 'NUMBER',
        sorter: true,
        width: '20%'
    },
    {
        title: <Localize>TABLES.Column_Title_NumberUsers</Localize>,
        dataIndex: 'usersCount',
        width: '15%'
    },
    {
        // title     : 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];

const subcontractorsData = (subcontractors) => {

    if (!subcontractors)
    {
        return [];
    }

    return subcontractors.map(subcontractor => {

        return {
            key: subcontractor.id,
            id: subcontractor.id,
            title: <Fields.EditLink subcontractor={ subcontractor } />,
            number: subcontractor.number,
            // type        : subcontractor.type,
            usersCount: <Fields.UsersView subcontractor={ subcontractor } />,
            action: <Fields.Action subcontractor={ subcontractor } />,
        };

    });

};

const SubcontractorsTableHelper = {
    columns: subcontractorsGroupsColumns,
    data: subcontractorsData,
}

export default SubcontractorsTableHelper;