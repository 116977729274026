import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { SUBCONTRACTOR_DELETE } from "graphql/mutation/subcontractor-gql";
import { errorNotification, successNotification } from "components/request-result";

import { EntityRemoveButton, Localize } from 'components/service';


const SubcontractorDeleteForm = ({ subcontractor }) => {

    let history = useHistory();
    let location = useLocation();

    const [ subcontractorDelete, { loading } ] = useMutation(SUBCONTRACTOR_DELETE,
        {

            update(cache, { data }) {

                const {
                    subcontractorDelete: {
                        label,
                        message
                    }
                } = data;

                cache.evict({
                    id: cache.identify({ id: subcontractor?.id, __typename: "Subcontractor" })
                });
                cache.gc();

                successNotification({
                    title: label,
                    description: message
                });

                history.push(`${ location.state.path }`);

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );


    return (
        <>
            <EntityRemoveButton
                modalButton={
                    <Button className="ant-btn-secondary"><Localize>SUBCONTRACTOR.Button_Text_Delete</Localize></Button>
                }
                nameEntity={ Localize({ children: "ENTITY.Modal_Title_Subcontractor" }).props.children }
                dataNameEntity={ subcontractor.title }
                loading={ loading }
                deleteMutation={ subcontractorDelete }
                variables={ {
                    id: subcontractor.id,
                } }
            />
        </>
    );

};

export default SubcontractorDeleteForm;