import React from "react";
import { useQuery } from "@apollo/client";

import { GET_EVENT } from 'graphql/query/event-gql';

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import Hall from "components/hall";
import Event from "components/events";
import { Localize } from "components/service";


const basePath = Hall.Const.basePath;

const HallCreatePage = ({ match }) => {

    useActiveMenuItem([ "exhibitions" ], [ "exhibitions" ]);

    const eeID = match ? parseInt(match.params.eeID) : null;

    const { data: { exhibitionEvent: event } = {}, loading } = useQuery(GET_EVENT, {
        variables: {
            id: eeID
        }
    });


    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ Event.Const.basePath }`
        },
        {
            label: event?.title,
            path: `${ Event.Const.basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Halls" }).props.children,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <PageWrapDefault
            className="page-hall"
            title={ !loading && event ? <Localize>PAGES.Title_CreateHall</Localize> : "Page 404" }
            dataExist={ !loading && event }
            staticPath={ `${ match.url }` }
        >

            <div className="row-grid col-lg-2">
                <div className="col-left">

                    <Hall.Forms.Create event={ event } />

                </div>
            </div>

        </PageWrapDefault>
    );
};

export default HallCreatePage;