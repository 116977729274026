import { useMutation } from "@apollo/client";

import { STAND_UPLOAD_FILE } from "graphql/mutation/stand-gql";
import { GET_STAND } from "graphql/query/stand-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationStandUploadFile = ({ eeID, standID }) => {

    const [ _setStandUploadFile, { loading } ] = useMutation(STAND_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const {
                    fileUpload: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_STAND,
                    variables: {
                        id: +standID,
                        ee_id: +eeID
                    }
                },
            ]
        }
    );


    return {
        _setStandUploadFile,
        loadingMutationStandUploadFile: loading,
    }
};

export default useMutationStandUploadFile;

