import React, { useState } from "react";
import { GET_JOBS } from "graphql/query/job-gql";
import { useQuery } from "@apollo/client";
import SubPage from './sub-pages';
import Job from 'components/job';
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";
import Icons from "components/icons";

import './last-minute.scss';

const LastMinutePage = () => {

    useActiveMenuItem([ "last-minute" ], [ "last-minute" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_LastMin" }).props.children,
            path: `/last-minute`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const [ eventID, setEventID ] = useState(false);
    const [ standID, setStandID ] = useState(false);
    let [ variables, setVariables ] = useState({});


    if (standID)
    {
        variables = {
            stand_id: standID,
        }
    }


    let { data, loading } = useQuery(GET_JOBS, {
        skip: !standID,
        variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    data = data?.craftJobs.data ?? {};


    return (
        <PageWrapDefault
            className="page-last-minute"
            title={ <Localize>PAGES.Title_LastMinJobs</Localize> }
            dataExist={ true }
            pageContentVisible={ !!eventID && !!standID }
            pageHeaderContent={

                <Job.Forms.LastMinute
                    setEventID={ setEventID }
                    setStandID={ setStandID }
                    eventID={ eventID }
                    standID={ standID }
                    variables={ variables } />
            }>


            { data?.length ?

                <SubPage.Jobs
                    setVariables={ setVariables }
                    actionBarVisible={ false }
                    stand={ { id: standID } }
                    extraHtml={
                        <strong className="top-table-text"><Localize>PAGES.Text_AllExisting</Localize> </strong>
                    }
                />
                :
                !loading &&
                <div className="no-data">
                    <Icons.NoJobs className='icon-no-jobs' />
                    <strong className="text"><Localize>PAGES.Text_NoExisting</Localize></strong>
                </div>
            }

        </PageWrapDefault>
    );
};

export default LastMinutePage;





