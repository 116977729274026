import React from 'react';
import { Form, Select } from "antd";
import CraftConst from '../../craft-const';
import Icons from "components/icons";
import { Localize } from "components/service";


const CraftSelectTypeFormItem = ({
    form,
    disabled = false,
    rules = true,
    onChangeSetFilter = () => { },
    initialValue,
    mode = '',
    name = 'type',
    label = Localize({ children: "FORMS.Input_Label_CraftType" }).props.children,
    craftTypes = 'craftTypes',
    additionalFieldName = 'title',
}) => {

    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue === 'mp_control' ? 'mp_control' : initialValue?.replace(/_/g, '') }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                showArrow
                mode={ mode }
                suffixIcon={ <Icons.Arrow /> }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value });
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children }` }
                dropdownClassName="select-serch-dropdown craft-select"
                optionFilterProp="value"
            >

                { CraftConst[ craftTypes ].map(({ type, title }) => (

                    <Select.Option key={ type } value={ type }>
                        <span onClick={ (e) => form.setFieldsValue({ [ additionalFieldName ]: e.target.childNodes[ 0 ].wholeText }) }> { title }</span>
                    </Select.Option>
                )) }
            </Select>

        </Form.Item>
    );
}

export default CraftSelectTypeFormItem;


