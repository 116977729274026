import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { eventUseMutation } from '../hooks';
import Icons from 'components/icons';
import { EntityRemoveButton, Localize } from 'components/service';
import EventConst from '../event-const';


const EventActionField = ({ id, title, status }) => {

    const history = useHistory();

    const {
        _setEventCreateUpdate,
    } = eventUseMutation.createUpdate(id, false);

    const {
        _setMutationEventDelete,
        loadingMutationEventDelete,
    } = eventUseMutation.delete(id);


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ EventConst.basePath }/${ id }`)
                },
                {
                    label: !status ? <Localize>MENUS_DROP.Label_ElfeOnline</Localize> : <Localize>MENUS_DROP.Label_ElfeOffline</Localize>,
                    key: '2',
                    icon: <Icons.Logs />,
                    onClick: () => {
                        _setEventCreateUpdate({
                            variables: {
                                input: {
                                    id,
                                    status: !status
                                }
                            }
                        })
                    }
                },
                {
                    key: '3',
                    icon:
                        <EntityRemoveButton
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                            }
                            nameEntity={ Localize({ children: "ENTITY.Modal_Title_Exhibition" }).props.children }
                            dataNameEntity={ title }
                            loading={ loadingMutationEventDelete }
                            deleteMutation={ _setMutationEventDelete }
                            variables={ {
                                id,
                            } }
                        />,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default EventActionField;