import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";


export default function HallsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `/exhibitions/:eeID/halls/create` }>
                { ({ match }) => <Pages.Hall.Create match={ match } /> }
            </Route>
            <Route path={ `${ path }` }>
                { ({ match }) => <Pages.Hall.Single match={ match } /> }
            </Route>
        </Switch>
    );
}