import EventFormItems from "./fields";
import EventFiltersForm from "./event-filters-form";
import EventEditForm from "./event-edit-form";
import EventAttachmentForm from "./event-attachment-form";

import './forms.scss';

const EventForms = {
    Filter: EventFiltersForm,
    Edit: EventEditForm,
    Fields: EventFormItems,
    Attachment: EventAttachmentForm,
};

export default EventForms;
