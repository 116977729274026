import EventFields from "../fields";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";

const eventsColumns = [
  {
    title: <Localize>TABLES.Column_Title_Exhibition</Localize>,
    dataIndex: "exhibition",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_ConstractionStarts</Localize>,
    dataIndex: "constructionStart_at",
    columnIndex: "CONSTRUCTIONSTART_AT",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_ConstractionEnds</Localize>,
    dataIndex: "constructionEnd_at",
    columnIndex: "CONSTRUCTIONEND_AT",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_EventStarts</Localize>,
    dataIndex: "start_at",
    columnIndex: "START_AT",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Stands</Localize>,
    dataIndex: "standsCount",
    columnIndex: "STANDS_COUNT",
    sorter: true,
    width: "8%",
  },
  {
    title: <Localize>TABLES.Column_Title_Halls</Localize>,
    dataIndex: "hallsCount",
    columnIndex: "HALLS_COUNT",
    sorter: true,
    width: "8%",
  },
  {
    title: <Localize>TABLES.Column_Title_DamageFilesCount</Localize>,
    dataIndex: "damageFilesCount",
    columnIndex: "DAMAGES_FILES_COUNT",
  },
  {
    title: <Localize>TABLES.Column_Title_ElfeOnline</Localize>,
    dataIndex: "status",
    columnIndex: "STATUS",
    sorter: true,
  },
  {
    // title: "action",
    dataIndex: "action",
    columnIndex: "ACTION",
    align: "right",
    width: "4%",
  },
];

const eventsData = (events) => {
  if (!events) {
    return [];
  }

  return events.map((event) => {
    return {
      key: event.id,
      exhibition: <EventFields.Link id={event.id} title={event.title} />,
      constructionStart_at: timeFormat(event?.constructionStart_at) ?? (
        <strong>&mdash;</strong>
      ),
      constructionEnd_at: timeFormat(event?.constructionEnd_at) ?? (
        <strong>&mdash;</strong>
      ),
      start_at: timeFormat(event?.start_at) ?? <strong>&mdash;</strong>,
      standsCount: (
        <EventFields.View
          id={event.id}
          count={event.standsCount}
          model={"stands"}
        />
      ),
      hallsCount: (
        <EventFields.View
          id={event.id}
          count={event.hallsCount}
          model={"halls"}
        />
      ),
      damageFilesCount: event.damageFilesCount ?? <strong>&mdash;</strong>,
      status: <EventFields.Radio id={event.id} status={event.status} />,
      action: (
        <EventFields.Action
          id={event.id}
          title={event.title}
          status={event.status}
        />
      ),
    };
  });
};

const EventTableHelper = {
  columns: eventsColumns,
  data: eventsData,
};

export default EventTableHelper;
