import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            surname
            email
            phone
            status
            role_id
            language_slug
            role{
                id
                label
                slug
                colorHex
                description
            }
            exhibitionEvent {
                id
                title
                status
            }
            company
            subcontractor_id
            is_online
            last_visit_at
            created_at
            updated_at
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text: String,
        $ee_id: [ID!],
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            text: $text,
            ee_id: $ee_id,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                surname
                email
                phone
                status
                role_id
                language_slug
                role{
                    id
                    label
                    slug
                    colorHex
                    description
                }
                exhibitionEvent {
                    id
                    title
                    status
                }
                company
                subcontractor_id
                subcontractor {
                    description
                    type
                    title
                    number
                    id
                }
                sendPassword {
                    id
                    user_id
                    send_user_id
                    message
                    created_at
                }
                is_online
                last_visit_at
                created_at
                updated_at
              }
        }
    }
`;

export const GET_USERS_CURSOR = gql`
    query UsersCursor(
        $text: String
        $ee_id: [ID!]
        $where: QueryUsersCursorWhereWhereConditions
        $first: Int
        $after: String
    ) {
        usersCursor(
        text: $text
        ee_id: $ee_id
        where: $where
        first: $first
        after: $after
        ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
            lastPage
        }
        edges {
            cursor
            node {
            id
            role_id
            name
            surname
              }
           }
        }
    }
`;

export const GET_USERS_AUTOCOMPLETE = gql`
    query GetUsersAutocomplete(
        $text: String
    ){
        users(
            text: $text
        ){
            data{
                id
                name
                surname
                role_slug
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
            id
            name
            surname
            email
            phone
            status
            role_id
            language_slug
            role{
                id
                label
                slug
                colorHex
                description
            }
            exhibitionEvent {
                id
                title
                status
            }
            company
            subcontractor_id
            subcontractor {
                description
                type
                title
                number
                id
            }
            is_online
            last_visit_at
            created_at
            updated_at
        }
    }
`;