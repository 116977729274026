import { ModalStandard } from 'components/service';
import FurnishingForms from '../forms';
import { Localize } from 'components/service';


const FurnishingTypeField = ({ furnishing, variables }) => {

    return (
        <div className='fields-link'>
            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                modalButton={
                    <strong className='title'>{ furnishing?.title }</strong>
                }>
                <strong className='modal-title'><Localize>FORMS.Title_EditFurnishing</Localize></strong>
                <FurnishingForms.Edit furnishing={ furnishing } variables={ variables } />
            </ModalStandard>
        </div>
    );
};


export default FurnishingTypeField;