import React from "react";
import { Link } from 'react-router-dom';
import Events from "components/events";


const UserExhibitionField = ({ exhibition }) => (

    exhibition?.length ?
        exhibition?.map(({ id, title }) =>
            <Link
                key={ +id }
                to={ `${ Events.Const.basePath }/${ id }` }
                className='tag-main tag-link ellipsis'
            > { title }
            </Link>) : <strong>&mdash;</strong>
);


export default UserExhibitionField;