import { gql } from '@apollo/client';

export const SUBCONTRACTOR_CREATE = gql`
    mutation SubcontractorCreate(
        $input: SubcontractorFieldsInput!
    ) {
        subcontractorCreate(
            input: $input
        ){
            label
            message
            subcontractor{
                id
                title
                number
                type
                description
                usersCount
                created_at
                updated_at
            }
        }
    }
`;


export const SUBCONTRACTOR_UPDATE = gql`
    mutation SubcontractorUpdate(
        $input: SubcontractorFieldsInput!
    ) {
        subcontractorUpdate(
            input: $input
        ){
            label
            message
            subcontractor{
                id
                title
                number
                type
                description
                usersCount
                created_at
                updated_at
            }
        }
    }
`;


export const SUBCONTRACTOR_DELETE = gql`
    mutation SubcontractorDelete(
        $id: ID!
    ) {
        subcontractorDelete(
            id: $id
        ){
            label
            message
        }
    }
`;


export const SUBCONTRACTOR_REMOVE_USER = gql`
    mutation SubcontractorRemoveUser(
        $user_id: ID!
        $subcontractor_id: ID!
    ) {
        subcontractorRemoveUser(
            user_id: $user_id
            subcontractor_id: $subcontractor_id
        ){
            label
            message
        }
    }
`;


export const SUBCONTRACTOR_SYNC_USERS = gql`
    mutation SubcontractorSyncToUsers($user_id: [ID]!, $subcontractor_id: ID!) {
        subcontractorSyncToUsers(
        user_id: $user_id
        subcontractor_id: $subcontractor_id
        ) {
        label
        message
        }
    }
`;


export const SUBCONTRACTOR_IMPORT_REIMPORT = gql`
    mutation SubcontractorImportOrReImport($file: Upload!) {
        subcontractorImportOrReImport(file: $file) {
            label
            message
            sync_info
        }
    }
`;


