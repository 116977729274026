import StandSelectWhereFormItem from "./stand-select-where-form-item";
import StandRadioSelect from "./stands-radio-select";


const StandFormItems = {
    SelectWere: StandSelectWhereFormItem,
    RadioSelect: StandRadioSelect,


};

export default StandFormItems;