import { useMutation } from "@apollo/client";

import { CRAFT_DELETE } from "graphql/mutation/craft-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationCraftDelete = ({ craftID }) => {

  const [ _setMutationCraftDelete, { loading } ] = useMutation(CRAFT_DELETE,
    {
      update(cache, { data }) {

        const {
          craftDelete: {
            label,
            message
          }
        } = data;

        cache.evict({
          id: cache.identify({ id: craftID, __typename: "Craft" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },
    });

  return {
    _setMutationCraftDelete,
    loadingMutationCraftDelete: loading,
  }
};

export default useMutationCraftDelete;