import FurnishingEditForm from "./furnishing-edit-form";
import FurnishingCopyEventForm from "./furnishing-copy-event-form";


import './forms.scss';

const FurnishingForms = {
    Edit: FurnishingEditForm,
    CopyEvent: FurnishingCopyEventForm,
};

export default FurnishingForms;
