import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/stands/sub-pages';


const StandRoute = ({ event, stand, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/crafts` } >
                <SubPage.Crafts
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/crafts/page/:pageNum` } exact>
                <SubPage.Crafts
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/jobs` }>
                <SubPage.Jobs
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/jobs/page/:pageNum` } exact>
                <SubPage.Jobs
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/logs` }>
                <SubPage.Logs
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/logs/page/:pageNum` } exact>
                <SubPage.Logs
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }/keys` } >
                <SubPage.Keys
                    event={ event }
                    stand={ stand } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView
                    event={ event }
                    stand={ stand }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default StandRoute;
