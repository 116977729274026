import UserGroupPermissionCheckboxFormItem from "./usergroup-permission-checkbox-form-item";
import UserGroupColorSelectFormItem from "./usergroup-color-select-form-item";
import UserGroupSelectFormItem from "./usergroup-select-form-item";


const UserGroupFormItems = {
    PermissionCheckbox: UserGroupPermissionCheckboxFormItem,
    ColorSelect: UserGroupColorSelectFormItem,
    GroupSelect: UserGroupSelectFormItem,
};

export default UserGroupFormItems;