import HallCreateForm from "./hall-create-form";
import HallEditForm   from "./hall-edit-form";
import HallDeleteForm from "./hall-delete-form";
import HallFormItems from "./fields";

const HallForms = {
    Create : HallCreateForm,
    Edit   : HallEditForm,
    Delete : HallDeleteForm,
    Fields : HallFormItems
};

export default HallForms;