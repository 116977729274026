import { gql } from '@apollo/client';


export const GET_EVENT_ID = gql`
    query ExhibitionEvent($id: ID) {
        exhibitionEvent(id: $id) {
        id
        title
    }
  }
`;


export const GET_EVENT = gql`
    query ExhibitionEvent($id: ID) {
        exhibitionEvent(id: $id) {
        id
        title
        status
        path_model
        start_at
        end_at
        constructionStart_at
        constructionEnd_at
        standsCount
        hallsCount
        users {
            id
            name
            surname
        }
        created_at
        updated_at
        }
    }
`;


export const GET_EVENTS = gql`
    query ExhibitionEvents(
        $text: String
        $where: QueryExhibitionEventsWhereWhereConditions
        $orderBy: [QueryExhibitionEventsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ) {
        exhibitionEvents(
        text: $text
        where: $where
        orderBy: $orderBy
        first: $first
        page: $page
        ) {
        paginatorInfo {
            total
            count
            currentPage
            perPage
            lastPage
        }
        data {
            id
            title
            status
            path_model
            start_at
            end_at
            constructionStart_at
            constructionEnd_at
            standsCount
            hallsCount
            damageFilesCount
            users {
            id
            name
            surname
            }
            created_at
            updated_at
             }
        }
    }
`;


export const GET_EVENTS_CURSOR = gql`
    query ExhibitionEventsCursor($text: String, $first: Int, $after: String) {
        exhibitionEventsCursor(text: $text, first: $first, after: $after) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
        }
        edges {
            node {
            id
            title
            status
            path_model
            }
            cursor
            }
       }
   }
`;


export const GET_EVENTS_LOG_UNIQ_CURSOR = gql`
    query LogEventsUniq($text: String, $user_id: ID, $first: Int, $after: String) {
        logEventsUniq(text: $text, user_id: $user_id, first: $first, after: $after) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
        }
        edges {
            node {
            id
            title
            status
            path_model
            }
            cursor
            }
       }
   }
`;


export const GET_EVENT_ATTACHMENTS = gql`
    query Attachments(
        $ee_id: ID,
        $user_id: ID,
        $first: Int,
        $page: Int,
    ) {
        attachments(
            ee_id: $ee_id,
            user_id: $user_id,
            first: $first,
            page: $page
        ) {
            paginatorInfo {
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                path
                server_name
                original_name
                extension
                size
                ee_id
                user_id
                title
                description
                order
                created_at
                updated_at
            }
        }
    }
`;


export const GET_EVENT_ATTACHMENT = gql`
    query Attachment(
        $id: ID!
        $ee_id: ID,
    ) {
        attachment(
            id: $id,
            ee_id: $ee_id,
        ) {
            id
            path
            server_name
            original_name
            extension
            size
            ee_id
            exhibitionEvent {
              id
              title
            }
            user_id
            title
            description
            order
            created_at
            updated_at
        }
    }
`;
