import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { Form, Select } from "antd";

import { GET_HALLS } from "graphql/query/hall-gql";
import Job from 'components/job';
import Icons from "components/icons";
import { Localize } from "components/service";


const HallSelectFormItem = ({
    ee_id,
    hall_id,
    form,
    disabled = false,
    rules = true,
    onChangeSetFilter = () => { },
    initialValue,
    mode = '',
    name = 'hall_id',
    label = Localize({ children: "FORMS.Input_Label_Hall" }).props.children,
    placeholder = Localize({ children: "FORMS.Input_Placeholder_ChooseHall" }).props.children,
}) => {


    const { location: { pathname } } = useHistory();

    const defaultSelectOption = pathname === Job.Const.basePathLastMinute ? true : false;

    const { data, loading } = useQuery(GET_HALLS, {
        variables: {
            ee_id
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const dataWithoutСurrent = data?.halls?.data?.filter(hall => hall.id !== hall_id);

    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `Please ${ placeholder }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value });
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ placeholder }
                dropdownClassName="select-serch-dropdown"
                optionFilterProp="value"
            >
                { defaultSelectOption && <Select.Option><Localize>FORMS.Select_OptionLabel_HallNotSelected</Localize></Select.Option> }

                { hall_id ?
                    !loading && dataWithoutСurrent?.map((hall) => (

                        <Select.Option key={ +hall?.id } value={ hall.id }>
                            <span>{ hall?.title }</span>
                        </Select.Option>
                    ))
                    :
                    !loading && data?.halls?.data.map((hall) => (

                        <Select.Option key={ +hall?.id } value={ hall.id }>
                            <span>{ hall.title }</span>
                        </Select.Option>
                    ))
                }

            </Select>
        </Form.Item>
    );

}

export default HallSelectFormItem;


