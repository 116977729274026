import { useMutation } from "@apollo/client";

import { JOB_UPLOAD_FILE } from "graphql/mutation/job-gql";
import { GET_JOBS } from "graphql/query/job-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationJobUploadFile = ({ variables }) => {

    const [ _setJobUploadFile, { loading } ] = useMutation(JOB_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const {
                    fileUpload: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_JOBS,
                    variables
                },
            ]
        }
    );


    return {
        _setJobUploadFile,
        loadingMutationJobUploadFile: loading,
    }
};

export default useMutationJobUploadFile;

