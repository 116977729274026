import { gql } from '@apollo/client';


export const CRAFT_CREATE = gql`
    mutation CraftCreate($input: CraftFieldsInput!) {
    craftCreate(input: $input) {
    label
    message
    craft {
      id
      ee_id
      stand_id
      type
      complete_at
      subcontractor_id
      status
      jobs_count
      subcontractor {
        id
        title
        }
      }
    }
  }
`;


export const CRAFT_UPDATE = gql`
mutation CraftUpdate($input: CraftFieldsInput!) {
    craftUpdate(input: $input) {
      label
      message
      craft {
        id
        ee_id
        stand_id
        type
        complete_at
        subcontractor_id
        status
        jobs_count
        subcontractor {
          id
          title
          }
        }
     }
  }
`;


export const CRAFT_DELETE = gql`
mutation CraftDelete($id: ID!) {
    craftDelete(id: $id) {
      label
      message
    }
  }
`;






