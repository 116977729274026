import React from "react";
import { useMutation } from "@apollo/client";

import { GET_USERS } from "graphql/query/user-gql";
import { EVENT_REMOVE_USER } from "graphql/mutation/event-gql";
import { errorNotification, successNotification } from "components/request-result";

import EntityRemoveButton from 'components/service/entity-remove-button';
import { CloseOutlined } from '@ant-design/icons';


const EventRemoveUserField = ({ user, event }) => {

    const text = `Are you sure you want to remove the ${ user.name } ${ user.surname } from the exhibition ${ event.title }?`;

    const [ eeRemoveUser, { loading } ] = useMutation(EVENT_REMOVE_USER,
        {
            refetchQueries: [ GET_USERS ],

            update(cache, { data }) {

                const {
                    eeRemoveUser: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (

        <EntityRemoveButton
            modalButton={ <CloseOutlined /> }
            text={ text }
            loading={ loading }
            deleteMutation={ eeRemoveUser }
            okText="Remove"
            variables={ {
                user_id: user.id,
                ee_id: event.id,
            } }
        />

    );

}

export default EventRemoveUserField;