const groupColors = [
    { text: 'Red', value: '#e84a4d' },
    { text: 'Green', value: '#44d13f' },
    { text: 'Yellow', value: '#f8dc00' },
    { text: 'Orange', value: '#f49a00' },
    { text: 'Light green', value: '#78cb93' },
    { text: 'Blue', value: '#5fecf9' },
    { text: 'Light blue', value: '#adc6ff' },
    { text: 'Magenta', value: '#E5027D' },
    { text: 'Mustard', value: '#d6bd00' }
];

const groupBasePath = '/user-groups';

const UserGroupConst = {
    colorHexList : groupColors,
    basePath     : groupBasePath
};

export default UserGroupConst;