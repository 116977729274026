import React from "react";
import { useQuery } from "@apollo/client";

import { GET_SUBCONTRACTOR } from "graphql/query/subcontractor-gql";

import RoutePage from "components/routes/subcontractors/subcontractor-route";
import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import SubcontractorConst from "components/subcontractor/subcontractor-const";
import { Localize } from "components/service";


const basePath = SubcontractorConst.basePath;

const SubcontractorSinglePage = ({ match }) => {

    useActiveMenuItem([ "subcontractors" ], [ "subcontractors" ]);

    const pageID = match ? parseInt(match.params.id) : null;

    const { data: { subcontractor } = {}, loading } = useQuery(GET_SUBCONTRACTOR, {
        variables: {
            id: pageID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        } ];

    return (

        <PageWrapDefault
            className="page-subcontractor"
            loading={ loading }
            title={ !loading && subcontractor ? subcontractor.title : "Page 404" }
            dataExist={ !loading && subcontractor }
            pageNavbar={ subcontractor ? pageNavbar : false }
            staticPath={ `${ basePath }/${ pageID }` }
        >
            <RoutePage subcontractor={ subcontractor } />

        </PageWrapDefault>
    );
};

export default SubcontractorSinglePage;
