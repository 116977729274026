import React from 'react';
import { Form, Select } from "antd";
import { useQuery } from "@apollo/client";
import { GET_ROLES } from "graphql/query/role-gql";
import Icons from "components/icons";
import { Localize } from "components/service";


const UserGroupSelectFormItem = ({
    form,
    disabled = false,
    rules = false,
    onChangeSetFilter = () => { },
    initialValue,
    mode = false,
    name = 'role_id',
    label = Localize({ children: "FORMS.Input_Label_UsersGroup" }).props.children
}) => {


    const { data, loading } = useQuery(GET_ROLES, {
        variables: {
            first: 999,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ mode ? initialValue : initialValue && +initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                showArrow
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value });
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                dropdownClassName="select-serch-dropdown"
                optionFilterProp="value"
            >
                {
                    !loading && data?.roles?.data.map((item) => (
                        <Select.Option key={ item.id } value={ +item.id }>
                            <span>{ item.label }</span>
                        </Select.Option>
                    ))
                }
            </Select>
        </Form.Item>
    );

}

export default UserGroupSelectFormItem;


