import CraftFields from "../fields";
import { timeFormat } from "utils/helper-functions";
import { Localize } from "components/service";


const craftColumns = [
    {
        title: <Localize>TABLES.Column_Title_Type</Localize>,
        dataIndex: 'type',
        columnIndex: 'TYPE',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Title</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        // sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: 'status',
        columnIndex: 'STATUS',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Jobs</Localize>,
        dataIndex: 'jobs_count',
        columnIndex: 'JOBS_COUNT',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_TimeCompletion</Localize>,
        dataIndex: 'complete_at',
        columnIndex: 'COMPLETE_AT',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Subcontractor</Localize>,
        dataIndex: 'subcontractor',
        columnIndex: 'SUBCONTRACTOR_ID',
        sorter: true,
    },
    {
        // title: "action",
        dataIndex: 'action',
        columnIndex: 'ACTION',
        align: 'right',
        width: '4%',
    }
];


const craftData = (crafts, _, variables) => {


    if (!crafts)
    {
        return [];
    }

    return crafts?.map(craft => {

        return {
            key: craft.id,
            title: craft.title ? <span className="color-white">{ craft.title }</span> : <strong>&mdash;</strong>,
            type: <CraftFields.Type craft={ craft } variables={ variables } />,
            status: <CraftFields.Status craft={ craft } />,
            jobs_count: <CraftFields.View craft={ craft } />,
            complete_at: timeFormat(craft?.complete_at) ?? '',
            subcontractor: <CraftFields.Link craft={ craft } />,
            action: <CraftFields.Action craft={ craft } variables={ variables } />
        };

    });
};


const CraftTableHelper = {
    columns: craftColumns,
    data: craftData,
}

export default CraftTableHelper;