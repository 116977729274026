import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, } from '@ant-design/icons';
import { GET_APP_PARAMS } from "graphql/query/local-store-gql";
import Menus from 'components/menus';
import { TopBar } from 'components/layout';
import { DateTime, Localize } from 'components/service';
import { AppRoutes } from "components/routes";
import { Suspense } from "components/request-result";
import { useMe, useLogout, useWindowSize } from "components/use-hooks";
import Breadcrumbs from 'components/breadcrumbs';
import Icons from 'components/icons';
import logo from "assets/logo.png";
import './app.scss';

const { Sider } = Layout;


const App = () => {

    const [ toggleSider, setToggleSider ] = useState(false);

    const { loading, error } = useMe("cache-and-network", true),
        { data: { appParams } } = useQuery(GET_APP_PARAMS),
        { logout } = useLogout();


    return (
        <div className={ `app ${ appParams.appBlockClass.join(" ") }` }>
            <Suspense type="block" state={ { loading, error } } blockClass="loader-main">
                <Layout className='app-layout'>
                    <Sider
                        trigger={ null }
                        collapsed={ useWindowSize().width < 1200 && toggleSider }
                        className="sider"
                        width="230"
                        breakpoint="xl"
                        collapsedWidth="0"
                        onBreakpoint={ (broken) => setToggleSider(broken) }
                    >
                        <span className='nav-opener absolute' onClick={ () => setToggleSider(!toggleSider) } ><MenuFoldOutlined /></span>

                        <div className="holder-date-logo">
                            <DateTime />
                            <Link className="logo" to="/">
                                <div className="logo-box">
                                    <img src={ logo } alt="Elfe" />
                                </div>
                                <strong className='logo-text'>ELFE</strong>
                            </Link>
                        </div>

                        <Menus.Sidebar appParams={ appParams } setToggleSider={ setToggleSider } />
                        <span className='sider-logout' onClick={ () => logout({ redirectRoute: "/" }) }>
                            <span><Icons.LogOut className="logout-icon" /> <Localize>MENUS_DROP.Label_Logout</Localize></span>
                        </span>
                    </Sider>
                    <Layout className="content-holder">
                        <main id='main'>
                            <TopBar>
                                <span className='nav-opener' onClick={ () => setToggleSider(!toggleSider) } ><MenuUnfoldOutlined /></span>
                                <Breadcrumbs />
                                <Menus.DropMenu />
                            </TopBar>
                            <AppRoutes />
                        </main>
                    </Layout>
                </Layout>
            </Suspense>
        </div>
    );
};

export default App;