import React from "react";

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import UserGroups from "components/usergroup";
import { Localize } from "components/service";


const basePath = UserGroups.Const.basePath;


const UserGroupCreatePage = () => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_UserGroups" }).props.children,
            path: `${ basePath }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: `${ basePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <PageWrapDefault
            className="page-user-groups"
            dataExist={ true }
            title={ <Localize>PAGES.Title_CreateGroupUser</Localize> }
            pageNavbar={ false }
            staticPath={ `${ basePath }/create` }
        >
            <div className="row-grid col-lg-2">
                <div className="col-left">
                    <UserGroups.Forms.Create />
                </div>
            </div>

        </PageWrapDefault>
    );
};

export default UserGroupCreatePage;