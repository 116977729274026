// import SubcontractorTables  from "./table";
import SubcontractorFields  from "./fields";
import SubcontractorForms   from "./forms";
import SubcontractorConst   from "./subcontractor-const";
import SubcontractorHelpers from "./helpers";
import { subcontractorUseMutation } from "./hooks";

const Subcontractor = {
    // Tables  : SubcontractorTables,
    Fields  : SubcontractorFields,
    Forms   : SubcontractorForms,
    Const   : SubcontractorConst,
    Helpers : SubcontractorHelpers,
    Hooks   : subcontractorUseMutation
}

export default Subcontractor;