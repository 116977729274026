import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Input } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { jobUseMutation } from '../../hooks';
import JobFormItems from '../../forms/fields';
import Craft from 'components/craft';
import Icons from 'components/icons';
import { UploadFile, Localize } from 'components/service';


const JobEditForm = ({
    craftsHide = [ 'mp_control' ],
    standID,
    job,
    variables,
    fieldsHide,
    action }) => {


    const [ form ] = Form.useForm();
    const jobID = +job?.id;
    const [ craftType, setCraftType ] = useState('');

    const {
        _setJobUploadFile,
        loadingMutationJobUploadFile
    } = jobUseMutation.uploadFile({ variables });

    const {
        _setJobCreateUpdate,
        loadingMutationJobCreateUpdate: loading,
    } = jobUseMutation.createUpdate({ jobID, variables });

    const isHide = (fieldName) => !fieldsHide?.filter(name => name === fieldName).length > 0;

    return (
        <Form
            key="edit-model-form"
            layout="vertical"
            form={ form }
            className="model-form edit-model-form"
            onFinish={ (values) => {

                _setJobCreateUpdate({
                    variables: {
                        input: {
                            id: jobID ? jobID : undefined,
                            craft_id: !jobID ? values.craft_id : undefined,
                            type: !values?.type ? 'last_minutes' : undefined,
                            ...values,
                            description: values.description ? values.description : undefined,
                        }
                    }
                });

                action();
                form.resetFields();
            } }>

            <Craft.Forms.Fields.SelectId
                disabled={ !!jobID }
                form={ form }
                standID={ standID }
                job={ job }
                filterElement={ craftsHide }
                setCraftType={ setCraftType }
                initialValue={ job?.id && `${ job?.craft.title } (${ job?.craft.type })` }
            />

            { isHide('type') &&
                <JobFormItems.SelectType
                    typeAll={ true }
                    form={ form }
                    initialValue={ job?.type }
                    initialValueCraft={ job?.craft.type }
                    craftTypeSelected={ craftType }
                />
            }

            { isHide('status') &&
                <JobFormItems.SelectStatus
                    form={ form }
                    initialValue={ job?.status }
                />
            }

            { isHide('description') &&
                <Form.Item
                    name="description"
                    label={ <Localize>FORMS.Input_Label_Description</Localize> }
                    initialValue={ job?.description }
                >
                    <Input.TextArea
                        maxLength={ 300 }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
                        autoSize={ { minRows: 3, maxRows: 5 } }
                    // showCount={
                    //     { formatter: ({ count, maxLength }) => maxLength - count }
                    // }
                    />
                </Form.Item>
            }

            { !!jobID &&

                <ul className='file-list'>

                    <li key='1'>
                        <span className='label-text'>
                            <Localize>FORMS.Input_Label_Attachment</Localize>
                        </span>

                        { job?.attachment ?

                            <div className='download-file'>
                                <Link
                                    to='#'
                                    onClick={ () => {
                                        window.open(
                                            job?.attachment,
                                            '_blank'
                                        );
                                    } }
                                >
                                    <Icons.Download /> <span className='link-text underline-none'>{ job?.attachment?.split('/').pop().split('.')[ 0 ] }</span>
                                </Link>
                                <span
                                    className="icon-delete-file"
                                    onClick={ () => {
                                        _setJobUploadFile({
                                            variables: {
                                                input: {
                                                    model: 'CraftJob',
                                                    model_id: jobID,
                                                    model_field: 'attachment',
                                                    file: null
                                                }
                                            }
                                        })
                                    } }>
                                    { loadingMutationJobUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }
                                </span>

                            </div>
                            :
                            <UploadFile
                                setMutation={ _setJobUploadFile }
                                loading={ loadingMutationJobUploadFile }
                                accept="image/gif, image/jpeg, image/png"
                                varSetMutation={ {
                                    input: {
                                        model: 'CraftJob',
                                        model_id: job?.id,
                                        model_field: 'attachment',
                                    }
                                } }
                                uploadBtn={
                                    <Button
                                        loading={ loadingMutationJobUploadFile }
                                        type='primary'
                                        size='small'
                                    >
                                        <span style={ { padding: "0 10px 1px" } }><Localize>GLOBAL.Button_Text_UploadFile</Localize></span>
                                    </Button>
                                } />
                        }

                    </li>
                </ul>
            }

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { jobID ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>JOB.Button_Text_Create</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default JobEditForm;


