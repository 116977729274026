import HallCreatePage from "./hall-create-page";
import HallSinglePage from "./hall-single-page";
import HallSubPage    from "./sub-pages";

const HallPages = {
    Create  : HallCreatePage,
    Single  : HallSinglePage,
    SubPage : HallSubPage
}

export default HallPages;
