import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import Icons from "components/icons";
import { Localize } from "components/service";


import './sidebar.scss';


const Sidebar = ({ appParams, setToggleSider }) => {

    let {
        selectedKeys = [ 'dashboard' ],
        openKeys = [ 'dashboard' ]
    } = appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState(openKeys);

    useEffect(() => {

        setOpenSubMenu(openKeys)

    }, [ appParams.activeMenu, openKeys ]);


    const items = [
        {
            key: 'exhibitions',
            label: <Link to="/exhibitions"><Icons.Events /><Localize>MENUS.Label_Exhibitions</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'users',
            label: <Link to="/users"><Icons.Users /><Localize>MENUS.Label_Users</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'subcontractors',
            label: <Link to="/subcontractors"><Icons.SubContractors /><Localize>MENUS.Label_Subcontractors</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'last-minute',
            label: <Link to="/last-minute"><Icons.LastMin /><Localize>MENUS.Label_LastMin</Localize></Link>,
            className: 'border-menu-item',
            onClick: () => setToggleSider(true),
            // disabled: true,
        }
    ];


    return (
        <>
            <Menu
                items={ items }
                className='sidebar-menu'
                theme="dark"
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ (keys) => setOpenSubMenu(keys) }
                style={ { borderRight: 0 } }>
            </Menu>
        </>
    );
};

export default Sidebar;