import { useMutation } from "@apollo/client";

import { STANDS_ACTIONS } from "graphql/mutation/stand-gql";
import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationCraftActions = ({ variables }) => {


    const [ _setCraftActions, { loading } ] = useMutation(STANDS_ACTIONS,
        {
            update(cache, { data }) {

                const {
                    standsActions: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_STANDS_CURSOR,
                    variables
                },
            ]
        }
    );



    return {
        _setCraftActions,
        loadingMutationCraftActions: loading,
    }
};

export default useMutationCraftActions;

