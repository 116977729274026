import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { standUseMutation } from '../hooks';
import StandConst from '../stand-const';
import Event from 'components/events';
import Icons from 'components/icons';
import { EntityRemoveButton, Localize } from 'components/service';


const StandActionField = ({ stand }) => {


    const history = useHistory();

    const {
        _setMutationStandDelete,
        loadingMutationStandDelete,
    } = standUseMutation.delete({ standID: stand.id, eventID: stand.ee_id });


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ Event.Const.basePath }/${ stand.ee_id }${ StandConst.basePath }/${ stand.id }`)
                },
                {
                    label: <Localize>MENUS_DROP.Label_ViewLogs</Localize>,
                    key: '2',
                    icon: <Icons.Logs />,
                    onClick: () => history.push(`${ Event.Const.basePath }/${ stand.ee_id }${ StandConst.basePath }/${ stand.id }/logs`)
                },
                {
                    label: <Localize>MENUS_DROP.Label_ViewKeys</Localize>,
                    key: '3',
                    icon: <Icons.Key />,
                    onClick: () => history.push(`${ Event.Const.basePath }/${ stand.ee_id }${ StandConst.basePath }/${ stand.id }/keys`)
                },
                {
                    key: '4',
                    icon:
                        <EntityRemoveButton
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                            }
                            nameEntity={ Localize({ children: "ENTITY.Modal_Title_Stand" }).props.children }
                            dataNameEntity={ stand.exhibitor }
                            loading={ loadingMutationStandDelete }
                            deleteMutation={ _setMutationStandDelete }
                            variables={ {
                                id: stand.id,
                            } }
                        />,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default StandActionField;