import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { GET_EVENTS } from 'graphql/query/event-gql';

import Events from 'components/events';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables, Elements } from "components/layout";
import { UploadFile, Localize } from "components/service";
import { gqlBuilderWhere } from "utils";


const basePath = Events.Const.basePath;
const tableHelper = Events.Helpers.TableMain;


const EventsPage = () => {

    useActiveMenuItem([ "exhibitions" ], [ "exhibitions" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ variables, setVariables ] = useState({});

    const eventsFilters = JSON.parse(localStorage.getItem("eventsFilters"));

    const {
        _setEventImportReimport,
        loadingMutationEventImportReimport,
        dataMutationEventImportReimport,
    } = Events.Hooks.importReimport({ variables });


    return (
        <PageWrapDefault
            className="page-events"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Exhibitions</Localize> }
        // staticPath="/exhibitions"
        >
            <Tables.Main
                model="exhibitionEvents"
                query={ GET_EVENTS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                setVariables={ setVariables }
                searchText={ searchText }
                objFilter={ filters || gqlBuilderWhere(eventsFilters) }
                tableHelper={ tableHelper }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Events.Filters.Table setSearchText={ setSearchText } setFilters={ setFilters } filters={ filters } eventsFilters={ eventsFilters } />
                    </div>

                    <div className="col">

                        { loadingMutationEventImportReimport &&
                            <Elements.Importing
                                loading={ loadingMutationEventImportReimport }
                                modalTitle={ <Localize>ENTITY.Modal_Title_Importing</Localize> } />
                        }


                        { dataMutationEventImportReimport &&
                            <Elements.Imported
                                data={ dataMutationEventImportReimport.eeImportOrReImport }
                                isImport={ true }
                                modalTitle={ <Localize>ENTITY.Modal_Title_Imported</Localize> } />
                        }

                        <UploadFile
                            setMutation={ _setEventImportReimport }
                            loading={ loadingMutationEventImportReimport }
                            uploadBtn={
                                <Button style={ { maxWidth: '100%' } } type='primary'>
                                    <PlusOutlined /> <Localize>EXHIBITION.Button_Text_Import</Localize>
                                </Button>
                            } />

                        <Link
                            className="ant-btn ant-btn-primary"
                            to={ `${ basePath }/create` }
                        >
                            <PlusOutlined /> <Localize>EXHIBITION.Button_Text_Create</Localize>
                        </Link>
                    </div>

                </div>

            </Tables.Main>
        </PageWrapDefault>
    )
};

export default EventsPage;