import React from "react";
import { useMutation } from "@apollo/client";

import { GET_USERS } from "graphql/query/user-gql";
import { SUBCONTRACTOR_REMOVE_USER } from "graphql/mutation/subcontractor-gql";
import { errorNotification, successNotification } from "components/request-result";

import { EntityRemoveButton, Localize } from 'components/service';
import { CloseOutlined } from '@ant-design/icons';


const SubcontractorRemoveUserField = ({ user, subcontractor }) => {

    const text = <Localize deleteObjectType={ `${ user.name } ${ user.surname }` }>GLOBAL.Modal_Text_DeleteText</Localize>

    const [ subcontractorRemoveUser, { loading } ] = useMutation(SUBCONTRACTOR_REMOVE_USER,
        {
            refetchQueries: [ GET_USERS ],

            update(cache, { data }) {

                const {
                    subcontractorRemoveUser: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (

        <EntityRemoveButton
            modalButton={ <CloseOutlined className="icon-delete" /> }
            text={ text }
            loading={ loading }
            deleteMutation={ subcontractorRemoveUser }
            okText={ Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children }
            variables={ {
                user_id: user.id,
                subcontractor_id: subcontractor.id,
            } }
        />

    );

}

export default SubcontractorRemoveUserField;