import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { Form, Radio, Skeleton } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_EVENTS_CURSOR } from "graphql/query/event-gql";
import Icons from "components/icons";
import MultiSelect from 'components/layout/form-elements/multi-select';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";

import './events-radio-select.scss';


const EventRadioSelect = ({
    eventIDFilterOut = '',
    disableBtn = false,
    userID = undefined,
    iconExtraClass = '',
    iconDown = false,
    valueFieldEventID = undefined,
    query = GET_EVENTS_CURSOR,
    setBtnConfirm = () => { },
    clearLocalStore = () => { },
    rules = false,
    placeholder = Localize({ children: "FORMS.Input_Placeholder_ChooseExhibition" }).props.children,
    name = 'ee_id',
    model = "exhibitionEventsCursor",
    label = '',
    form,
    initialValue,
}) => {


    const [ searchEvent, setSearchEvent ] = useState("");

    let { data, loading, fetchMore } = useQuery(query, {
        variables: {
            user_id: userID,
            text: useDebounce(searchEvent),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    let {
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data,
            loading,
            fetchMore
        });

    nodes = nodes.filter((event) => event?.value !== eventIDFilterOut);


    return (
        <Form.Item
            initialValue={ initialValue }
            name={ name }
            label={ label }
            rules={ rules && [ { required: true, message: `Please ${ placeholder }` } ] }
        >

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                form={ form }
                modalButton={

                    <span className="select-model-modal-button">
                        <span>
                            { valueFieldEventID ? nodes?.filter(
                                ({ value }) => value === valueFieldEventID)[ 0 ]?.label
                                :
                                <span className="placeholder-text">{ placeholder }</span>
                            }
                        </span>
                        { valueFieldEventID ? iconDown ? <Icons.Arrow className={ `${ iconExtraClass }` } /> : <SyncOutlined /> : <Icons.Arrow className={ `${ iconExtraClass }` } /> }
                    </span>
                }>

                <strong className="modal-title">{ placeholder }</strong>

                <MultiSelect
                    name={ name }
                    disableBtn={ disableBtn }
                    setBtnConfirm={ setBtnConfirm }
                    formReset={ () => form.resetFields([ name, 'search' ]) }
                    setSearch={ setSearchEvent }
                    search={ searchEvent }
                    clearLocalStore={ clearLocalStore }
                    placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Exhibition" }).props.children }
                >

                    { searchEvent && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                        <Radio.Group
                            className='group-radio radio-dark'
                            options={ nodes }
                            onChange={ e => form.setFieldsValue({ [ name ]: e.target.value }) }
                        />
                    }

                    { pageInfo?.hasNextPage &&

                        <>
                            { loading && <Loader style={ { marginTop: '-30px' } } /> }
                            <Waypoint onEnter={ () => fetchMoreAction() } />
                        </>
                    }

                </MultiSelect>
            </ModalStandard>
        </Form.Item>
    )
};

export default EventRadioSelect;