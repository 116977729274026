import React from "react";
import { Switch, Route } from 'react-router-dom';
import UsersRoute from './users/users-route';
import EventsRoute from './events/events-route';
import UserGroupsRoute from './usergroups/usergroups-route';
import SubcontractorsRoute from "./subcontractors/subcontractors-route";
import HallsRoute from "./halls/halls-route";
import StandsRoute from "./stands/stands-route";
import LastMinuteRoute from "./last-minute/last-minute-route";
import { Pages } from "components/pages";


const AppRoutes = () => {

    return (

        <Switch>
            <Route path="/" exact>
                <Pages.Dashboard />
            </Route>
            <Route path="/user-groups">
                <UserGroupsRoute />
            </Route>
            <Route path="/users">
                <UsersRoute />
            </Route>
            <Route path="/exhibitions/:eeID/halls/:hallID">
                <HallsRoute />
            </Route>
            <Route path="/exhibitions/:eeID/stands/:standID">
                <StandsRoute />
            </Route>
            <Route path="/exhibitions">
                <EventsRoute />
            </Route>
            <Route path="/subcontractors">
                <SubcontractorsRoute />
            </Route>
            <Route path="/last-minute">
                <LastMinuteRoute />
            </Route>
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;