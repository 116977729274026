import EventFields from "./fields";
import EventForms from "./forms";
import EventActions from "./actions";
import EventConst from "./event-const";
import EventHelpers from "./helpers";
import EventFilters from "./filters";
import { eventUseMutation } from "./hooks";


const Events = {
  Actions: EventActions,
  Filters: EventFilters,
  Fields: EventFields,
  Forms: EventForms,
  Const: EventConst,
  Helpers: EventHelpers,
  Hooks: eventUseMutation
}

export default Events;