import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import StandForms from '../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;


const StandTableFilter = ({
    event,
    setSearchText,
    searchText,
    setFilters = false,
    filters,
    standsFilters,
    setResetSorting = () => { },
    objOrderBy }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState({});
    const [ count, setCount ] = useState();

    const filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {

        if (filters)
        {
            localStorage.setItem(`standsFilters_eeID-${ event?.id }`, JSON.stringify({ ...standsFilters, ...filterList }));
        } else form.resetFields([ 'AQUISE_NUMBER', 'PROAUF_NUMBER', 'BLOCK', 'CAD_NUMBER', 'HALL_ID', 'CORRIDOR', 'STAND_NUMBER', 'DELIVERY_TIME_AT' ]);


        if (searchText)
        {
            localStorage.setItem(`standsSearch_eeID-${ event?.id }`, JSON.stringify(searchText));
        } else
        {
            localStorage.removeItem(`standsSearch_eeID-${ event?.id }`);
            form.resetFields([ 'search' ]);
        }

    }, [ filters, searchText ]);


    useEffect(() => {

        if (standsFilters)
        {
            setCount(standsFilters);
        }

    }, []);
    /*eslint-enable */


    return (

        <Form
            key="filters-form"
            className="clear-css-prop table-filter-form"
            layout="vertical"
            form={ form }>

            <Form.Item
                initialValue={ searchText }
                name="search">

                <Search
                    className="filter-search"
                    prefix={ <SearchOutlined /> }
                    // defaultValue={ searchText }
                    placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_Stand" }).props.children }
                    onChange={ (e) => setSearchText(e.target.value) }
                />

            </Form.Item>

            <DrawerStandart
                width="287"
                extraClass={ 'modal-form filters' }
                modalButton={
                    <Button>
                        <Icons.Filter />
                        <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                        { filterCount && <span className="filter-count">{ filterCount }</span> }
                    </Button>
                }
            >
                <strong className="modal-title">
                    <Localize>FORMS.Title_Text_Filters</Localize>
                </strong>

                <StandForms.Filter
                    event={ event }
                    setCount={ setCount }
                    setFilters={ setFilters }
                    setFilterList={ setFilterList }
                    filterList={ filterList }
                    gqlBuilderWhere={ gqlBuilderWhere }
                    standsFilters={ standsFilters }
                    form={ form } />

            </DrawerStandart>

            <Button
                type="link"
                onClick={ () => {
                    setFilterList({});
                    setCount({});
                    setFilters(gqlBuilderWhere({}));
                    setSearchText('');
                    setResetSorting(objOrderBy);
                    localStorage.removeItem(`standsFilters_eeID-${ event?.id }`);
                    localStorage.removeItem(`standsSorting_eeID-${ event?.id }`);
                    localStorage.removeItem(`standsSearch_eeID-${ event?.id }`);
                } }
            >
                <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
            </Button>
        </Form>
    );
};

export default StandTableFilter;
