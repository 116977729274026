import useMutationFurnishingCreateUpdate from './furnishing-use-mutation-create-update';
import useMutationFurnishingDelete from "./furnishing-use-mutation-delete";
import useMutationFurnishingImportReimport from "./furnishing-use-mutation-import-reimport";
import useMutationFurnishingUploadFile from "./furnishing-use-mutation-upload-file";



export const furnishingUseMutation = {
  createUpdate: useMutationFurnishingCreateUpdate,
  delete: useMutationFurnishingDelete,
  importReimport: useMutationFurnishingImportReimport,
  uploadFile: useMutationFurnishingUploadFile,
};