import JobTypeField from "./job-type-field";
import JobActionField from "./job-action-field";
import JobStatusField from "./job-status-field";
import JobDateField from "./job-date-field";
import JobAttachmentField from "./job-attachment-field";
import JobCraftField from "./job-craft-field";


const JobFields = {
    Type: JobTypeField,
    Action: JobActionField,
    Status: JobStatusField,
    Date: JobDateField,
    Attachment: JobAttachmentField,
    Craft: JobCraftField
}


export default JobFields;