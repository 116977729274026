import JobHelpers from '../helpers';


const JobStatusField = ({ job }) => {


    const { statusColor, getTranslationStatus } = JobHelpers.Functions;

    return (
        <strong className={ `${ statusColor(job?.status) }` }>{ getTranslationStatus(job?.status) }</strong>
    );
};


export default JobStatusField;
