import { Link } from 'react-router-dom';
import Icons from 'components/icons';


const JobAttachmentField = ({ attachment }) => {

    return (
        <>
            {
                attachment ?

                    <div className='download-file'>
                        <Link
                            to='#'
                            onClick={ () => {
                                window.open(
                                    attachment,
                                    '_blank'
                                );
                            } }
                            className='underline-none'
                        >
                            <Icons.Download /> <span className='link-text'>{ attachment?.split('/').pop().split('.')[ 0 ] }</span>
                        </Link>
                    </div>
                    :
                    <strong>&mdash;</strong>
            }
        </>
    );

};

export default JobAttachmentField;