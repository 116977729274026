import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button } from "antd";
import { GET_LOGS } from "graphql/query/log-gql";
import Users from 'components/users';
import { Tables } from "components/layout";
import Icons from "components/icons";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const userBasePath = Users.Const.basePath;
const tableHelper = Users.Helpers.TableLogs;


const UserLogsSubPage = ({ user }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ userBasePath }`
        },
        {
            label: `${ user?.name } ${ user?.surname }`,
            path: `${ userBasePath }/${ user?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Logs" }).props.children,
            path: `${ userBasePath }/${ user?.id }/logs`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const userID = user?.id;
    const routeUrl = `${ userBasePath }/${ userID }/logs`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);
    const currentPage = match ? parseInt(match.params.pageNum) : 1;

    const [ eventID, setEventID ] = useState(false);
    const [ filters, setFilters ] = useState();

    const { _setUserReport, loadingMutationUserReport } = Users.Hooks.report();


    return (

        <Tables.Main
            model="logs"
            query={ GET_LOGS }
            querySkip={ !eventID }
            varSet={ {
                ee_id: eventID,
                user_id: userID,
                perPage: 30,
            } }
            tableVisible={ !!eventID }
            routeUrl={ routeUrl }
            currentPage={ currentPage }
            objFilter={ filters }
            objOrderBy={ [ { column: "CREATED_AT", order: "DESC" } ] }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar filter-users-log">

                <div className="col">
                    <Users.Filters.TableLogs setEventID={ setEventID } userID={ userID } eventID={ eventID } setFilters={ setFilters } />
                </div>

                <div className="col">

                    { !!eventID &&

                        <Button
                            className="btn-export-logs"
                            loading={ loadingMutationUserReport }
                            disabled={ loadingMutationUserReport }
                            type='primary'
                            onClick={ () => _setUserReport({
                                variables: {
                                    user_id: userID,
                                }
                            }) }
                        >
                            { !loadingMutationUserReport && <Icons.Download /> } <Localize>GLOBAL.Button_Text_ExportCsv</Localize>
                        </Button>
                    }
                </div>
            </div>

        </Tables.Main>
    );
};

export default UserLogsSubPage;

