import { Link } from 'react-router-dom';
import EventConst from '../event-const';


const EventViewField = ({ id, count, model }) => {

    return (
        <div className='view-box-field'>
            { count ?
                <Link
                    to={ `${ EventConst.basePath }/${ id }/${ model }` }
                >
                    <span className='count'>{ count }</span>
                    <span className='btn-view'>view</span>
                </Link> : <strong>&mdash;</strong> }
        </div>

    );
};

export default EventViewField;