import React from "react";
import { Form, Button, DatePicker } from 'antd';
import { standUseMutation } from '../../hooks';
import StandFormItems from '../../forms/fields';
import Hall from 'components/hall';
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";


const StandChangeDeliveryTimeForm = ({ event, standsSelectAll, stands, hallID, variables, action }) => {

    const [ form ] = Form.useForm();
    const valueFieldWhere = Form.useWatch('where', form);


    const {
        _setStandActions,
        loadingMutationStandActions,
    } = standUseMutation.actions({ variables });


    return (
        <Form
            layout="vertical"
            form={ form }
            className="model-form"
            onFinish={ (values) => {

                _setStandActions({
                    variables: {
                        action: 'delivery_time',
                        ee_id: event?.id,
                        stand_id: valueFieldWhere === 'stands_checkboxes' && stands.length > 0 ? stands : [],
                        hall_id: valueFieldWhere === 'stands_hall' && hallID ? hallID : undefined,
                        ...values,
                        value: timeFormat(values.value, 'YYYY-MM-DD HH:mm'),
                    }
                });

                action();
                form.resetFields();
            } }>

            <StandFormItems.SelectWere form={ form } standsSelectAll={ standsSelectAll } stands={ stands } hallID={ hallID } />

            { valueFieldWhere === 'stands_hall' && !hallID &&
                <Hall.Forms.Fields.Select
                    form={ form }
                    ee_id={ event?.id } />
            }

            <Form.Item
                name="value"
                label={ <Localize>FORMS.Input_Label_NewDeliveryTime</Localize> }
                rules={ [ { required: true, message: <Localize>FORM_RULES.Required_DeliveryTime</Localize> } ] }
            >
                <DatePicker
                    minuteStep={ 15 }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_DeliveryTime" }).props.children }
                    showTime format="DD.MM.YYYY HH:mm"
                />
            </Form.Item>

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationStandActions }
                >
                    <Localize>GLOBAL.Button_Text_Save</Localize>

                </Button>
            </div>
        </Form>
    );
};

export default StandChangeDeliveryTimeForm;


