import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Button, Checkbox, Skeleton } from 'antd';
import MultiSelect from 'components/layout/form-elements/multi-select';
import Events from "components/events";
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from 'components/service';


const UserCopyEventForm = ({
    action,
    propsObj: {
        query,
        queryVar,
        mutation,
        mutationName,
        model,
        modelID
    } }) => {


    const [ form ] = Form.useForm();

    const [ eventID, setEventID ] = useState(false);

    const [ btnConfirm, setBtnConfirm ] = useState({
        ee_id: false,
    });

    const valueFieldEventID = Form.useWatch('ee_id', form);
    const valueFieldUserID = Form.useWatch('user_id', form) || [];


    /*eslint-disable */
    useEffect(() => {
        setEventID(valueFieldEventID);
    }, [ btnConfirm ]);
    /*eslint-enable */

    const variables = eventID ? { first: 10000, ee_id: eventID } : { ...queryVar }


    const { data: dataQuery, loading: loadingQuery } = useQuery(query, {
        variables,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });


    const initialValues = dataQuery?.users?.data?.map(
        user => ({
            label: `${ user.name } ${ user.surname }`,
            value: user.id,
        })
    )

    const userListInit = dataQuery?.users?.data?.map((user) => user?.id.toString());

    const [ currentEventUsers, setCurrentEventUsers ] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        setCurrentEventUsers(userListInit)
    }, [ !eventID ]);
    /*eslint-enable */


    const [ _setMutation, { loading: loadingMutation } ] = useMutation(mutation,

        {
            refetchQueries: [ query ],

            update(cache, { data }) {

                const {
                    [ mutationName ]: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );


    return (
        <Form
            key="add-user-form"
            layout="vertical"
            className="add-user-form"
            form={ form }
            onFinish={ () => {

                _setMutation({
                    variables: {
                        [ model ]: modelID,
                        user_id: valueFieldUserID.length > 0 ? [ ...currentEventUsers, ...valueFieldUserID ] : [ ...currentEventUsers, ...userListInit ],
                    }
                });

                action();
            } }
        >

            <Events.Forms.Fields.RadioSelect
                name="ee_id"
                label={ <Localize>FORMS.Input_Label_Exhibition</Localize> }
                form={ form }
                iconExtraClass="arrow-down"
                valueFieldEventID={ valueFieldEventID }
                setBtnConfirm={ setBtnConfirm }
                eventIDFilterOut={ modelID }
            />

            <br />

            { eventID &&

                <Form.Item
                    name="user_id"
                >

                    { loadingQuery ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :

                        <MultiSelect
                            disableBtn={ true }
                            disableSearch={ true }
                        >

                            <Checkbox.Group
                                className='group-checkbox'
                                options={ initialValues }
                                defaultValue={ userListInit }
                                onChange={ value => form.setFieldsValue({ 'user_id': value }) }
                            />

                        </MultiSelect>
                    }

                </Form.Item>
            }

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutation }
                >
                    <Localize>GLOBAL.Button_Text_Confirm</Localize>
                </Button>
            </div>

        </Form>
    );

};

export default UserCopyEventForm;