import UserFields from "../fields";
import { Localize } from "components/service";
import Craft from "components/craft";
import { helperFunc } from 'utils'


const userLogsColumns = [
    {
        title: <Localize>TABLES.Column_Title_Time</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Hall</Localize>,
        dataIndex: 'hall_title',
        columnIndex: 'HALL_ID',
    },
    {
        title: <Localize>TABLES.Column_Title_StandNumber</Localize>,
        dataIndex: 'stand_number',
        columnIndex: 'STAND_NUMBER',
    },
    {
        title: <Localize>TABLES.Column_Title_Craft</Localize>,
        dataIndex: 'craft_type',
        columnIndex: 'CRAFT_TYPE',
    },
    {
        title: <Localize>TABLES.Column_Title_JobNumber</Localize>,
        dataIndex: 'craft_job_id',
        columnIndex: 'CRAFT_JOB_ID',
    },
    {
        title: <Localize>TABLES.Column_Title_JobType</Localize>,
        dataIndex: 'craft_job_type',
        columnIndex: 'CRAFT_JOB_TYPE',
    },
    {
        title: <Localize>TABLES.Column_Title_Message</Localize>,
        dataIndex: 'message',
        columnIndex: 'MESSAGE',
        width: '27%'

    }
];


const userLogsData = (logs) => {

    const { getTranslationCraft } = Craft.Helpers.Functions;
    const { charSplitToUpper } = helperFunc;


    if (!logs)
    {
        return [];
    }


    return logs.map(log => {

        return {
            key: log.id,
            created_at: log.created_at,
            hall_title: log.hall_title,
            stand_number: log.stand_number,
            craft_type: getTranslationCraft(charSplitToUpper(log?.craft_type, '_', false)),
            craft_job_id: log.craft_job_id,
            craft_job_type: log.craft_job_type,
            message: <UserFields.Message log={ log } />,
        };

    });
};


const UserLogsTableHelper = {
    columns: userLogsColumns,
    data: userLogsData,
}

export default UserLogsTableHelper;