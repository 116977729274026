import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const UserLanguageSelect = ({
    disabled = false,
    rules = true,
    initialValue,
    mode = '',
    name = 'language_slug',
    label = Localize({ children: "FORMS.Input_Label_Language" }).props.children
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                dropdownClassName="select-serch-dropdown"
                optionFilterProp="value"
            >

                <Select.Option key="en" value="en"><Localize>FORMS.Select_OptionLabel_English</Localize> </Select.Option>
                <Select.Option key="de" value="de"><Localize>FORMS.Select_OptionLabel_Deutsch</Localize> </Select.Option>

            </Select>
        </Form.Item>
    );

}

export default UserLanguageSelect;


