import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_EVENT_ATTACHMENTS } from "graphql/query/event-gql";

import Events from 'components/events';
import Icons from "components/icons";
import { Tables } from "components/layout";
import { ModalStandard, Localize, UploadFile } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const basePath = Events.Const.basePath;
const tableHelper = Events.Helpers.TableAttachments;


const EventAttachmentsSubPage = ({ event }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: event?.title,
            path: `${ basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Documents" }).props.children,
            path: `${ basePath }/${ event?.id }/'documents'`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const [ variables, setVariables ] = useState({});

    const {
        _setAttachmentCreateUpdate,
        loadingMutationAttachmentCreateUpdate,
        dataMutationAttachmentCreateUpdate
    } = Events.Hooks.attachmentCreateUpdate({ variables });

    const {
        _setAttachmentOrderSet,
    } = Events.Hooks.attachmentOrderSet({ variables });

    let match = useRouteMatch(`${ basePath }/${ event.id }/documents/page/:pageNum`);

    const attachment = dataMutationAttachmentCreateUpdate?.attachmentCreate?.attachment;


    return (
        <Tables.DragSorting
            model="attachments"
            extraClass="event-attachments"
            query={ GET_EVENT_ATTACHMENTS }
            varSet={ {
                ee_id: event?.id,
                perPage: 30,
            } }
            currentObj={ event }
            routeUrl={ `${ basePath }/${ event.id }/documents` }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            setVariables={ setVariables }
            mutationOrderSet={ _setAttachmentOrderSet }
            objFilter={ {} }
            tableHelper={ tableHelper }
        >
            <div className="upload-document-holder">
                <UploadFile
                    accept="*/*"
                    varSetMutation={ {
                        ee_id: event?.id
                    } }
                    setMutation={ _setAttachmentCreateUpdate }
                    loading={ loadingMutationAttachmentCreateUpdate }
                    uploadBtn={
                        <div className="upload-document-box">
                            <Icons.Download /> <strong className="text"><Localize>GLOBAL.Button_Text_AddFile</Localize></strong>
                        </div>
                    } />
            </div>

            { attachment &&

                <ModalStandard
                    maskClosable={ false }
                    defaultShow={ true }
                    closable={ false }
                    width={ 380 }
                    extraClass={ 'modal-form' }
                >
                    <strong className='modal-title'><Localize>FORMS.Title_AddFile</Localize></strong>
                    <Events.Forms.Attachment documentCreate={ true } attachment={ attachment } variables={ variables } />
                </ModalStandard>
            }

        </Tables.DragSorting>
    );
};

export default EventAttachmentsSubPage;
