import { gql } from '@apollo/client';


export const JOB_CREATE = gql`
  mutation CraftJobCreate($input: CraftJobCreateFieldsInput!) {
    craftJobCreate(input: $input) {
      label
      message
      craft_job {
        id
        type
        craft_id
        craft_type
        status
        description
        attachment
        completor_id
        completor {
          id
          name
        }
        completed_at
        craft {
          id
          title
        }
        created_at
        updated_at
      }
    }
  }
`;


export const JOB_UPDATE = gql`
  mutation CraftJobUpdate($input: CraftJobUpdateFieldsInput!) {
    craftJobUpdate(input: $input) {
      label
      message
      craft_job {
        id
        type
        craft_id
        craft_type
        status
        description
        attachment
        completor_id
        completor {
          id
          name
          surname
          created_at
          role {
            label
          }
        }
        completed_at
        craft {
          id
          title
        }
        created_at
        updated_at
      }
   }
}
`;


export const JOB_UPLOAD_FILE = gql`
    mutation FileUpload($input: FileUpload) {
        fileUpload(input: $input) {
        label
        message
        }
    }
`;


export const JOB_DELETE = gql`
  mutation CraftJobDelete($id: ID!) {
    craftJobDelete(id: $id) {
      label
      message
    }
  }
`;













