import { Tooltip } from 'antd';
import { ModalStandard } from 'components/service';
import CraftForms from '../forms';
import CraftHelpers from '../helpers';
import { helperFunc } from 'utils'
import { Localize } from 'components/service';


const CraftTypeField = ({ craft, variables }) => {

    const { charSplitToUpper } = helperFunc;
    const { craftColor, getIconCraft, getTranslationCraft } = CraftHelpers.Functions;

    return (
        <div className='craft-link'>
            <Tooltip overlayClassName="craft-tooltip" destroyTooltipOnHide={ true } title={ craft?.title }>
                <span
                    className={ `craft ${ craftColor(craft?.status) }` }>

                    <span className='count'>
                        { craft?.type.match(/\d+/) }
                    </span>

                    { getIconCraft(charSplitToUpper(craft?.type, '_', false)) }
                </span>
            </Tooltip>

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                modalButton={
                    <strong className='title'>{ getTranslationCraft(charSplitToUpper(craft?.type, '_', false)) }</strong>
                }>

                <strong className='modal-title'><Localize>FORMS.Title_EditCraft</Localize></strong>

                <CraftForms.Edit standID={ craft?.stand_id } craft={ craft } variables={ variables } />

            </ModalStandard>
        </div>
    );
};


export default CraftTypeField;