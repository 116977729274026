import Fields from "components/usergroup/fields";
import { Localize } from "components/service";


const userGroupsColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_Label</Localize>,
        dataIndex: 'label',
        columnIndex: 'LABEL',
        sorter: true,
        width: '30%'
    },
    {
        title: <Localize>TABLES.Column_Title_GroupRights</Localize>,
        dataIndex: 'groupRights',
        width: '30%'
    },
    {
        title: <Localize>TABLES.Column_Title_NumberUsers</Localize>,
        dataIndex: 'usersCount',
        width: '15%'
    },
    {
        // title     : 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];

const userGroupsData = (roles) => {

    if (!roles)
    {
        return [];
    }

    return roles.map(role => {

        return {
            key: role.id,
            id: role.id,
            label: <Fields.EditLink role={ role } />,
            groupRights: <Fields.RightsAll role={ role } />,
            usersCount: <Fields.UsersView role={ role } />,
            action: <Fields.Action role={ role } />,
        };

    });

};

const UserGroupsTableHelper = {
    columns: userGroupsColumns,
    data: userGroupsData,
}

export default UserGroupsTableHelper;