import React from "react";
import { Form, Input, Button, DatePicker } from 'antd';
import { standUseMutation } from './../../hooks';
import Hall from 'components/hall';
import { EntityRemoveButton, Localize } from 'components/service';
import { timeFormat } from "utils/helper-functions";
import moment from 'moment';


const StandEditForm = ({ eventID, stand }) => {

    const [ form ] = Form.useForm();
    const standID = stand?.id;

    const {
        _setStandCreateUpdate,
        loadingMutationStandCreateUpdate: loading,
    } = standUseMutation.createUpdate({ standID, eventID });

    const {
        _setMutationStandDelete,
        loadingMutationStandDelete,
    } = standUseMutation.delete({ standID, eventID });


    return (
        <Form
            key="edit-stand-form"
            layout="vertical"
            form={ form }
            className="model-form edit-stand-form"
            onFinish={ (values) => {
                _setStandCreateUpdate({
                    variables: {
                        input: {
                            id: standID ? +stand.id : undefined,
                            ee_id: +eventID,
                            ...values,
                            delivery_time_at: timeFormat(values.delivery_time_at),
                        }
                    }
                })
            } }>

            <Form.Item
                name="exhibitor"
                label={ <Localize>FORMS.Input_Label_Exhibitor</Localize> }
                initialValue={ stand?.exhibitor }
                rules={ [
                    { required: true, message: <Localize>FORM_RULES.Required_Exhibitor</Localize> }
                ] }
            >
                <Input name="exhibitor" />
            </Form.Item>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">

                <Form.Item
                    name="aquise_number"
                    label={ <Localize>FORMS.Input_Label_AcqstnNumber</Localize> }
                    initialValue={ stand?.aquise_number }
                >
                    <Input name="aquise_number" />
                </Form.Item>

                <Form.Item
                    name="proauf_number"
                    label={ <Localize>FORMS.Input_Label_ProaufNumber</Localize> }
                    initialValue={ stand?.proauf_number }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_ProafNumber</Localize> }
                    ] }
                >
                    <Input name="proauf_number" />
                </Form.Item>

                <Hall.Forms.Fields.Select
                    form={ form }
                    ee_id={ +eventID }
                    initialValue={ stand?.hall_id } />

                <Form.Item
                    name="block"
                    label={ <Localize>FORMS.Input_Label_Block</Localize> }
                    initialValue={ stand?.block }
                >
                    <Input name="block" />
                </Form.Item>

                <Form.Item
                    name="corridor"
                    label={ <Localize>FORMS.Input_Label_Corridor</Localize> }
                    initialValue={ stand?.corridor }
                >
                    <Input name="corridor" />
                </Form.Item>

                <Form.Item
                    name="stand_number"
                    label={ <Localize>FORMS.Input_Label_StandNumber</Localize> }
                    initialValue={ stand?.stand_number }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_StandNumber</Localize> }
                    ] }
                >
                    <Input name="stand_number" />
                </Form.Item>

                <Form.Item
                    name="cad_number"
                    label={ <Localize>FORMS.Input_Label_CadNumber</Localize> }
                    initialValue={ stand?.cad_number }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_CadNumber</Localize> }
                    ] }
                >
                    <Input name="cad_number" />
                </Form.Item>

                <Form.Item
                    name="delivery_time_at"
                    label={ <Localize>FORMS.Input_Label_DeliveryTime</Localize> }
                    initialValue={ moment(stand?.delivery_time_at) }
                >
                    <DatePicker
                        minuteStep={ 15 }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY HH:mm"
                    />
                </Form.Item>

            </div>

            <div className="form-btn-holder">
                { standID &&
                    <EntityRemoveButton
                        modalButton={
                            <Button className="ant-btn-secondary">
                                <Localize>STAND.Button_Text_Delete</Localize>
                            </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_ExhibitionStand" }).props.children }
                        dataNameEntity={ `${ stand?.exhibitor }` }
                        loading={ loadingMutationStandDelete }
                        deleteMutation={ _setMutationStandDelete }
                        variables={ {
                            id: standID,
                        } }
                    /> }

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { standID ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>STAND.Button_Text_Create</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default StandEditForm;


