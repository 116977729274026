import React from "react";
import { Form, Input, Button } from 'antd';
import { useMutation } from "@apollo/client";
import { SUBCONTRACTOR_UPDATE } from "graphql/mutation/subcontractor-gql";
import SubcontractorDeleteForm from "../delete-form";
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from "components/service";


const SubcontractorEditForm = ({ subcontractor }) => {

    const [ form ] = Form.useForm();

    const [ subcontractorUpdate, { loading } ] = useMutation(SUBCONTRACTOR_UPDATE,
        {
            update(cache, { data }) {

                const {
                    subcontractorUpdate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (
        <>
            <Form
                key="edit-subcontractor"
                layout="vertical"
                form={ form }
                className="subcontractor-form"
                initialValues={ {
                    title: subcontractor.title,
                    number: subcontractor.number,
                    type: subcontractor.type,
                    description: subcontractor.description,
                } }

                onFinish={ (values) => {
                    subcontractorUpdate({
                        variables: {
                            input: {
                                id: subcontractor.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    name="title"
                    label={ <Localize>FORMS.Input_Label_Title</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Title</Localize> }
                    ] }
                >
                    <Input name="label" />
                </Form.Item>

                <Form.Item
                    name="number"
                    label={ <Localize>FORMS.Input_Label_Number</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Number</Localize> }
                    ] }
                >
                    <Input name="label" />
                </Form.Item>

                {/* <Form.Item
                    name  = "type"
                    label = "type"
                    rules = {[
                        { required: false, message: 'Please input type' }
                    ]}
                >
                    <Input name="label" />
                </Form.Item> */}

                <div className="form-btn-holder">

                    <SubcontractorDeleteForm subcontractor={ subcontractor } />

                    <Button
                        className="btn-right"
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default SubcontractorEditForm;