import React from "react";
import { useMutation } from "@apollo/client";

import { GET_USERS }        from "graphql/query/user-gql";
import { ROLE_REMOVE_USER } from "graphql/mutation/role-gql";
import { errorNotification, successNotification } from "components/request-result";

import EntityRemoveButton from 'components/service/entity-remove-button';
import { CloseOutlined }  from '@ant-design/icons';


const UserGroupRemoveUserField = ( { user, role } ) => {

    const text = `Are you sure you want to remove the ${ user.name } ${ user.surname } from the group ${ role.label }?`;

    const [roleRemoveUser, { loading }] = useMutation( ROLE_REMOVE_USER,
        {
            refetchQueries: [ GET_USERS ],

            update( cache, { data } ) {

                const {
                    roleRemoveUser : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title       : label,
                    description : message
                });

            },
            onError( error ){
                errorNotification( error );
            }
        }
    );


    return(

        <EntityRemoveButton
            modalButton    = { <CloseOutlined className="icon-delete" /> }
            text           = { text }
            loading        = { loading }
            deleteMutation = { roleRemoveUser }
            okText         = "Remove"
            variables      = { {
                user_id : user.id,
                role_id : role.id,
            } }
        />

    );

}

export default UserGroupRemoveUserField;