import UserFields from "components/users/fields";
import UserGroupFields from "components/usergroup/fields";
import { Localize } from "components/service";


const usersColumns = [
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: 'name',
        columnIndex: 'NAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Phone</Localize>,
        dataIndex: 'phone',
        columnIndex: 'PHONE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Email</Localize>,
        dataIndex: 'email',
        columnIndex: 'EMAIL',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Company</Localize>,
        dataIndex: 'company',
        columnIndex: 'COMPANY',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Exhibition</Localize>,
        dataIndex: 'exhibition'
    },
    {
        // title     : 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];


const usersData = (users, role) => {

    if (!users)
    {
        return [];
    }

    return users.map(user => {

        return {
            key: user.id,
            name: <UserFields.Info user={ { id: user.id, name: user.name, surname: user.surname } } />,
            phone: user.phone ?? <strong>&mdash;</strong>,
            email: user.email ? <a href={ `mailto:${ user.email }` }>{ user.email }</a> : <strong>&mdash;</strong>,
            company: <UserFields.Company subcontractor={ user.subcontractor } company={ user.company } />,
            exhibition: <UserFields.Exhibition exhibition={ user.exhibitionEvent } />,
            action: <UserGroupFields.RemoveUser user={ user } role={ role } />
        };

    });

};


const UserGroupUsersTableHelper = {
    columns: usersColumns,
    data: usersData,
}

export default UserGroupUsersTableHelper;