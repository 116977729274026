import { gql } from '@apollo/client';


export const GET_ROLE = gql`
    query GetRole(
        $id: ID,
    ) {
        role(
            id: $id,
        ){
            id
            label
            slug
            description
            colorHex
            usersCount
            permissions{
                id
                label
                panel
                type
                value
                description
                created_at
                updated_at
            }
            permissionsCount{
                crm
                app
            }
            created_at
            updated_at
        }
    }
`;


export const GET_ROLES = gql`
    query GetRoles(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryRolesOrderByOrderByClause!],
        $where: QueryRolesWhereWhereConditions,
    ) {
        roles(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                label
                colorHex
                permissionsCount{
                    crm
                    app
                }
                usersCount
            }
        }
    }
`;