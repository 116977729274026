import { gql } from '@apollo/client';


export const GET_SUBCONTRACTOR = gql`
    query GetSubcontractor($id: ID) {
        subcontractor(id: $id) {
            id
            title
            number
            type
            description
            usersCount
            created_at
            updated_at
        }
    }
`;


export const GET_SUBCONTRACTOR_CURSOR = gql`
    query SubcontractorsCursor($text: String, $first: Int, $after: String) {
        subcontractorsCursor(text: $text, first: $first, after: $after) {
            pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
        }
        edges {
            node {
                id
                title
                number
                type
                description
            }
            cursor
        }
     }
  }
`;

export const GET_SUBCONTRACTORS = gql`
    query GetSubcontractors(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QuerySubcontractorsOrderByOrderByClause!],
    ) {
        subcontractors(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                title
                number
                type
                description
                usersCount
                created_at
                updated_at
            }
        }
    }
`;




