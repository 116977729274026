import React from "react";
import { useQuery } from "@apollo/client";
import { GET_STAND } from 'graphql/query/stand-gql';
import { GET_EVENT_ID } from 'graphql/query/event-gql';
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import StandRoute from 'components/routes/stands/stand-route';
import Events from 'components/events';
import { Localize } from "components/service";


const StandPage = ({ match, history }) => {

    useActiveMenuItem([ "stands" ], [ "stands" ]);

    const path = history.location.pathname.split('/').pop();
    const eventID = match ? parseInt(match.params.eeID) : null;
    const standID = match ? parseInt(match.params.standID) : null;

    const { data: { exhibitionEvent: event } = {}, loading: loadingEvent } = useQuery(GET_EVENT_ID, {
        skip: !eventID,
        variables: {
            id: eventID
        }
    });


    const { data: { stand } = {}, loading: loadingStand } = useQuery(GET_STAND, {
        skip: loadingEvent,
        variables: {
            id: standID,
            ee_id: +event?.id
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });

    const loading = path === 'create' ? false : loadingEvent || loadingStand;

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Crafts" }).props.children,
            path: "crafts"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Jobs" }).props.children,
            path: "jobs"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Logs" }).props.children,
            path: "logs"
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Keys" }).props.children,
            path: "keys"
        }
    ];



    return (
        <PageWrapDefault
            className="page-stand"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateStand</Localize> : !loading && stand ? stand.exhibitor : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && stand }
            pageNavbar={ !loading && stand ? pageNavbar : false }
            staticPath={ `${ Events.Const.basePath }/${ eventID }/stands/${ standID }` }
        >
            <StandRoute
                event={ event }
                stand={ stand }
                history={ history } />

        </PageWrapDefault>
    );
};

export default StandPage;





