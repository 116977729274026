import React from 'react';
import { Form, Select } from 'antd';

import UserGroupConst from '../../usergroup-const';
import Icons from 'components/icons';
import { Localize } from "components/service";


const { Option } = Select;

const UserGroupColorSelectFormItem = () => {

    return (
        <>
            <Form.Item
                name="colorHex"
                label={ <Localize>FORMS.Input_Label_GroupColor</Localize> }
            >
                <Select
                    suffixIcon={ <Icons.Arrow /> }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_ChooseColor" }).props.children }
                >
                    <Option key="null" value={ null }><Localize>FORMS.Select_OptionLabel_ChooseColor</Localize> </Option>

                    { UserGroupConst.colorHexList.map((item) => (

                        <Option key={ item.text } value={ item.value }>

                            <span className="color-box" style={ { background: item.value } }></span>

                            <span style={ { textTransform: 'uppercase', color: item.value } }>
                                { item.text }
                            </span>

                        </Option>

                    )) }

                </Select>
            </Form.Item>
        </>
    );

}

export default UserGroupColorSelectFormItem;