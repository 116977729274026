import { useMutation } from "@apollo/client";

import { FURNISHING_UPLOAD_FILE } from "graphql/mutation/furnishing-gql";
import { GET_FURNISHINGS } from "graphql/query/furnishing-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationFurnishingUploadFile = ({ id }) => {

    const [ _setFurnishingUploadFile, { loading } ] = useMutation(FURNISHING_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const {
                    fileUpload: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_FURNISHINGS,
                    variables: {
                        id
                    }
                },
            ]
        }
    );


    return {
        _setFurnishingUploadFile,
        loadingMutationFurnishingUploadFile: loading,
    }
};

export default useMutationFurnishingUploadFile;

