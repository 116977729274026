import { Link } from "react-router-dom";
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useLogout, useMe } from "components/use-hooks";
import Icons from 'components/icons';
import { Localize } from "components/service";


import './dropdown-menu.scss';


const DropMenu = () => {

  const { me } = useMe();
  const { logout } = useLogout();

  const menu = (
    <Menu
      items={ [
        {
          key: '1',
          label: <Link to={ { pathname: `/users/${ me.id }` } } > <UserOutlined /> &nbsp; <Localize>MENUS_DROP.Label_MyProfile</Localize></Link>,
        },
        {
          key: '2',
          label: <span onClick={ () => logout({ redirectRoute: "/" }) }><LogoutOutlined /> &nbsp; <Localize>MENUS_DROP.Label_Logout</Localize></span>
        }
      ] }
    />
  );


  return (
    <Dropdown
      className="dropdown-profile"
      overlay={ menu }
      trigger={ [ 'click' ] }
    >
      <span className="my-profile-link" onClick={ e => e.preventDefault() }>
        <Icons.Profile className="profile-icon" /> <span className="profile-info">{ `${ me.name } ${ me.surname }` }</span> <DownOutlined />
      </span>
    </Dropdown>
  )
};


export default DropMenu;