import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import EventForms from '../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;



const EventTableFilter = ({ setSearchText, setFilters = false, filters, eventsFilters }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState({});
    const [ count, setCount ] = useState();

    const filterCount = helperFunc.countFilterList(count);


    /*eslint-disable */
    useEffect(() => {

        if (filters)
        {

            localStorage.setItem("eventsFilters", JSON.stringify({ ...eventsFilters, ...filterList }));
        } else form.resetFields();

    }, [ filters ]);


    useEffect(() => {

        if (eventsFilters)
        {
            setFilters(gqlBuilderWhere(eventsFilters));
            setCount(eventsFilters);
        }

    }, []);
    /*eslint-enable */


    return (
        <>
            <Search
                className="filter-search"
                prefix={ <SearchOutlined /> }
                placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Exhibition" }).props.children }
                onChange={ (e) => setSearchText(e.target.value) }
            />

            { setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }>

                    <DrawerStandart
                        width="287"
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <EventForms.Filter
                            setCount={ setCount }
                            setFilters={ setFilters }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            eventsFilters={ eventsFilters }
                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            setFilterList({});
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                            localStorage.removeItem("eventsFilters");
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );
};

export default EventTableFilter;
