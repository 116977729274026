import React from "react";
import { Link } from "react-router-dom";

import SubcontractorConst from '../subcontractor-const';


const basePath = SubcontractorConst.basePath;

const SubcontractorUsersViewField = ({ subcontractor }) => {

    return (


        <div className='view-box-field'>
            { subcontractor?.usersCount ?
                <Link
                    to={ `${ basePath }/${ subcontractor.id }/users` }
                >
                    <span className="count">{ subcontractor.usersCount } users</span>
                    <span className="btn-view">view</span>
                </Link> : <strong>&mdash;</strong> }
        </div>
    );

};

export default SubcontractorUsersViewField;
