import React, { useState } from "react";
import { Drawer } from "antd";
// import Icons from "components/icons";


const DrawerStandart = ({
    modalButton,
    extraClass = "",
    disabled = false,
    width = "287",
    ...props
}) => {

    const [ showModal, setShowModal ] = useState(false);

    return (
        <>
            { React.cloneElement(modalButton, {
                disabled,
                onClick: () => setShowModal(true)
            }) }
            <Drawer
                className={ `drawer-standard ${ extraClass }` }
                visible={ showModal }
                width={ width }
                placement="right"
                // closeIcon={ <Icons.Cross /> }
                onClose={ () => setShowModal(false) }
                header={ null }
                footer={ null }
                onCancel={ () => {
                    setShowModal(false)
                } }
            >
                { Array.isArray(props.children) ?
                    props.children.map(
                        (child, index) => React.cloneElement(child, { key: index, action: () => setShowModal(false) })
                    ) :
                    React.cloneElement(props.children, { action: () => setShowModal(false) })
                }
            </Drawer>
        </>
    );
}

export default DrawerStandart;