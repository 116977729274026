import React from 'react';
import { Form, Row, Col, Checkbox, Skeleton } from 'antd';

import { usergroupUseQuery } from '../../hooks';
import { Localize } from "components/service";


const columns = {
    span: 24,
    xs: 12,
    sm: 12,
    lg: 12,
    xl: 12,
    xxl: 12
};

const UserGroupPermissionCheckboxFormItem = ({ disabled = false }) => {

    const { permissions, loading } = usergroupUseQuery.getPermissions(true);

    return (
        <>
            { loading && <Skeleton active={ true } paragraph={ { rows: 10 } } /> }

            { !loading &&

                <Form.Item name="permission_id">
                    <Checkbox.Group >
                        <Row>

                            <Col { ...columns } >
                                <span className="label-text"><Localize>FORMS.Input_Label_CrmRights</Localize> </span>
                                <Row>
                                    { permissions.data.map(permission => {

                                        if (permission.panel === 'crm')
                                        {

                                            return (

                                                <Col className="my-1" key={ `col-permission-${ permission.id }` } span={ 24 }>

                                                    <Checkbox className='checkbox-outline' value={ parseInt(permission.id) } disabled={ disabled } >
                                                        { permission.label }
                                                    </Checkbox>

                                                </Col>

                                            );
                                        }

                                        return false;

                                    }) }
                                </Row>
                            </Col>

                            <Col { ...columns } >
                                <span className="label-text"><Localize>FORMS.Input_Label_AppRights</Localize> </span>
                                <Row>
                                    { permissions.data.map(permission => {

                                        if (permission.panel === 'app')
                                        {

                                            return (

                                                <Col className="my-1" key={ `col-permission-${ permission.id }` } span={ 24 }>

                                                    <Checkbox className='checkbox-outline' value={ parseInt(permission.id) } disabled={ disabled }>
                                                        { permission.label }
                                                    </Checkbox>

                                                </Col>

                                            );
                                        }

                                        return false;

                                    }) }
                                </Row>
                            </Col>

                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            }
        </>
    );
};

export default UserGroupPermissionCheckboxFormItem;