import SubcontractorTitleLinkField from "./subcontractor-title-link-field";
import SubcontractorUsersViewField from "./subcontractor-users-view-field";
import SubcontractorActionField    from "./subcontractor-action-field";
import SubcontractorRemoveUserField from "./subcontractor-remove-user-field";

const SubcontractorFields = {
    EditLink   : SubcontractorTitleLinkField,
    UsersView  : SubcontractorUsersViewField,
    Action     : SubcontractorActionField,
    RemoveUser : SubcontractorRemoveUserField
}

export default SubcontractorFields;