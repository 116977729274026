import React, { useState } from "react";
import { Form, Button, DatePicker, Input } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { craftUseMutation } from '../../hooks';
import CraftFormItems from '../fields';
import Stands from 'components/stands';
import Hall from 'components/hall';
import Subcontractor from 'components/subcontractor';
import { Localize } from 'components/service';
import { timeFormat } from "utils/helper-functions";


const CraftAssignCreate = ({ event, standsSelectAll, stands, hallID, variables, operation, action }) => {

    let [ count, setCount ] = useState(1);
    const [ form ] = Form.useForm();
    const valueFieldWhere = Form.useWatch('where', form);
    const placeholderCraftComplete = Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children;

    const {
        _setCraftActions,
        loadingMutationCraftActions,
    } = craftUseMutation.actions({ variables });


    function createEmptyArray(count) {
        let arr = [];
        for (let i = 0; i < count; i++)
        {
            arr.push('');
        }
        return arr;
    }


    function convertToValue(values) {
        let value = [];
        for (let i = 0; i < count; i++)
        {
            if (operation === 'additional_craft')
            {
                value.push({
                    craft_type: values[ `craft_type_${ i }` ],
                    craft_title: values[ `craft_title_${ i }` ],
                    craft_complete_at: timeFormat(values[ `craft_complete_at_${ i }` ], 'YYYY-MM-DD HH:mm'),
                    sub_id: values[ `sub_id_${ i }` ],
                });
            } else
            {
                value.push({
                    craft_type: values[ `craft_type_${ i }` ],
                    sub_id: values[ `sub_id_${ i }` ],
                });
            }
        }

        return value;
    }


    return (
        <Form
            layout="vertical"
            form={ form }
            className="model-form form-action-assign-create"
            onFinish={ (values) => {

                _setCraftActions({
                    variables: {
                        action: operation,
                        where: values.where,
                        ee_id: event?.id,
                        stand_id: valueFieldWhere === 'stands_checkboxes' && stands.length > 0 ? stands : [],
                        hall_id: valueFieldWhere === 'stands_hall' && hallID ? hallID : values.hall_id || undefined,
                        value: convertToValue(values)
                    }
                });

                action();
                form.resetFields();
            } }>

            <Stands.Forms.Fields.SelectWere standsSelectAll={ standsSelectAll } form={ form } stands={ stands } hallID={ hallID } />

            { valueFieldWhere === 'stands_hall' && !hallID &&
                <Hall.Forms.Fields.Select
                    form={ form }
                    ee_id={ event?.id } />
            }

            <ul className="add-field-list">
                { createEmptyArray(count)?.map((_, index) => (
                    <li key={ index }>

                        <span className='line'></span>

                        <CloseCircleOutlined onClick={ () => { setCount(--count); form.resetFields([ `craft_type_${ index }` ]) } } />

                        <CraftFormItems.SelectType
                            // craftTypes={ operation === 'additional_craft' ? 'craftTypesFilter' : 'craftTypes' }
                            craftTypes={ 'craftTypesFilter' }
                            name={ `craft_type_${ index }` }
                            additionalFieldName={ `craft_title_${ index }` }
                            rules={ true }
                            form={ form }
                        />

                        { operation === 'additional_craft' &&

                            <>
                                <Form.Item
                                    name={ `craft_title_${ index }` }
                                    label={ <Localize>FORMS.Input_Label_CraftTitle</Localize> }
                                >
                                    <Input name="title" />
                                </Form.Item>

                                <Form.Item
                                    name={ `craft_complete_at_${ index }` }
                                    label={ <Localize>FORMS.Input_Label_TimeOfCompletion</Localize> }
                                    rules={ [ { required: true, message: <Localize>FORMS.Input_Placeholder_CraftCompletion</Localize> } ] }
                                >
                                    <DatePicker
                                        minuteStep={ 15 }
                                        showTime format="DD.MM.YYYY HH:mm"
                                        placeholder={ placeholderCraftComplete }
                                    />
                                </Form.Item>
                            </> }

                        <Subcontractor.Forms.Fields.Select
                            name={ `sub_id_${ index }` }
                            rules={ operation === 'additional_craft' ? false : true }
                            form={ form } />
                    </li>
                )) }

            </ul>


            <span className='line'></span>

            <strong className="btn-add" onClick={ () => setCount(++count) }>
                <PlusOutlined /> <Localize>CRAFT.Button_Text_AddCraft</Localize>
            </strong>

            <span className='line'></span>

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationCraftActions }
                >
                    <Localize>GLOBAL.Button_Text_Save</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default CraftAssignCreate;





