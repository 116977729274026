import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { Input, Button } from 'antd';
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { GET_SUBCONTRACTORS } from "graphql/query/subcontractor-gql";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { Elements, PageWrapDefault, Tables } from "components/layout";
import Subcontractor from 'components/subcontractor';
import { UploadFile, Localize } from "components/service";


const { Search } = Input;
const basePath = Subcontractor.Const.basePath;
const tableHelper = Subcontractor.Helpers.TableMain;

const SubcontractorsPage = () => {

    useActiveMenuItem([ "subcontractors" ], [ "subcontractors" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Subcontractors" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");
    const [ variables, setVariables ] = useState({});

    const {
        _setImportReimport,
        loadingMutationImportReimport,
        dataMutationImportReimport,
    } = Subcontractor.Hooks.importReimport({ variables });


    return (
        <PageWrapDefault
            className="page-subcontractor"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Subcontractors</Localize> }
            staticPath={ `${ basePath }` }
        >
            <Tables.Main
                model="subcontractors"
                query={ GET_SUBCONTRACTORS }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                setVariables={ setVariables }
                objFilter={ {} }
                objOrderBy={ [ { column: "ID", order: "ASC" } ] }
                tableHelper={ tableHelper }
            >

                <div className="table-action-bar">

                    <div className="col">

                        <Search
                            className="filter-search"
                            prefix={ <SearchOutlined /> }
                            placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_Subcontractor" }).props.children }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />
                    </div>

                    <div className="col">

                        { loadingMutationImportReimport &&
                            <Elements.Importing
                                loading={ loadingMutationImportReimport }
                                modalTitle="The subcontractors file is importing…" />
                        }


                        { dataMutationImportReimport &&
                            <Elements.Imported
                                model="subcontractor"
                                data={ dataMutationImportReimport?.subcontractorImportOrReImport }
                                isImport={ true }
                                modalTitle="The subcontractors file has been imported:" />
                        }

                        <UploadFile
                            setMutation={ _setImportReimport }
                            loading={ loadingMutationImportReimport }
                            uploadBtn={
                                <Button type='primary'>
                                    <PlusOutlined /> <span><Localize>SUBCONTRACTOR.Button_Text_Import</Localize></span>
                                </Button>
                            } />

                        <Link
                            className="ant-btn ant-btn-primary"
                            type="primary"
                            to={ `${ basePath }/create` }
                        >
                            <PlusOutlined /> <span><Localize>SUBCONTRACTOR.Button_Text_Create</Localize></span>
                        </Link>
                    </div>

                </div>

            </Tables.Main>
        </PageWrapDefault>
    )
};

export default SubcontractorsPage;