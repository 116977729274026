import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import Icons from "components/icons";
import { Localize } from "components/service";


const EventDownloadReportButton = ({ eventID, setEventReportExel, setEventReportPdf, setEventPdfStadsQrCode }) => {


    const menu = (
        <Menu
            items={ [
                {
                    key: '1',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportExel({
                                variables: {
                                    ee_id: eventID,
                                    type: 'claims',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_ComplainsReport</Localize>
                        </Link>
                    ),
                    icon: <strong className='title'>EXCEL</strong>,
                },
                {
                    key: '2',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportExel({
                                variables: {
                                    ee_id: eventID,
                                    type: 'customer_claims',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_ExhibitorReport</Localize>
                        </Link>
                    )
                },
                {
                    key: '3',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportExel({
                                variables: {
                                    ee_id: eventID,
                                    type: 'last_minutes',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_LastMinReport</Localize>
                        </Link>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportExel({
                                variables: {
                                    ee_id: eventID,
                                    type: 'furnishing_orders',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_LastMinOrders</Localize>
                        </Link>
                    ),
                },
                {
                    key: '5',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportExel({
                                variables: {
                                    ee_id: eventID,
                                    type: 'damages',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_DamageReport</Localize>
                        </Link>
                    ),
                },
                // {
                //     key: '6',
                //     label: (
                //         <Link to="" onClick={ (e) => {
                //             e.preventDefault();
                //             setEventReportExel({
                //                 variables: {
                //                     ee_id: eventID,
                //                     type: 'damages_all',
                //                 }
                //             })

                //         } }>
                //             <Localize>MENUS_DROP.Label_DamageReportAll</Localize>
                //         </Link>
                //     ),
                // },
                {
                    type: 'divider',
                },
                {
                    key: '7',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportPdf({
                                variables: {
                                    ee_id: eventID,
                                    type: 'claims',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_ComplainsReport</Localize>
                        </Link>
                    ),
                    icon: <strong className='title'>PDF</strong>,
                },
                {
                    key: '8',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportPdf({
                                variables: {
                                    ee_id: eventID,
                                    type: 'customer_claims',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_ExhibitorReport</Localize>
                        </Link>
                    )
                },
                {
                    key: '9',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportPdf({
                                variables: {
                                    ee_id: eventID,
                                    type: 'last_minutes',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_LastMinReport</Localize>
                        </Link>
                    ),
                },
                {
                    key: '10',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportPdf({
                                variables: {
                                    ee_id: eventID,
                                    type: 'damages',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_DamageReport</Localize>
                        </Link>
                    ),
                },
                {
                    key: '11',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventReportPdf({
                                variables: {
                                    ee_id: eventID,
                                    type: 'damages_all',
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_DamageReportAll</Localize>
                        </Link>
                    ),
                },
                {
                    key: '12',
                    label: (
                        <Link to="" onClick={ (e) => {
                            e.preventDefault();
                            setEventPdfStadsQrCode({
                                variables: {
                                    ee_id: eventID,
                                }
                            })

                        } }>
                            <Localize>MENUS_DROP.Label_StandsQrCode</Localize>
                        </Link>
                    ),
                },
            ] }
        />
    );


    return (
        <Dropdown overlayClassName='drop-download-report' overlay={ menu } placement="bottom">
            <Button>
                <Icons.Download />
                <span><Localize>GLOBAL.Button_Text_DownloadReport</Localize></span>
                <Icons.Arrow className='arrow-down' />
            </Button>
        </Dropdown>
    );
}


export default EventDownloadReportButton;
