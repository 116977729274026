import FurnishingForms from "./forms";
import FurnishingConst from "./furnishing-const";
import FurnishingHelpers from "./helpers";
import { furnishingUseMutation } from "./hooks";



const Furnishing = {
  Hooks: furnishingUseMutation,
  Forms: FurnishingForms,
  Const: FurnishingConst,
  Helpers: FurnishingHelpers
}

export default Furnishing;