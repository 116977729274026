import { Link } from 'react-router-dom';
import EventConst from '../event-const';


const EventLinkField = ({ id, title }) => {

    return (
        <Link
            to={ {
                pathname: `${ EventConst.basePath }/${ id }`,
                state: {
                    breadcrumbSlug: {
                        id,
                        label: title
                    }
                }
            } }
        >
            { title }
        </Link>
    );
};

export default EventLinkField;

