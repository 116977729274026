import React from "react";
import { Form, Button, Input, InputNumber } from 'antd';
import { furnishingUseMutation } from '../../hooks';
import { Localize } from 'components/service';



const FurnishingEditForm = ({ furnishing, event, variables, action }) => {

    const [ form ] = Form.useForm();

    const furnishingID = +furnishing?.id;
    const eeID = +furnishing?.ee_id || event?.id;

    const {
        _setFurnishingCreateUpdate,
        loadingMutationFurnishingCreateUpdate,
    } = furnishingUseMutation.createUpdate({ furnishingID, variables });


    return (
        <Form
            key="edit-stand-form"
            layout="vertical"
            form={ form }
            className="model-form edit-stand-form"
            onFinish={ (values) => {

                _setFurnishingCreateUpdate({
                    variables: {
                        input: {
                            id: furnishingID ? furnishingID : undefined,
                            ee_id: eeID,
                            ...values,
                        }
                    }
                });

                action();
                form.resetFields();
            } }>


            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_FurnishingDescr</Localize> }
                initialValue={ furnishing?.title }
            >
                <Input name="title" />
            </Form.Item>

            <Form.Item
                name="sku"
                label={ <Localize>FORMS.Input_Label_Sku</Localize> }
                initialValue={ furnishing?.sku }

            >
                <Input name="sku" disabled={ furnishingID } />
            </Form.Item>

            <Form.Item
                name="price"
                label={ <Localize>FORMS.Input_Label_LastMinutePrice</Localize> }
                initialValue={ furnishing?.price }
            >
                <InputNumber name="price" max={ 1000000000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>

            <Form.Item
                name="qty"
                label={ <Localize>FORMS.Input_Label_LastMinuteQuantity</Localize> }
                initialValue={ furnishing?.qty }
            >
                <InputNumber name="qty" max={ 1000000000 } controls={ false } style={ { width: '100%' } } />
            </Form.Item>


            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutationFurnishingCreateUpdate }
                >
                    { furnishingID ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>FURNISHING.Button_Text_AddFurnishing</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default FurnishingEditForm;


