import { useMutation } from "@apollo/client";

import { FURNISHING_DELETE } from "graphql/mutation/furnishing-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationFurnishingDelete = ({ furnishingID }) => {

  const [ _setMutationFurnishingDelete, { loading } ] = useMutation(FURNISHING_DELETE,
    {
      update(cache, { data }) {

        const {
          furnishingDelete: {
            label,
            message
          }
        } = data;

        cache.evict({
          id: cache.identify({ id: furnishingID, __typename: "Furnishing" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },
    });

  return {
    _setMutationFurnishingDelete,
    loadingMutationFurnishingDelete: loading,
  }
};

export default useMutationFurnishingDelete;