import UserOverviewSubPage from "./user-owerview-sub-page";
import UserLogsSubPage from "./user-logs-sub-page";


const SubPage = {
    OverView: UserOverviewSubPage,
    Logs: UserLogsSubPage,
};

export default SubPage;
