import { ModalStandard, Localize } from 'components/service';
import JobForms from '../forms';
import JobHelpers from '../helpers';


const JobTypeField = ({ job }) => {

    const { getTranslationType } = JobHelpers.Functions;

    return (
        <div className='craft-link'>

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                modalButton={
                    <strong className='title'>{ getTranslationType(job?.type) }</strong>
                }>

                <strong className='modal-title'>
                    <Localize>FORMS.Title_EditJob</Localize>
                </strong>

                <JobForms.Edit job={ job } />

            </ModalStandard>
        </div>
    );
};


export default JobTypeField;
