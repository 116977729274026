import { useMutation } from "@apollo/client";

import { FURNISHING_UPDATE, FURNISHING_CREATE } from "graphql/mutation/furnishing-gql";
import { GET_FURNISHINGS } from "graphql/query/furnishing-gql";

import { successNotification, errorNotification } from "components/request-result";



const useMutationFurnishingCreateUpdate = ({ furnishingID, variables }) => {

    const FURNISHING_CREATE_UPDATE = furnishingID ? FURNISHING_UPDATE : FURNISHING_CREATE;
    const furnishingCreateUpdate = furnishingID ? 'furnishingUpdate' : 'furnishingCreate';

    const [ _setFurnishingCreateUpdate, { loading } ] = useMutation(FURNISHING_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ furnishingCreateUpdate ]: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_FURNISHINGS,
                    variables
                },
            ]
        }
    );



    return {
        _setFurnishingCreateUpdate,
        loadingMutationFurnishingCreateUpdate: loading,
    }
};

export default useMutationFurnishingCreateUpdate;