import React from "react";
import { Link } from "react-router-dom";

import UserGroupConst from '../usergroup-const';


const UserGroupTitleLinkField = ({ role }) => {

    return (
        <Link
            className="table-link-underline"
            style={ { textTransform: 'uppercase', color: role.colorHex } }
            to={ `${ UserGroupConst.basePath }/${ role.id }` }
        >
            <strong>{ role.label }</strong>
        </Link>
    );

};

export default UserGroupTitleLinkField;