import React from "react";
import { Link } from "react-router-dom";

import hallConst from '../hall-const';


const basePath = hallConst.basePath;
const parentPath = hallConst.parrentPath;

const HallStandsViewField = ({ hall }) => {

    return (


        <div className='view-box-field'>
            { hall?.stands_count ?
                <Link
                    to={ `${ parentPath }/${ hall?.ee_id }${ basePath }/${ hall.id }/stands` }
                >
                    <span className="count">{ hall.stands_count }</span>
                    <span className="btn-view">view</span>
                </Link> : <strong>&mdash;</strong> }
        </div>
    );

};

export default HallStandsViewField;
