import React, { useState } from "react";
import { Button, Input } from "antd";
import { PlusOutlined, SearchOutlined, SnippetsOutlined } from "@ant-design/icons";
import { useRouteMatch } from "react-router-dom";

import { GET_FURNISHINGS } from "graphql/query/furnishing-gql";
import { EVENT_COPY_FURNICHING } from "graphql/mutation/event-gql";

import Furnishing from "components/furnishing";
import Events from "components/events";
import { Tables, Elements } from "components/layout";
import { Localize, ModalStandard, UploadFile } from "components/service";
import { useBreadCrumbs, useMe } from "components/use-hooks";
import Icons from "components/icons";

const { Search } = Input;



const parrentPath = Events.Const.basePath;;
const basePath = Furnishing.Const.basePath;
const tableHelper = Furnishing.Helpers.TableMain;

const EventFurnishingsSubPage = ({ event }) => {

    const { me } = useMe();

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ parrentPath }`
        },
        {
            label: event?.title,
            path: `${ parrentPath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Furnishings" }).props.children,
            path: `${ parrentPath }/${ event?.id }${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const [ searchText, setSearchText ] = useState("");
    const [ variables, setVariables ] = useState({});

    let match = useRouteMatch(`${ parrentPath }/${ event?.id }${ basePath }/page/:pageNum`);

    const eventFurnishings = { column: 'EE_ID', operator: "EQ", value: parseInt(event?.id) }


    const {
        _setFurnishingImportReimport,
        loadingMutationFurnishingImportReimport,

    } = Furnishing.Hooks.importReimport({ variables });


    return (

        <Tables.Main
            model="furnishings"
            query={ GET_FURNISHINGS }
            varSet={ { perPage: 30 } }
            currentObj={ me }
            routeUrl={ `${ parrentPath }/${ event.id }${ basePath }` }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            setVariables={ setVariables }
            objFilter={ eventFurnishings }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">
                <div className="col">
                    <Search
                        className="filter-search"
                        prefix={ <SearchOutlined /> }
                        onChange={ (e) => setSearchText(e.target.value) }
                    />
                </div>

                <div className="col">

                    { loadingMutationFurnishingImportReimport &&
                        <Elements.Importing
                            loading={ loadingMutationFurnishingImportReimport }
                            modalTitle={ <Localize>ENTITY.Modal_Title_Importing</Localize> } /> }

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <SnippetsOutlined /> <Localize>MODAL.Button_Text_CopyFromEvent</Localize>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_CopyFromEvent</Localize></strong>

                        <Furnishing.Forms.CopyEvent
                            propsObj={ {
                                query: GET_FURNISHINGS,
                                queryVar: {
                                    first: 10000,
                                    where: eventFurnishings
                                },
                                mutation: EVENT_COPY_FURNICHING,
                                mutationName: "eeCopyFurnishing",
                                model: "ee_id",
                                modelID: event?.id,
                            }
                            } />
                    </ModalStandard>

                    <UploadFile
                        varSetMutation={ { ee_id: event?.id } }
                        setMutation={ _setFurnishingImportReimport }
                        loading={ loadingMutationFurnishingImportReimport }
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        uploadBtn={
                            <Button type="primary"><Icons.Import /> <Localize>FURNISHING.Button_Text_FurnishingImport</Localize></Button>
                        } />

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button type="primary">
                                <PlusOutlined /> <Localize>FURNISHING.Button_Text_AddFurnishing</Localize>
                            </Button>
                        }>

                        <strong className='modal-title'><Localize>FORMS.Title_AddFurnishing</Localize></strong>
                        <Furnishing.Forms.Edit event={ event } variables={ variables } />

                    </ModalStandard>
                </div>
            </div>
        </Tables.Main>
    );

}


export default EventFurnishingsSubPage;