import { useQuery } from "@apollo/client";

import { GET_PERMISSIONS } from "graphql/query/permission-gql";


const useQueryPermissions = ( withStatus = false ) => {

    const { data : { permissions = {} } = {}, loading } = useQuery( GET_PERMISSIONS, {
        variables: {
            first: 9999,
        },
        fetchPolicy: "network-only"
    } );

    if( withStatus ) return { permissions, loading };

    if( permissions ) return { permissions } ;

    return false;
}

export default useQueryPermissions;