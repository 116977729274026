import { gql } from '@apollo/client';

export const HALL_CREATE = gql`
    mutation HallCreate(
        $input: HallFieldsInput!
    ) {
        hallCreate(
            input: $input
        ){
            label
            message
            hall{
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                finished
                exhibitionEvent {
                    id
                    title
                    status
                    path_model
                }
            }
        }
    }
`;


export const HALL_UPDATE = gql`
    mutation HallUpdate(
        $input: HallFieldsInput!
    ) {
        hallUpdate(
            input: $input
        ){
            label
            message
            hall{
                id
                title
                ee_id
                number
                stands_count
                hall_plan
                finished
                exhibitionEvent {
                    id
                    title
                    status
                    path_model
                }
            }
        }
    }
`;


export const HALL_UPLOAD_FILE = gql`
    mutation FileUpload($input: FileUpload) {
        fileUpload(input: $input) {
        label
        message
        }
    }
`;



export const HALL_DELETE = gql`
mutation HallDelete($id: ID!, $assign_hall_id: ID) {
    hallDelete(id: $id, assign_hall_id: $assign_hall_id) {
      label
      message
    }
  }
`;
