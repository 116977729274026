import StandFields from "../fields";
import { Localize } from "components/service";


const standLogsColumns = [
    {
        title: <Localize>TABLES.Column_Title_Time</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_User</Localize>,
        dataIndex: 'user_full_name',
        columnIndex: 'USER',
        // sorter: true
    },

    {
        title: <Localize>TABLES.Column_Title_Message</Localize>,
        dataIndex: 'message',
        columnIndex: 'MESSAGE',
        // width: '30%'

    }
];



const StandLogsData = (logs) => {

    if (!logs)
    {
        return [];
    }

    return logs.map(log => {

        return {
            key: log.id,
            created_at: log.created_at,
            user_full_name: <StandFields.UserInfo userInfo={ { id: log.user_id, userFullName: log.user_full_name } } />,
            message: <StandFields.Message log={ log } />,
        };
    });
};


const StandLogsTableHelper = {
    columns: standLogsColumns,
    data: StandLogsData,
}

export default StandLogsTableHelper;