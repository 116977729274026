import Events from "components/events";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";

const basePath = Events.Const.basePath;


const EventOverviewSubPage = ({ event, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: (event && ` ${ event?.title }`) || Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path: (event && `${ basePath }/${ event?.id }`) || `${ basePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <div className="row-grid col-lg-2">
            <div className="col-left">
                <Events.Forms.Edit event={ event } history={ history } />
            </div>
            <div className="col-right"></div>
        </div>
    )
}



export default EventOverviewSubPage;