import React from "react";
import { timeFormat } from "utils/helper-functions";


const JobDateField = ({ job, date, user }) => {

    return (
        <>
            { job?.[ user ] ?
                <div style={ { fontSize: '12px' } }>
                    <span> by { job?.[ user ]?.name } { job?.[ user ]?.surname }</span> <br />
                    <span>on { timeFormat(job?.[ date ]) } </span>
                </div> : <strong>&mdash;</strong> }
        </>
    );
};

export default JobDateField;