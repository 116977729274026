import EventTableHelper from './event-table-helper';
import EventUsersTableHelper from './event-users-table-helper';
import EventAttachmentsTableHelper from './event-attachments-table-helper';


const EventHelpers = {
    TableMain: EventTableHelper,
    TableUsers: EventUsersTableHelper,
    TableAttachments: EventAttachmentsTableHelper,
}

export default EventHelpers;