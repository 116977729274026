import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";


export default function SubcontractorRoute( { subcontractor } ) {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/users` } exact>
                <Pages.Subcontractor.SubPage.Users subcontractor={ subcontractor } />
            </Route>
            <Route path={ `${ path }/users/page/:pageNum` } exact>
                <Pages.Subcontractor.SubPage.Users subcontractor={ subcontractor } />
            </Route>
            <Route path={ `${ path }` }>
                <Pages.Subcontractor.SubPage.OverView subcontractor={ subcontractor } />
            </Route>
        </Switch>
    );

}