import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { userUseMutation } from '../hooks';
import UserAction from "../actions";
import UserConst from '../users-const';
import { EntityRemoveButton, ModalStandard, Localize } from 'components/service';
import Icons from 'components/icons';



const UserActionField = ({ userId, userName }) => {

    const history = useHistory();

    const {
        _setMutationUserDelete,
        loadingMutationUserDelete,
    } = userUseMutation.delete(userId);


    const menu = (
        <Menu
            items={ [
                {
                    label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                    key: '1',
                    icon: <Icons.Edit />,
                    onClick: () => history.push(`${ UserConst.basePath }/${ userId }`)
                },
                {
                    label: <Localize>MENUS_DROP.Label_ViewLogs</Localize>,
                    key: '2',
                    icon: <Icons.Logs />,
                    onClick: () => history.push(`${ UserConst.basePath }/${ userId }/logs`)
                },
                {
                    key: '3',
                    icon:
                        <ModalStandard
                            width={ 380 }
                            modalButton={ <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.SendPass /> <Localize>MENUS_DROP.Label_SendPass</Localize></span> }>
                            <UserAction.Fields.SendPass userID={ userId } />
                        </ModalStandard>
                },
                {
                    key: '4',
                    icon:
                        <EntityRemoveButton
                            modalButton={
                                <span style={ { display: 'flex', alignItems: 'center' } } ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                            }
                            nameEntity={ Localize({ children: "ENTITY.Modal_Title_User" }).props.children }
                            dataNameEntity={ userName }
                            loading={ loadingMutationUserDelete }
                            deleteMutation={ _setMutationUserDelete }
                            variables={ {
                                id: userId,
                            } }
                        />,
                },
            ] }
        />
    );

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            overlay={ menu }
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    )
};

export default UserActionField;

