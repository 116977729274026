import React from "react";
import { Link } from "react-router-dom";

import HallConst from '../hall-const';


const HallTitleLinkField = ({ hall }) => {

    return (
        <Link
            to={ {
                pathname: `${ HallConst.parrentPath }/${ hall.ee_id }${ HallConst.basePath }/${ hall.id }`,
                state: {
                    breadcrumbSlug: {
                        id: hall.id,
                        label: hall.title
                    }
                }
            } }
        >
            <strong>{ hall.title }</strong>
        </Link>
    );

};

export default HallTitleLinkField;
