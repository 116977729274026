import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { standUseMutation } from '../../hooks';
import Icons from 'components/icons'
import { UploadFile, Localize } from 'components/service';


const StandFileList = ({ stand }) => {

    const fileListItem = [
        {
            field: 'file_stand_plan',
            label: <Localize>ACTIONS.List_Item_Label_StandPlan</Localize>
        },
        {
            field: 'file_graphic',
            label: <Localize>ACTIONS.List_Item_Label_Graphic</Localize>
        },
        {
            field: 'file_info',
            label: 'information',
        },
        {
            field: 'file_photo',
            label: <Localize>ACTIONS.List_Item_Label_Photo</Localize>
        } ]
        .map(({ field, label }) => {

            const {
                _setStandUploadFile,
                loadingMutationStandUploadFile,
            } = standUseMutation.UploadFile({ eeID: stand?.ee_id, standID: stand?.id });

            const fileName = stand?.[ field ]?.split('/')?.pop()?.split('~')?.pop();

            return (

                <li key={ field }>

                    <span className='label-text'>{ label }</span>

                    { stand?.[ field ] ?

                        <div className='download-file'>
                            <Tooltip overlayClassName="craft-tooltip" destroyTooltipOnHide={ true } title={ fileName }>
                                <Link
                                    to='#'
                                    onClick={ () => {
                                        window.open(
                                            stand?.[ field ],
                                            '_blank'
                                        );
                                    } }
                                >
                                    <Icons.Download /> <span className='link-text underline-none'><Localize>GLOBAL.Link_Text_Download</Localize></span>
                                </Link>
                            </Tooltip>
                            <span onClick={ () => {
                                _setStandUploadFile({
                                    variables: {
                                        input: {
                                            model: 'Stand',
                                            model_id: stand?.id,
                                            model_field: field,
                                            file: null
                                        }
                                    }
                                })
                            } }>

                                { loadingMutationStandUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }

                            </span>

                        </div>
                        :
                        <UploadFile
                            setMutation={ _setStandUploadFile }
                            loading={ loadingMutationStandUploadFile }
                            accept="*/*"
                            varSetMutation={ {
                                input: {
                                    model: 'Stand',
                                    model_id: stand?.id,
                                    model_field: field,
                                }
                            } }
                            uploadBtn={
                                <Button loading={ loadingMutationStandUploadFile } type='primary' size='small'><Localize>GLOBAL.Button_Text_UploadFile</Localize></Button>
                            } />
                    }

                </li>
            )
        });


    return (
        <ul className='file-list'>

            { fileListItem }

        </ul>
    );
}


export default StandFileList;