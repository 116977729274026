import useMutationJobCreateUpdate from './job-use-mutation-create-update';
import useMutationJobDelete from "./job-use-mutation-delete";
import useMutationJobUploadFile from "./job-use-mutation-upload-file";



export const jobUseMutation = {

  createUpdate: useMutationJobCreateUpdate,
  delete: useMutationJobDelete,
  uploadFile: useMutationJobUploadFile,
};