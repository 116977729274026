import { useMutation } from "@apollo/client";

import { CRAFT_UPDATE, CRAFT_CREATE } from "graphql/mutation/craft-gql";
import { GET_CRAFTS } from "graphql/query/craft-gql";

import { successNotification, errorNotification } from "components/request-result";



const useMutationCraftCreateUpdate = ({ craftID, variables }) => {

    const CRAFT_CREATE_UPDATE = craftID ? CRAFT_UPDATE : CRAFT_CREATE;
    const craftCreateUpdate = craftID ? 'craftUpdate' : 'craftCreate';

    const [ _setCraftCreateUpdate, { loading } ] = useMutation(CRAFT_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ craftCreateUpdate ]: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_CRAFTS,
                    variables
                },
            ]
        }
    );



    return {
        _setCraftCreateUpdate,
        loadingMutationCraftCreateUpdate: loading,
    }
};

export default useMutationCraftCreateUpdate;

