import HallTitleLinkField from "./hall-title-link-field";
import HallPlanField from "./hall-plan-field";
import HallActionField from "./hall-action-field";
import HallStandsViewField from "./hall-stands-view-field";

const HallFields = {
    EditLink: HallTitleLinkField,
    Plan: HallPlanField,
    Action: HallActionField,
    StandsView: HallStandsViewField
}

export default HallFields;