import React from 'react';
import { useQuery } from "@apollo/client";
import { Form, Select } from "antd";
import { GET_CRAFTS } from "graphql/query/craft-gql";
import { Localize } from "components/service";
import Icons from "components/icons";
import Craft from 'components/craft';
import { helperFunc } from 'utils'


const CraftSelectIdFormItem = ({
    job,
    standID = undefined,
    form,
    disabled = false,
    rules = true,
    onChangeSetFilter = () => { },
    initialValue,
    setCraftType = () => { },
    filterElement = [],
    mode = '',
    name = 'craft_id',
    label = Localize({ children: "FORMS.Input_Label_Craft" }).props.children,
    selectBy = 'id',
}) => {

    const { charSplitToUpper } = helperFunc;
    const { getTranslationCraft } = Craft.Helpers.Functions;

    const { data, loading } = useQuery(GET_CRAFTS, {
        skip: disabled,
        variables: {
            first: 10000,
            stand_id: standID,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
    });


    return (
        <Form.Item
            name={ name }
            label={ label }
            rules={ rules && !job?.id && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }>
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                defaultValue={ initialValue }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value });
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                dropdownClassName="select-serch-dropdown"
                optionFilterProp="key"
            >
                { !loading && data?.crafts?.data.filter(({ type }) => !filterElement?.includes(type)).map((craft) => (

                    <Select.Option disabled={ craft?.status === 'disabled' } key={ craft?.type } value={ craft[ selectBy ] }>

                        <span onClick={ () => setCraftType(craft?.type) }>
                            { charSplitToUpper(craft?.title) }  ( { getTranslationCraft(charSplitToUpper(craft?.type, '_', false)) } )
                        </span>

                    </Select.Option>
                )) }
            </Select>
        </Form.Item>
    );

}

export default CraftSelectIdFormItem;