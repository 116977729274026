import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserPage } from "./users";
import { EventsPage, EventPage } from "./events";
import { StandPage } from "./stands";
import { LastMinutePage } from "./last-minute";
import UserGroupPages from "./usergroups";
import SubcontractorPages from "./subcontractors";
import HallPages from "./halls";
import Dashboard from "./dashboard/dashboard-page";
import Page404 from "./404/404-page";


import "./pages.scss";



export const Pages = {
    Dashboard,
    Users: UsersPage,
    User: UserPage,
    Events: EventsPage,
    Event: EventPage,
    Stand: StandPage,
    UserGroup: UserGroupPages,
    Subcontractor: SubcontractorPages,
    Hall: HallPages,
    LastMinute: LastMinutePage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}