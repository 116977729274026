import React from "react";
import { Form, Input, Button, Switch, DatePicker } from 'antd';
import { eventUseMutation } from '../../hooks';
import EntityRemoveButton from 'components/service/entity-remove-button';
import { Localize } from "components/service";
import moment from 'moment';


const EventEditForm = ({ event }) => {

    const [ form ] = Form.useForm();

    const {
        _setEventCreateUpdate,
        loadingMutationEventCreateUpdate: loading,
    } = eventUseMutation.createUpdate(event?.id);

    const {
        _setMutationEventDelete,
        loadingMutationEventDelete,
    } = eventUseMutation.delete(event?.id);


    const fieldValueStartAt = Form.useWatch('start_at', form);
    const fieldValueConstructionStartAt = Form.useWatch('constructionStart_at', form);

    const disabledDate = (current, name) => {
        const fieldValue = name === 'start_at' ? fieldValueStartAt : fieldValueConstructionStartAt;
        return current && current < (moment(fieldValue))
    };


    return (
        <Form
            key="edit-event-form"
            layout="vertical"
            form={ form }
            className="model-form edit-event-form"
            onFinish={ (values) => {

                _setEventCreateUpdate({
                    variables: {
                        input: {
                            id: event?.id ? +event.id : undefined,
                            ...values
                        }
                    }
                })
            } }>

            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_ExhibitionName</Localize> }
                initialValue={ event?.title }
                rules={ [
                    { required: true, message: <Localize>FORMS.Input_Placeholder_ExhibitionName</Localize> }
                ] }
            >
                <Input name="title" />
            </Form.Item>

            <Form.Item
                name="status"
                label={ <Localize>FORMS.Input_Label_ElfeOnline</Localize> }
                initialValue={ event?.status }
                valuePropName="checked"
                className='form-item-switch'
            >
                <Switch />
            </Form.Item>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name="start_at"
                    label={ <Localize>FORMS.Input_Label_ExhibitionStarts</Localize> }
                    initialValue={ event?.start_at && moment(event?.start_at) }
                >
                    <DatePicker
                        onChange={ value => {
                            form.setFieldsValue({ end_at: moment(value).add(1, 'days') });
                        } }
                        minuteStep={ 15 }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY HH:mm"
                    />
                </Form.Item>

                <Form.Item
                    name="end_at"
                    label={ <Localize>FORMS.Input_Label_ExhibitionEnds</Localize> }
                    initialValue={ event?.end_at && moment(event?.end_at) }
                >
                    <DatePicker
                        minuteStep={ 15 }
                        disabledDate={ (current) => disabledDate(current, 'start_at') }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY HH:mm"
                    />

                </Form.Item>

                <Form.Item
                    name="constructionStart_at"
                    label={ <Localize>FORMS.Input_Label_ConstructionStarts</Localize> }
                    initialValue={ event?.constructionStart_at && moment(event?.constructionStart_at) }
                >
                    <DatePicker
                        onChange={ value => {
                            form.setFieldsValue({ constructionEnd_at: moment(value).add(1, 'days') })
                        } }
                        minuteStep={ 15 }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY HH:mm"
                    />

                </Form.Item>

                <Form.Item
                    name="constructionEnd_at"
                    label={ <Localize>FORMS.Input_Label_ConstructionEnds</Localize> }
                    initialValue={ event?.constructionEnd_at && moment(event?.constructionEnd_at) }
                >
                    <DatePicker
                        minuteStep={ 15 }
                        disabledDate={ (current) => disabledDate(current, 'constructionStart_at') }
                        placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                        showTime format="DD.MM.YYYY HH:mm"
                    />
                </Form.Item>

            </div>

            <Form.Item
                name="path_model"
                label={ <Localize>FORMS.Input_Label_ExhibitionLink</Localize> }
                initialValue={ event?.path_model }
                rules={ [
                    { required: true, message: 'Please input the event link' },
                    // {
                    //     validator: (rule, value) => {
                    //         if (value && !helperFunc.linkRegex.test(value))
                    //         {
                    //             return Promise.reject('Invalid link');
                    //         }
                    //         return Promise.resolve();
                    //     }
                    // },
                ] }>
                <Input />
            </Form.Item>

            <div className="form-btn-holder">
                { event?.id &&
                    <EntityRemoveButton
                        modalButton={
                            <Button className="ant-btn-secondary">
                                <Localize>EXHIBITION.Button_Text_Delete</Localize>
                            </Button>
                        }
                        nameEntity={ Localize({ children: "ENTITY.Modal_Title_ExhibitionEvent" }).props.children }
                        dataNameEntity={ `${ event?.title }` }
                        loading={ loadingMutationEventDelete }
                        deleteMutation={ _setMutationEventDelete }
                        variables={ {
                            id: +event?.id,
                        } }
                    /> }

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { event?.id ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>EXHIBITION.Button_Text_Create</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default EventEditForm;


