import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import UserForms from '../../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;



const UserTableFilter = ({
    setFilters = () => { },
    setResetSorting = () => { },
    usersFilters,
    usersFiltersEeID,
    filters,
    setSearchText,
    objOrderBy,
    setEventID,
    eventID }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState(null);
    const [ count, setCount ] = useState();

    let filterCount = helperFunc.countFilterList(count);
    filterCount = eventID ? filterCount + 1 : filterCount;

    /*eslint-disable */
    useEffect(() => {

        if (filters)
        {
            localStorage.setItem("usersFilters", JSON.stringify({ ...usersFilters, ...filterList }));
        } else form.resetFields([ 'COMPANY', 'ROLE_ID' ]);

        if (eventID)
        {
            localStorage.setItem("usersFiltersEeID", JSON.stringify(eventID));
        } else form.resetFields([ "ee_id" ]);

    }, [ filters, eventID ]);


    useEffect(() => {

        if (usersFilters)
        {
            setFilters(gqlBuilderWhere(usersFilters));
            setCount(usersFilters);
        }

        if (usersFiltersEeID)
        {
            setEventID(usersFiltersEeID);
        }

    }, []);
    /*eslint-enable */


    return (
        <>
            <Search
                className="filter-search"
                prefix={ <SearchOutlined /> }
                placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_User" }).props.children }
                onChange={ (e) => setSearchText(e.target.value) }
            />

            { setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }>

                    <DrawerStandart
                        width="287"
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <UserForms.Filter
                            setEventID={ setEventID }
                            setCount={ setCount }
                            setFilters={ setFilters }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            usersFilters={ usersFilters }
                            usersFiltersEeID={ usersFiltersEeID }
                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            setFilterList({});
                            setEventID(undefined);
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                            setResetSorting(objOrderBy);
                            localStorage.removeItem("usersFilters");
                            localStorage.removeItem("usersFiltersEeID");
                            localStorage.removeItem("usersSorting");
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );
};

export default UserTableFilter;
