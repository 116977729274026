import useMutationCraftCreateUpdate from './craft-use-mutation-create-update';
import useMutationCraftDelete from "./craft-use-mutation-delete";
import useMutationCraftActions from "./craft-use-mutation-actions";



export const craftUseMutation = {
  createUpdate: useMutationCraftCreateUpdate,
  delete: useMutationCraftDelete,
  actions: useMutationCraftActions,
};