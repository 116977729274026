import React from 'react';
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";


const StandSelectWhereFormItem = ({
    form,
    stands,
    hallID,
    disabled = false,
    rules = true,
    standsSelectAll,
    mode = '',
    name = 'where',
    label = Localize({ children: "FORMS.Input_Label_Where" }).props.children
}) => {


    const initialValue = () => {

        if (stands.length > 0 && !standsSelectAll) return 'stands_checkboxes'

        if (standsSelectAll) return 'stands_event'

        return null;
    };


    return (
        <Form.Item
            initialValue={ initialValue() }
            name={ name }
            label={ label }
            rules={ !initialValue() && rules && [ { required: true, message: <Localize>FORM_RULES.Required_Where</Localize> } ] }
        >
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => form.setFieldsValue({ [ name ]: value }) }
                placeholder={ <Localize>FORM_RULES.Required_Where</Localize> }
                dropdownClassName="select-serch-dropdown craft-select"
                optionFilterProp="value"
            >
                <Select.Option disabled={ !initialValue() || standsSelectAll } key='1' value='stands_checkboxes' >
                    <span><Localize length={ (stands.length && standsSelectAll) ? '0' : stands.length }>FORMS.Select_OptionLabel_SelectedStands</Localize></span>
                </Select.Option>

                <Select.Option key='2' value='stands_hall'>
                    <span><Localize length={ stands.length }>FORMS.Select_OptionLabel_AllStandsHall</Localize></span>
                </Select.Option>

                { !hallID &&
                    <Select.Option key='3' value='stands_event'>
                        <span><Localize length={ stands.length }>FORMS.Select_OptionLabel_AllStandsExhibition</Localize></span>
                    </Select.Option>
                }

            </Select>
        </Form.Item>
    );

}

export default StandSelectWhereFormItem;


