import StandOverviewSubPage from "./stand-overview-sub-page";
import StandKeysSubPage from "./stand-keys-sub-page";
import StandCraftsSubPage from "./stand-crafts-sub-page";
import StandJobsSubPage from "./stand-jobs-sub-page";
import StandLogsSubPage from "./stand-logs-sub-page";


const SubPage = {
    OverView: StandOverviewSubPage,
    Keys: StandKeysSubPage,
    Crafts: StandCraftsSubPage,
    Jobs: StandJobsSubPage,
    Logs: StandLogsSubPage,
};

export default SubPage;
