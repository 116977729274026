import EventLinkField from "./event-link-field";
import EventViewField from "./event-view-field";
import EventActionField from './event-action-field';
import EventRadioField from './event-radio-field';
import EventRemoveUserField from './event-remove-user-field';
import EventAttachmentField from './event-attachment-field';
import EventAttachmentAction from './event-attachment-action-field';



const EventFields = {
    Link : EventLinkField,
    View : EventViewField,
    Action:EventActionField,
    Radio: EventRadioField,
    RemoveUser: EventRemoveUserField,
    Attachment: EventAttachmentField,
    AttachmentAction: EventAttachmentAction,
}

export default EventFields;