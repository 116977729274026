import EventFields from "../fields";
import User from 'components/users';
import { Localize } from "components/service";


const usersColumns = [
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: 'name',
        columnIndex: 'NAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Phone</Localize>,
        dataIndex: 'phone',
        columnIndex: 'PHONE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Email</Localize>,
        dataIndex: 'email',
        columnIndex: 'EMAIL',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Company</Localize>,
        dataIndex: 'company',
        columnIndex: 'COMPANY',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_UserGroup</Localize>,
        dataIndex: 'role',
        columnIndex: 'ROLE_SLUG',
        sorter: true
    },
    {
        // title: 'action',
        dataIndex: 'action',
        align: 'right',
        width: '4%'
    }
];


const usersData = (users, event) => {

    if (!users)
    {
        return [];
    }

    return users.map(user => {

        return {
            key: user.id,
            name: <User.Fields.Info user={ { id: user.id, name: user.name, surname: user.surname } } />,
            phone: user.phone ?? <strong>&mdash;</strong>,
            email: user.email ? <a href={ `mailto:${ user.email }` }>{ user.email }</a> : <strong>&mdash;</strong>,
            company: <User.Fields.Company subcontractor={ user.subcontractor } company={ user.company } />,
            role: <User.Fields.Role role={ user.role } />,
            action: <EventFields.RemoveUser user={ user } event={ event } />
        };
    });
};


const EventUsersTableHelper = {
    columns: usersColumns,
    data: usersData,
}

export default EventUsersTableHelper;