import React from "react";
import { Link } from "react-router-dom";


const UserRoleField = ({ role }) => {

    return (
        <Link
            className="table-link-underline"
            style={ { textTransform: 'uppercase', color: role?.colorHex } }
            to={ `/user-groups/${ role?.id }` }>
            <strong>{ role?.label }</strong>
        </Link>
    );
};

export default UserRoleField;


