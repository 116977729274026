import React, { useEffect, useState } from "react";
import { Form, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import JobForms from '../../forms';
import Events from "components/events";
import Stands from "components/stands";
import Hall from 'components/hall';
import { ModalStandard, Localize } from "components/service";


const JobLastMinuteForm = ({ setEventID, setStandID, eventID, standID, variables }) => {

    const [ btnConfirm, setBtnConfirm ] = useState({
        ee_id: false,
        stand_id: false,
    });

    const [ form ] = Form.useForm();

    const valueFieldEventID = Form.useWatch('ee_id', form);
    const valueFieldHallID = Form.useWatch('hall_id', form);
    const valueFieldStandID = Form.useWatch('stand_id', form);

    /*eslint-disable */
    useEffect(() => {
        setEventID(valueFieldEventID);
        setStandID(valueFieldStandID);
    }, [ btnConfirm ]);
    /*eslint-enable */

    if (valueFieldEventID !== eventID)
    {
        form.resetFields([ 'stand_id', 'hall_id' ]);
    }


    return (
        <Form
            key="edit-model-form"
            layout="vertical"
            form={ form }
            className="model-form edit-model-form last-minute-form"
        >
            <div className="holder-form">

                <Events.Forms.Fields.RadioSelect
                    name="ee_id"
                    label={ <Localize>FORMS.Input_Label_Exhibition</Localize> }
                    form={ form }
                    valueFieldEventID={ valueFieldEventID }
                    setBtnConfirm={ setBtnConfirm }
                />

                { eventID &&

                    <Stands.Forms.Fields.RadioSelect
                        name="stand_id"
                        label={ <Localize>FORMS.Input_Label_Stand</Localize> }
                        form={ form }
                        valueFieldStandID={ valueFieldStandID }
                        valueFieldHallID={ valueFieldHallID }
                        valueFieldEventID={ valueFieldEventID }
                        setBtnConfirm={ setBtnConfirm }
                        extraFields={
                            <Hall.Forms.Fields.Select
                                label={ false }
                                rules={ false }
                                form={ form }
                                ee_id={ valueFieldEventID } />
                        }
                    />
                }

                { eventID && standID &&

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button type="primary">
                                <PlusOutlined /> <Localize>MODAL.Button_Text_JobCreate</Localize>
                            </Button>
                        }>

                        <strong className='modal-title'>
                            <Localize>FORMS.Title_CreateLastMinJob</Localize>
                        </strong>

                        <JobForms.Edit
                            craftsHide={ [ 'mp_control', 'protocol' ] }
                            fieldsHide={ [ 'status', 'type' ] }
                            standID={ valueFieldStandID }
                            variables={ variables } />

                    </ModalStandard>
                }

            </div>

        </Form>
    );
};

export default JobLastMinuteForm;


