import React from 'react';
import { Form, Select } from "antd";
import JobConst from '../../job-const';
import Icons from "components/icons";
import { Localize } from "components/service";


const JobSelectStatusFormItem = ({
    form,
    disabled = false,
    rules = false,
    onChangeSetFilter = () => { },
    initialValue,
    mode = '',
    name = 'status',
    label = Localize({ children: "FORMS.Input_Label_JobStatus" }).props.children,
}) => {


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }>
            <Select
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value })
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                dropdownClassName="select-serch-dropdown job-select"
                optionFilterProp="value"
            >

                { JobConst.jobStatus.map((type) => (
                    <Select.Option key={ type.value } value={ type.value }>
                        <span>{ type.label }</span>
                    </Select.Option>
                ))
                }
            </Select>
        </Form.Item>
    );

}

export default JobSelectStatusFormItem;


