import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";


export default function UserGroupsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.UserGroup.Archive />
            </Route>
            <Route path={ `${ path }/page/:pageNum` } exact>
                <Pages.UserGroup.Archive />
            </Route>
            <Route path={ `${ path }/page` } exact>
                <Pages.UserGroup.Archive />
            </Route>
            <Route path={ `${ path }/create` } exact>
                <Pages.UserGroup.Create />
            </Route>
            <Route path={ `${ path }/:id` } >
                { ({ match }) => <Pages.UserGroup.Single match={ match } exact /> }
            </Route>
        </Switch>
    );
}
