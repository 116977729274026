import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import Icons from 'components/icons'
import { hallUseMutation } from '../../hooks';
import { UploadFile, Localize } from 'components/service';


const HallFileList = ({ hall }) => {

    const {
        _setHallUploadFile,
        loadingMutationHallUploadFile,
    } = hallUseMutation.uploadFile({ id: hall?.id });


    return (
        <ul className='file-list'>

            <li key='1'>
                <span className='label-text'><Localize>FORMS.Input_Label_HallPlan</Localize></span>

                {
                    hall.hall_plan ?

                        <div className='download-file'>
                            <Link
                                to='#'
                                onClick={ () => {
                                    window.open(
                                        hall.hall_plan,
                                        '_blank'
                                    );
                                } }
                                className='underline-none'
                            >
                                <Icons.Download /> <span className='link-text'><Localize>GLOBAL.Link_Text_Download</Localize></span>
                            </Link>
                            <span onClick={ () => {
                                _setHallUploadFile({
                                    variables: {
                                        input: {
                                            model: 'Hall',
                                            model_id: hall.id,
                                            model_field: 'hall_plan',
                                            file: null
                                        }
                                    }
                                })
                            } }>
                                { loadingMutationHallUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }
                            </span>

                        </div>
                        :
                        <UploadFile
                            setMutation={ _setHallUploadFile }
                            loading={ loadingMutationHallUploadFile }
                            accept="*/*"
                            varSetMutation={ {
                                input: {
                                    model: 'Hall',
                                    model_id: hall.id,
                                    model_field: 'hall_plan',
                                }
                            } }
                            uploadBtn={
                                <Button loading={ loadingMutationHallUploadFile } type='primary' size='small'><Localize>GLOBAL.Button_Text_UploadFile</Localize></Button>
                            } />
                }

            </li>
        </ul>
    );
}

export default HallFileList;