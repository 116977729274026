import JobFields from "../fields";
import { Localize } from "components/service";


let jobsColumns = [
    {
        title: <Localize>TABLES.Column_Title_JobTableNumber</Localize>,
        dataIndex: 'number',
        columnIndex: 'NUMBER',
        width: '6%',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Craft</Localize>,
        dataIndex: 'craft',
        columnIndex: 'CRAFT_ID',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_JobType</Localize>,
        dataIndex: 'type',
        columnIndex: 'TYPE',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: 'status',
        columnIndex: 'STATUS',
        sorter: true,
    },

    {
        title: <Localize>TABLES.Column_Title_Description</Localize>,
        dataIndex: 'description',
        columnIndex: 'DESCRIPTION',
        // sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Created</Localize>,
        dataIndex: 'created_at',
        columnIndex: 'CREATED_AT',
        sorter: true,
    },
    // {
    //     title: <Localize>TABLES.Column_Title_InProgress</Localize>,
    //     dataIndex: 'taken_at',
    //     columnIndex: 'TAKEN_AT',
    //     sorter: true,
    // },
    {
        title: <Localize>TABLES.Column_Title_Completed</Localize>,
        dataIndex: 'completed_at',
        columnIndex: 'COMPLETED_AT',
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Attachments</Localize>,
        dataIndex: 'attachment',
        columnIndex: 'ATTACHMENTS',
        // sorter: true,
    },
    {
        // title: "action",
        dataIndex: 'action',
        columnIndex: 'ACTION',
        align: 'right',
        width: '4%',
    }
];


const jobsData = (craftJobs, variables) => {


    if (!craftJobs)
    {
        return [];
    }

    return craftJobs?.map(job => {

        return {
            key: job.id,
            number: job.number,
            craft: <JobFields.Craft job={ job } />,
            type: <JobFields.Type job={ job } />,
            status: <JobFields.Status job={ job } />,
            description: job.description ?? <span>&mdash;</span>,
            created_at: <JobFields.Date job={ job } user="creator" date="created_at" />,
            // taken_at: <JobFields.Date job={ job } user="taken" date="taken_at" />,
            completed_at: <JobFields.Date job={ job } user="completor" date="completed_at" />,
            attachment: <JobFields.Attachment attachment={ job?.attachment } />,
            action: <JobFields.Action job={ job } variables={ variables } />
        }
    });
};


const JobTableHelper = (columnsHide = []) => {

    jobsColumns = jobsColumns.filter(({ dataIndex }) => !columnsHide?.includes(dataIndex))

    return {
        columns: jobsColumns,
        data: jobsData,
    }

}

export default JobTableHelper;