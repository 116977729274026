import { gql } from '@apollo/client';


export const GET_CRAFTS = gql`
    query Crafts(
        $ee_id: ID
        $hall_id: ID
        $stand_id: ID
        $subcontractor_id: ID
        $where: QueryCraftsWhereWhereConditions
        $orderBy: [QueryCraftsOrderByOrderByClause!]
        $first: Int
        $page: Int
    ) {
        crafts(
        ee_id: $ee_id
        hall_id: $hall_id
        stand_id: $stand_id
        subcontractor_id: $subcontractor_id
        where: $where
        orderBy: $orderBy
        first: $first
        page: $page
        ) {
        paginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
        data {
            id
            type
            title
            status
            jobs_count
            complete_at
            subcontractor_id
            stand_id
            ee_id
            subcontractor {
                id
                title
              }
              craft_jobs {
                id
                type
                craft_id
                craft_type
              }
           }
        }
    }
`;
