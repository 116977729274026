import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

import { GET_USERS } from "graphql/query/user-gql";
import { ROLE_SYNC_USERS } from "graphql/mutation/role-gql";

import UserGroups from "components/usergroup";
import Users from "components/users";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";

const { Search } = Input;

const basePath = UserGroups.Const.basePath;
const tableHelper = UserGroups.Helpers.TableUsers;


const UserGroupUsersSubPage = ({ role }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_UserGroups" }).props.children,
            path: `${ basePath }`
        },
        {
            label: role?.label,
            path: `${ basePath }/${ role?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_GroupUsers" }).props.children,
            path: `${ basePath }/${ role?.id }/group-users`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/${ role.id }/group-users/page/:pageNum`);

    const groupUsers = { column: 'ROLE_ID', operator: "EQ", value: parseInt(role.id) }
    const [ searchText, setSearchText ] = useState("");


    return (
        <Tables.Main
            model="users"
            query={ GET_USERS }
            varSet={ { perPage: 30 } }
            currentObj={ role }
            routeUrl={ `${ basePath }/${ role.id }/group-users` }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ groupUsers }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Search
                        className="filter-search"
                        prefix={ <SearchOutlined /> }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_User" }).props.children }
                        onChange={ (e) => setSearchText(e.target.value) }
                    />
                </div>

                <div className="col">
                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button
                                type="primary">
                                <PlusOutlined /> <Localize>GLOBAL.Button_Text_UserToGroup</Localize>
                            </Button>
                        }>
                        <strong className="modal-title"><Localize>FORMS.Title_AddUsers</Localize></strong>

                        <Users.Forms.Add
                            propsObj={ {
                                query: GET_USERS,
                                queryVar: {
                                    first: 10000,
                                    where: groupUsers
                                },
                                mutation: ROLE_SYNC_USERS,
                                mutationName: "roleSyncToUsers",
                                model: "role_id",
                                modelID: role?.id,
                            }
                            } />
                    </ModalStandard>
                </div>

            </div>
        </Tables.Main>
    );

};

export default UserGroupUsersSubPage;