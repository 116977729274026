import { gql } from '@apollo/client';


export const STAND_CREATE = gql`
    mutation StandCreate($input: StandFieldsInput!) {
        standCreate(input: $input) {
        label
        message
        stand {
            id
        }
        }
    }
`;

export const STAND_UPDATE = gql`
    mutation StandUpdate($input: StandFieldsInput!) {
        standUpdate(input: $input) {
        label
        message
        stand {
            id
            ee_id
            hall_id
            hall_name
            exhibitor
            aquise_number
            proauf_number
            block
            corridor
            stand_number
            cad_number
            delivery_time_at
            file_stand_plan
            file_info
            file_graphic
            file_photo
            file_animation
            keys {
            id
            title
            number
            stand_id
            }
            created_at
            updated_at
            exhibitionEvent {
            id
            title
            status
            }
            hall {
            id
            title
            }
        }
        }
    }
`;

export const STAND_DELETE = gql`
    mutation StandDelete($id: ID!) {
        standDelete(id: $id) {
        label
        message
        }
    }
`;


export const STAND_UPLOAD_FILE = gql`
    mutation FileUpload($input: FileUpload) {
        fileUpload(input: $input) {
        label
        message
        }
    }
`;


export const STAND_KEY_CREATE = gql`
    mutation StandKeyCreate($input: StandKeyFieldsInput!) {
        standKeyCreate(input: $input) {
        label
        message
        stand {
            id
            ee_id
            hall_id
            keys {
                id
                title
                number
                }
            }
        }
    }
`;


export const STAND_KEY_UPDATE = gql`
    mutation StandKeyUpdate($input: StandKeyFieldsInput!) {
        standKeyUpdate(input: $input) {
        label
        message
        stand {
            id
            ee_id
            hall_id
            keys {
                id
                title
                number
                }
            }
        }
    }
`;


export const STAND_KEY_DELETE = gql`
    mutation StandKeyDelete($id: ID!, $stand_id: ID!) {
        standKeyDelete(id: $id, stand_id: $stand_id) {
        label
        message
        stand {
            id
            ee_id
            hall_id
            keys {
                id
                title
                number
                }
            }
        }
    }
`;


export const STANDS_ACTIONS = gql`
    mutation StandsActions(
        $action: StandsAction!
        $where: StandsActionWhere!
        $ee_id: ID!
        $stand_id: [ID]!
        $hall_id: ID
        $craft_type: [CraftTypeField]
        $value: Mixed
    ) {
        standsActions(
        action: $action
        where: $where
        ee_id: $ee_id
        stand_id: $stand_id
        hall_id: $hall_id
        craft_type: $craft_type
        value: $value
        ) {
        label
        message
        }
    }
`;