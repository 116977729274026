import React, { useState } from "react";
import { Waypoint } from 'react-waypoint'
import { Form, Tag, Checkbox, Skeleton } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from "@apollo/client";
import { GET_EVENTS_CURSOR } from "graphql/query/event-gql";
import MultiSelect from 'components/layout/form-elements/multi-select';
import EditableTagGroup from 'components/layout/form-elements/editable-tag-group';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import { helperFunc } from 'utils';

import './events-select.scss';


const EventSelect = ({
    form,
    initialValue,
    model = "exhibitionEventsCursor",
    name = 'ee_id',
    label = Localize({ children: "FORMS.Input_Label_AssignedExhibitions" }).props.children
}) => {


    const eventListInit = initialValue?.map((event) => event?.id.toString());
    const [ eventList, setEventList ] = useState(eventListInit);
    const [ searchEvent, setSearchEvent ] = useState("");

    form.setFieldsValue({ [ name ]: eventList });

    let { data, loading, fetchMore } = useQuery(GET_EVENTS_CURSOR, {
        variables: {
            text: useDebounce(searchEvent),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const {
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data,
            loading,
            fetchMore
        });

    const initialValues = initialValue && initialValue?.map(
        ({ id, title }) => ({
            value: id,
            label: title
        })
    )


    return (
        <Form.Item
            name={ name }
            label={ label }
        >

            <EditableTagGroup
                setTags={ setEventList }
                tags={ initialValues ? helperFunc.getLabel(eventList, [ ...new Set([ ...nodes, ...initialValues ]?.map(item => JSON.stringify(item))) ]?.map(item => JSON.parse(item))) : helperFunc.getLabel(eventList, nodes) }
            >
                <ModalStandard
                    width={ 380 }
                    extraClass={ 'modal-form' }
                    modalButton={
                        <Tag className="tag-main site-tag-plus">
                            <PlusOutlined /> <Localize>MODAL.Button_Text_AddExhibition</Localize>
                        </Tag> }>

                    <strong className="modal-title"><Localize>FORMS.Title_AddExhibitions</Localize></strong>

                    <MultiSelect
                        setSearch={ setSearchEvent }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Exhibition" }).props.children }
                    >
                        { searchEvent && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                            <Checkbox.Group
                                className='group-checkbox'
                                // options={ nodes }
                                options={ initialValues ? [ ...new Set([ ...nodes, ...initialValues ]?.map(item => JSON.stringify(item))) ]?.map(item => JSON.parse(item)) : nodes }
                                defaultValue={ eventList }
                                onChange={ value => setEventList(value) } />
                        }

                        { pageInfo?.hasNextPage &&

                            <>
                                { loading && <Loader style={ { marginTop: '-30px' } } /> }
                                <Waypoint onEnter={ () => fetchMoreAction() } />
                            </>
                        }

                    </MultiSelect>

                </ModalStandard>

            </EditableTagGroup>
        </Form.Item>
    )
};

export default EventSelect;


