import Craft from 'components/craft';
import { helperFunc } from 'utils';


const JobCraftField = ({ job }) => {

    const { charSplitToUpper } = helperFunc;
    const { getTranslationCraft } = Craft.Helpers.Functions;

    return (
        <span className='color-white'>

            { `${ charSplitToUpper(job?.craft?.title) }` }
            {/* { getTranslationCraft(charSplitToUpper(job?.craft?.title, '_', false)) || job?.craft?.title } */ }
            <br />
            ( { getTranslationCraft(charSplitToUpper(job?.craft?.type, '_', false)) } )
        </span>
    );
};


export default JobCraftField;