import UserInfoField from "./user-info-field";
import UserRoleField from "./user-role-field";
import UserExhibitionField from './user-exhibition-field';
import UserActionField from './user-action-field';
import UserCompanyField from './user-company-field';
import UserMessage from './user-message-field';
import UserPhoneField from './user-phone-field';


const UserFields = {
    Info : UserInfoField,
    Role : UserRoleField,
    Exhibition: UserExhibitionField,
    Action: UserActionField,
    Company: UserCompanyField,
    Message: UserMessage,
    Phone: UserPhoneField
}

export default UserFields;