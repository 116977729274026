import HallActions from './actions';
import HallFields from "./fields";
import HallForms   from "./forms";
import HallConst   from "./hall-const";
import HallHelpers from "./helpers";


const Hall = {
    Actions : HallActions,
    Fields  : HallFields,
    Forms   : HallForms,
    Const   : HallConst,
    Helpers : HallHelpers,

}

export default Hall;