import { Link } from 'react-router-dom';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { hallUseMutation } from '../hooks';
import Icons from 'components/icons';
import { UploadFile, Localize } from 'components/service';



const HallPlanField = ({ hall }) => {

    const fileListItem = [
        {
            field: 'hall_plan',
            label: <Localize>ACTIONS.List_Item_Label_HallPlan</Localize>,
        } ]
        .map(({ field, label }) => {

            const {
                _setHallUploadFile,
                loadingMutationHallUploadFile,
            } = hallUseMutation.uploadFile({ id: hall?.id });


            return (

                <li key={ field }>

                    { hall?.[ field ] ?

                        <div className='download-file'>
                            <Link
                                to='#'
                                onClick={ () => {
                                    window.open(
                                        hall?.[ field ],
                                        '_blank'
                                    );
                                } }

                            >
                                <Icons.Download /> <span className='link-text'>{ label }</span>
                            </Link>
                            <span onClick={ () => {
                                _setHallUploadFile({
                                    variables: {
                                        input: {
                                            model: 'Hall',
                                            model_id: hall.id,
                                            model_field: field,
                                            file: null
                                        }
                                    }
                                })
                            } }>

                                { loadingMutationHallUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Delete /> }
                            </span>
                        </div>
                        :
                        <UploadFile
                            setMutation={ _setHallUploadFile }
                            loading={ loadingMutationHallUploadFile }
                            accept="*/*"
                            varSetMutation={ {
                                input: {
                                    model: 'Hall',
                                    model_id: hall.id,
                                    model_field: field,
                                }
                            } }
                            uploadBtn={
                                <div className='upload-file'>
                                    <span className='link-text'>{ label }</span>
                                    { loadingMutationHallUploadFile ? <Loading3QuartersOutlined spin /> : <Icons.Import /> }
                                </div>
                            } />
                    }

                </li>
            )
        });


    return (
        <ul className='download-file-list' style={ { width: '140px' } }>

            { fileListItem }

        </ul>

    );
};

export default HallPlanField;