import { gql } from '@apollo/client';


export const GET_LOGS = gql`
    query Logs(
        $text: String
        $where: QueryLogsWhereWhereConditions
        $orderBy: [QueryLogsOrderByOrderByClause!]
        $first: Int
        $page: Int
        $user_id: ID
        $stand_id: ID
        $ee_id: ID
    ) {
        logs(
             text: $text,
             where: $where,
             orderBy: $orderBy,
             first: $first,
             page: $page,
             user_id: $user_id,
             stand_id: $stand_id,
             ee_id: $ee_id
             ) {
        paginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
        data {
            id
            ee_id
            ee_title
            hall_id
            hall_title
            stand_id
            stand_number
            craft_title
            craft_type
            craft_job_type
            craft_job_id
            action
            user_id
            user_full_name
            msg_en
            msg_de
            created_at
            updated_at
            }
        }
    }
`;

