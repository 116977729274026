import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import { useMutation } from "@apollo/client";

import { SUBCONTRACTOR_CREATE } from "graphql/mutation/subcontractor-gql";

import SubcontractorConst from '../../subcontractor-const';
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from "components/service";


const basePath = SubcontractorConst.basePath;

const SubcontractorCreateForm = () => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [ subcontractorCreate, { loading } ] = useMutation(SUBCONTRACTOR_CREATE,
        {
            update(cache, { data }) {

                const {
                    subcontractorCreate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if (data?.subcontractorCreate?.subcontractor.id)
                {

                    history.push(`${ basePath }`);

                }

            },
            onError(error) {
                errorNotification(error);
            }
        }

    );

    return (
        <>
            <Form
                key="create-subcontractor"
                layout="vertical"
                className="subcontractor-form"
                form={ form }
                onFinish={ (values) => {
                    subcontractorCreate({
                        variables: {
                            input: { ...values }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    name="title"
                    label={ <Localize>FORMS.Input_Label_Title</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Title</Localize> }
                    ] }
                >
                    <Input name="label" />
                </Form.Item>

                <Form.Item
                    name="number"
                    label={ <Localize>FORMS.Input_Label_Number</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_Number</Localize> }
                    ] }
                >
                    <Input name="label" />
                </Form.Item>

                {/* <Form.Item
                    name  = "type"
                    label = "type"
                    rules = {[
                        { required: false, message: 'Please input type' }
                    ]}
                >
                    <Input name="label" />
                </Form.Item> */}

                <div className="form-btn-holder">

                    <Button
                        className="btn-right"
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize>SUBCONTRACTOR.Button_Text_Create</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default SubcontractorCreateForm;