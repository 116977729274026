import { useMutation } from "@apollo/client";

import { GET_JOBS } from 'graphql/query/job-gql';
import { JOB_UPDATE, JOB_CREATE } from "graphql/mutation/job-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationJobCreateUpdate = ({ jobID, variables }) => {

    const JOB_CREATE_UPDATE = jobID ? JOB_UPDATE : JOB_CREATE;
    const jobCreateUpdate = jobID ? 'craftJobUpdate' : 'craftJobCreate';

    const [ _setJobCreateUpdate, { loading } ] = useMutation(JOB_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ jobCreateUpdate ]: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },
            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_JOBS,
                    variables
                },
            ]
        }
    );



    return {
        _setJobCreateUpdate,
        loadingMutationJobCreateUpdate: loading,
    }
};

export default useMutationJobCreateUpdate;

