import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import { useMutation } from "@apollo/client";
import { ROLE_UPDATE } from "graphql/mutation/role-gql";
import UserGroupConst from '../../usergroup-const';
import FormItems from "../fields";
import DeleteForm from "../usergroup-delete-form";
import { errorNotification, successNotification } from "components/request-result";
import { Localize } from "components/service";


const basePath = UserGroupConst.basePath;

const UserGroupEditForm = ({ role }) => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    let permissionIds = [];
    let disabledPermissionCheckbox = false;

    role.permissions.map(perm => permissionIds.push(parseInt(perm.id)));

    const [ roleUpdateRequest, { loading } ] = useMutation(ROLE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    roleUpdate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                history.push(`${ basePath }`);

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    if (role.slug === 'developer' || role.slug === 'admin')
    {

        disabledPermissionCheckbox = true;

    }

    return (
        <>
            <Form
                key="edit-user-group"
                layout="vertical"
                form={ form }
                className="usergroup-form"
                initialValues={ {
                    label: role.label,
                    permission_id: permissionIds,
                    colorHex: role.colorHex
                } }
                onFinish={ (values) => {
                    roleUpdateRequest({
                        variables: {
                            input: {
                                id: role.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    name="label"
                    label={ <Localize>FORMS.Input_Label_GroupName</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_GroupName</Localize> }
                    ] }
                >
                    <Input name="label" />
                </Form.Item>

                <FormItems.ColorSelect />

                <FormItems.PermissionCheckbox disabled={ disabledPermissionCheckbox } />

                <div className="form-btn-holder">

                    <DeleteForm role={ role } />

                    <Button
                        className="btn-right"
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default UserGroupEditForm;