import { Localize } from "components/service";


function statusColor(status) {
    switch (status)
    {
        case 'waiting':
            return 'color-white';

        case 'in_progress':
            return 'color-yellow';

        case 'completed':
            return 'color-green';

        default:
            return '';
    }
}


function getTranslationType(type) {
    switch (type)
    {
        case 'claims':
            return <Localize>JOB.Type_Text_Claim</Localize>;

        case 'last_minutes':
            return <Localize>JOB.Type_Text_LastMinute</Localize>;

        case 'customer_claims':
            return <Localize>JOB.Type_Text_CustomerClaim</Localize>;

        case 'damages':
            return <Localize>JOB.Type_Text_Damage</Localize>;

        case 'protocol_defects':
            return <Localize>JOB.Type_Text_ProtocolDefect</Localize>;

        default:
            return '';
    }
}


function getTranslationStatus(type) {
    switch (type)
    {
        case 'waiting':
            return <Localize>JOB.Status_Text_Open</Localize>;

        case 'in_progress':
            return <Localize>JOB.Status_Text_InProgress</Localize>;

        case 'completed':
            return <Localize>JOB.Status_Text_Completed</Localize>;

        default:
            return '';
    }
}


const jobFuncHelper = {
    statusColor,
    getTranslationType,
    getTranslationStatus,
};

export default jobFuncHelper;