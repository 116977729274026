import CraftForms from "./forms";
import CraftConst from "./craft-const";
import CraftHelpers from "./helpers";
import { craftUseMutation } from "./hooks";



const Craft = {
  Hooks: craftUseMutation,
  Forms: CraftForms,
  Const: CraftConst,
  Helpers: CraftHelpers
}

export default Craft;