import React from 'react';
import { Form, Select } from "antd";
import JobConst from '../../job-const';
import JobHelpers from '../../helpers';
import Icons from "components/icons";
import { Localize } from "components/service";


const JobSelectTypeFormItem = ({
    form,
    disabled = false,
    rules = true,
    typeAll = false,
    onChangeSetFilter = () => { },
    initialValue,
    initialValueCraft,
    mode = '',
    name = 'type',
    label = Localize({ children: "FORMS.Input_Label_JobType" }).props.children,
    craftTypeSelected,
}) => {

    const { getTranslationType } = JobHelpers.Functions;

    function getJobType(craftTypeSelected) {

        if ((craftTypeSelected === 'protocol' || initialValueCraft === 'protocol') && typeAll)


            return JobConst.jobTypes.filter((type) => type.value === "protocol_defects")

        if (typeAll)

            return JobConst.jobTypes.filter((type) => type.value !== "protocol_defects")

        else
            return JobConst.jobTypes;
    }


    return (
        <Form.Item
            name={ name }
            label={ label }
            initialValue={ initialValue }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children } ${ label }` } ] }>
            <Select
                value={ Localize({ children: getTranslationType(initialValue)?.props?.children })?.props?.children }
                showSearch
                suffixIcon={ <Icons.Arrow /> }
                mode={ mode }
                className="select-serch-input"
                disabled={ disabled }
                onChange={ value => {
                    form.setFieldsValue({ [ name ]: value })
                    onChangeSetFilter(false, name, value);
                } }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children } ${ label }` }
                dropdownClassName="select-serch-dropdown job-select"
                optionFilterProp="value"
            >

                { getJobType(craftTypeSelected)?.map((type) => (
                    <Select.Option key={ type.value } value={ type.value }>
                        <span>{ type.label }</span>
                    </Select.Option>
                ))
                }
            </Select>
        </Form.Item>
    );

}

export default JobSelectTypeFormItem;


