import { Input, Button } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { Localize } from "components/service";
import './multi-select.scss'


const MultiSelect = ({
  name = '',
  disableBtn = false,
  extraFields = false,
  formReset = () => { },
  setBtnConfirm = () => { },
  clearLocalStore = () => { },
  disableSearch = false,
  children,
  action,
  placeholder = Localize({ children: "SEARCH.Input_Placeholder_MultiSelect" }).props.children,
  setSearch,
  search = undefined,
}) => {

  return (
    <div className='multi-select'>

      { extraFields && extraFields }
      { !disableSearch &&
        <Input.Search
          value={ search }
          className='search-input'
          prefix={ <SearchOutlined /> }
          placeholder={ placeholder }
          onChange={ (e) => setSearch(e.target.value) }
        /> }

      <div className='holder-group-select'>

        <span className='line-top'></span>
        <div style={ { height: '267px', overflowY: "auto" } }>

          { children }

        </div>

        <span className='line-bottom'></span>
      </div>

      {
        !disableBtn &&

        <div className="form-btn-holder">
          <Button
            className="ant-btn-secondary bg-light-gray"
            onClick={ () => {
              action();
              clearLocalStore();
              formReset();
              setBtnConfirm({
                [ name ]: false,
              });
            } }
          >
            <Localize>GLOBAL.Button_Text_Cancel</Localize>
          </Button>
          <Button
            className="btn-right"
            type="primary"
            onClick={ () => {
              action();
              setBtnConfirm({
                [ name ]: true,
              });
            } }
          >
            <Localize>GLOBAL.Button_Text_Confirm</Localize>
          </Button>
        </div>
      }


    </div>
  );
}


export default MultiSelect;