import React from "react";
import { Form, Input, Button } from "antd";
import UserGroup from "components/usergroup";
import Events from "components/events";
import { Localize } from "components/service";


const UserFiltersForm = ({ action, usersFiltersEeID, usersFilters, setCount, setFilters, setEventID, setFilterList, filterList, gqlBuilderWhere, form }) => {

    const valueFieldEventID = Form.useWatch('ee_id', form);

    const initialValFormatted = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return val.replace(/[^a-z0-9]/gi, '');
    }

    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        if (name === 'COMPANY')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "LIKE",
                        value: `${ value }%`
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }

        if (name === 'ROLE_ID')
        {
            value.toString().length > 0 ?

                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "IN",
                        value: value
                    }
                }) : setFilterList({ ...filterList, [ name ]: {} });
        }
    }


    return (

        <div className="ant-form ant-form-vertical filters-form">

            <Form.Item
                initialValue={ initialValFormatted(usersFilters, 'COMPANY') }
                name="COMPANY"
                label={ <Localize>FORMS.Input_Label_Company</Localize> }
                onChange={ onChangeSetFilter }
            >
                <Input name="COMPANY" placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Company" }).props.children }` } />
            </Form.Item>

            <UserGroup.Forms.Fields.GroupSelect
                initialValue={ usersFilters?.ROLE_ID?.value }
                name="ROLE_ID"
                mode="multiple"
                form={ form }
                onChangeSetFilter={ onChangeSetFilter }
            />

            <Events.Forms.Fields.RadioSelect
                initialValue={ usersFiltersEeID }
                name="ee_id"
                label={ <Localize>FORMS.Input_Label_Exhibition</Localize> }
                form={ form }
                iconExtraClass="arrow-down"
                clearLocalStore={ () => localStorage.removeItem("usersFiltersEeID") }
                iconDown={ true }
                valueFieldEventID={ valueFieldEventID }
            />

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...usersFilters, ...filterList }));
                        setCount({ ...usersFilters, ...filterList });
                        setEventID(valueFieldEventID);
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setEventID(undefined);
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("usersFilters");
                        localStorage.removeItem("usersFiltersEeID");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default UserFiltersForm;