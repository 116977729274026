import React from "react";
import { useQuery } from "@apollo/client";

import { GET_ROLE } from "graphql/query/role-gql";

import RoutePage from "components/routes/usergroups/usergroup-route";
import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import UserGroupConst from "components/usergroup/usergroup-const";
import { Localize } from "components/service";


const basePath = UserGroupConst.basePath;

const UserGroupSinglePage = ({ match }) => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const pageID = match ? parseInt(match.params.id) : null;

    const { data: { role } = {}, loading } = useQuery(GET_ROLE, {
        variables: {
            id: pageID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_GroupUsers" }).props.children,
            path: "group-users"
        } ];


    return (
        <PageWrapDefault
            className="page-user-groups"
            loading={ loading }
            title={ !loading && role ? role.label : "Page 404" }
            dataExist={ !loading && role }
            pageNavbar={ role ? pageNavbar : false }
            staticPath={ `${ basePath }/${ pageID }` }
        >
            <RoutePage role={ role } />
        </PageWrapDefault>
    );
};

export default UserGroupSinglePage;