import React from "react";
import { Form, Button, DatePicker, Checkbox, Input } from 'antd';
import { craftUseMutation } from '../../hooks';
import CraftFormItems from '../../forms/fields';
import Subcontractor from "components/subcontractor";
import { Localize } from 'components/service';
import { timeFormat } from "utils/helper-functions";
import moment from 'moment';


const CraftEditForm = ({ standID, craft, variables, action }) => {

    const [ form ] = Form.useForm();
    const craftID = +craft?.id;

    const {
        _setCraftCreateUpdate,
        loadingMutationCraftCreateUpdate: loading,
    } = craftUseMutation.createUpdate({ craftID, standID, variables });


    return (
        <Form
            key="edit-stand-form"
            layout="vertical"
            form={ form }
            className="model-form edit-stand-form"
            onFinish={ (values) => {

                _setCraftCreateUpdate({
                    variables: {
                        input: {
                            id: craftID ? craftID : undefined,
                            stand_id: standID,
                            ...values,
                            subcontractor_id: values.subcontractor_id?.value || values.subcontractor_id,
                            status: values.status ? 'completed' : 'in_progress',
                            complete_at: timeFormat(values?.complete_at)
                        }
                    }
                });

                action();
                form.resetFields();
            } }>

            <CraftFormItems.SelectType
                form={ form }
                initialValue={ craft?.type } />

            <Form.Item
                name="title"
                label={ <Localize>FORMS.Input_Label_CraftTitle</Localize> }
                initialValue={ craft?.title }
            >
                <Input name="title" />
            </Form.Item>

            <Form.Item
                name="status"
                initialValue={ craft?.status === 'completed' ? true : false }
            >
                <Checkbox
                    defaultChecked={ craft?.status === 'completed' ? true : false }
                    onChange={ (e) => form.setFieldsValue({ status: e.target.checked }) }
                >
                    <Localize>FORMS.Checkbox_Label_CraftCompleted</Localize>
                </Checkbox>
            </Form.Item>


            <Form.Item
                name="complete_at"
                label={ <Localize>FORMS.Input_Label_TimeOfCompletion</Localize> }
                initialValue={ craftID ? moment(craft?.complete_at) : '' }
                rules={ [ { required: true, message: <Localize>FORMS.Input_Placeholder_CraftCompletion</Localize> } ] }
            >
                <DatePicker
                    minuteStep={ 15 }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_SelectDate" }).props.children }
                    showTime format="DD.MM.YYYY HH:mm"
                />
            </Form.Item>

            <Subcontractor.Forms.Fields.Select
                form={ form }
                initialValue={ craft?.subcontractor_id ? { label: craft?.subcontractor?.title, value: craft?.subcontractor_id } : null } />

            <div className="form-btn-holder">

                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    { craftID ? <Localize>GLOBAL.Button_Text_SaveChanges</Localize> : <Localize>CRAFT.Button_Text_CreateCraft</Localize> }
                </Button>
            </div>
        </Form>
    );
};

export default CraftEditForm;


