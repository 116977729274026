import UsersTableHelper from './users-table-helper';
import usersFuncHelper from './users-functions-helper';
import UserLogsTableHelper from './users-logs-table-helper';

const UsersHelpers = {
    TableMain: UsersTableHelper,
    TableLogs : UserLogsTableHelper,
    Functions: usersFuncHelper,
}

export default UsersHelpers;