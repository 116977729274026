import React from "react";
import { Link } from "react-router-dom";

import UserGroupConst from '../usergroup-const';


const UserGroupUsersViewField = ({ role }) => {

    return (
        <div className='view-box-field'>
            { role?.usersCount ?
                <Link
                    to={ `${ UserGroupConst.basePath }/${ role.id }/group-users` }
                >
                    <span className='count'>{ role.usersCount } users</span>
                    <span className='btn-view'>view</span>
                </Link> : <strong>&mdash;</strong> }
        </div>
    );

};

export default UserGroupUsersViewField;