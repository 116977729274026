const standsBasePath = '/stands';


const StandConst = {
   basePath: standsBasePath,
};

export default StandConst;









