import React, { useState, useEffect } from "react";
import { Button, Form } from 'antd';
import { GET_EVENTS_LOG_UNIQ_CURSOR } from "graphql/query/event-gql";
import UserForms from "../../forms";
import Icons from 'components/icons';
import Events from "components/events";
import { DrawerStandart, Localize } from "components/service";
import { gqlBuilderWhere, helperFunc } from "utils";


const UserLogsTableFilter = ({ setFilters = false, setEventID, userID, eventID }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState({});
    const [ count, setCount ] = useState();
    const [ btnConfirm, setBtnConfirm ] = useState({ ee_id: false });

    const filterCount = helperFunc.countFilterList(count);

    const valueFieldEventID = Form.useWatch('ee_id', form);

    /*eslint-disable */
    useEffect(() => {
        setEventID(valueFieldEventID);
    }, [ btnConfirm ]);
    /*eslint-enable */

    return (
        <div className="d-flex-md align-items-center">
            <Form
                key="edit-model-form"
                layout="vertical"
                form={ form }
                className="model-form edit-model-form"
            >
                <div className="holder-form">

                    <Events.Forms.Fields.RadioSelect
                        model="logEventsUniq"
                        query={ GET_EVENTS_LOG_UNIQ_CURSOR }
                        name="ee_id"
                        userID={ userID }
                        form={ form }
                        valueFieldEventID={ valueFieldEventID }
                        setBtnConfirm={ setBtnConfirm }
                    />
                </div>
            </Form>

            { eventID && setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }>

                    <DrawerStandart
                        width="287"
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <UserForms.FiltersLog
                            eeID={ eventID }
                            setCount={ setCount }
                            setFilters={ setFilters }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            form.resetFields([ 'CRAFT_JOB_TYPE', 'CRAFT_JOB_ID', 'CREATED_AT', 'HALL_ID', 'STAND_NUMBER', 'CRAFT_TYPE' ]);
                            setFilterList({});
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                        } }
                    >
                        <span><Localize>GLOBAL.Modal_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </div>
    );
};

export default UserLogsTableFilter;
