import SubcontractorsPage      from "./subcontractors-page";
import SubcontractorCreatePage from "./subcontractor-create-page";
import SubcontractorSinglePage from "./subcontractor-single-page";
import SubcontractorSubPage    from "./sub-page";

const SubcontractorPages = {
    Archive : SubcontractorsPage,
    Create  : SubcontractorCreatePage,
    Single  : SubcontractorSinglePage,
    SubPage : SubcontractorSubPage
}

export default SubcontractorPages;