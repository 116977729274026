import UserChangePasswordForm from "./user-change-password-form";
import UserEditForm from "./user-edit-form";
import UserAddForm from "./user-add-form";
import UserCopyEventForm from "./user-copy-event-form";
import UserLogFiltersForm from "./user-log-filters-form";
import UserFiltersForm from "./user-filters-form";


import './forms.scss';

const UserForms = {
    ChangePassword: UserChangePasswordForm,
    Edit: UserEditForm,
    Add: UserAddForm,
    CopyEvent: UserCopyEventForm,
    Filter: UserFiltersForm,
    FiltersLog: UserLogFiltersForm
};

export default UserForms;
