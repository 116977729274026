import useMutationStandCreateUpdate from './stand-use-mutation-create-update';
import useMutationStandDelete from "./stand-use-mutation-delete";
import useMutationStandUploadFile from './stand-use-mutation-upload-file';
import useMutationStandKeyCreate from './stand-key-use-mutation-create';
import useMutationStandKeyUpdate from './stand-key-use-mutation-update';
import useMutationStandKeyDelete from "./stand-key-use-mutation-delete";
import useMutationStandsActions from './stand-use-mutation-actions';
import useMutationStandReport from './stand-use-mutation-report';


export const standUseMutation = {
  createUpdate: useMutationStandCreateUpdate,
  delete: useMutationStandDelete,
  keyCreate: useMutationStandKeyCreate,
  keyUpdate: useMutationStandKeyUpdate,
  keyDelete: useMutationStandKeyDelete,
  UploadFile: useMutationStandUploadFile,
  actions: useMutationStandsActions,
  report: useMutationStandReport,
}