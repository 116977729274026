import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { GET_USERS } from 'graphql/query/user-gql';

import Users from 'components/users';
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import './users.scss';


const basePath = Users.Const.basePath;
const tableHelper = Users.Helpers.TableMain;


const UsersPage = () => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" } ];

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ eventID, setEventID ] = useState(undefined);
    const [ resetSorting, setResetSorting ] = useState(false);

    const usersFilters = JSON.parse(localStorage.getItem("usersFilters"));
    const usersFiltersEeID = JSON.parse(localStorage.getItem("usersFiltersEeID"));
    const usersSorting = JSON.parse(localStorage.getItem("usersSorting"));

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_UserGroups" }).props.children,
            path: "user-groups"
        } ];


    return (
        <PageWrapDefault
            className="page-users"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Users</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >
            <Tables.Main
                model="users"
                query={ GET_USERS }
                varSet={ { ee_id: eventID || usersFiltersEeID, perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                objFilter={ filters || gqlBuilderWhere(usersFilters) }
                objOrderBy={ usersSorting || objOrderBy }
                sortingName="usersSorting"
                tableHelper={ tableHelper }
                resetSorting={ resetSorting }
            >
                <div className="table-action-bar">

                    <div className="col">
                        <Users.Filters.Table
                            filters={ filters }
                            setSearchText={ setSearchText }
                            setFilters={ setFilters }
                            setEventID={ setEventID }
                            eventID={ eventID }
                            usersFilters={ usersFilters }
                            usersFiltersEeID={ usersFiltersEeID }
                            setResetSorting={ setResetSorting }
                            objOrderBy={ objOrderBy }
                        />
                    </div>

                    <div className="col">
                        <Link
                            className="ant-btn ant-btn-primary"
                            to={ `${ basePath }/create` }>

                            <PlusOutlined /> <Localize>USER.Button_Text_Create</Localize>
                        </Link>
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default UsersPage;