import { useMutation } from "@apollo/client";

import { STAND_KEY_UPDATE } from "graphql/mutation/stand-gql";

import { successNotification, errorNotification } from "components/request-result";


const useMutationStandKeyUpdate = (stand) => {


    const [ _setStandKeyUpdate, { loading } ] = useMutation(STAND_KEY_UPDATE,
        {
            update(cache, { data }) {

                const {
                    standKeyUpdate: {
                        label,
                        message,
                        stand: {
                            keys: {
                                title,
                                number
                            }
                        }

                    }
                } = data;


                cache.modify({
                    id: cache.identify(stand),
                    fields: {
                        title() {
                            return title;
                        },
                        number() {
                            return number;
                        },
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });
            },
            onError(error) {
                errorNotification(error);
            }
        }
    );



    return {
        _setStandKeyUpdate,
        loadingMutationStandKeyUpdate: loading,
    }
};

export default useMutationStandKeyUpdate;

