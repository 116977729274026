import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { GET_LOGS } from "graphql/query/log-gql";
import Events from 'components/events';
import Stands from 'components/stands';
import { Tables } from "components/layout";
import Icons from "components/icons";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const { Search } = Input;
const eventBasePath = Events.Const.basePath;
const standBasePath = Stands.Const.basePath;
const tableHelper = Stands.Helpers.TableLogs


const StandLogsSubPage = ({ event, stand }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ eventBasePath }`
        },
        {
            label: event?.title,
            path: `${ eventBasePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }`
        },
        {
            label: stand?.exhibitor,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Logs" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }/logs`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const [ searchText, setSearchText ] = useState("");

    const routeUrl = `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }/logs`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);
    const currentPage = match ? parseInt(match.params.pageNum) : 1;

    const { _setStandReport } = Stands.Hooks.report();


    return (

        <Tables.Main
            model="logs"
            query={ GET_LOGS }
            varSet={ {
                event_id: event?.id,
                stand_id: stand?.id,
                perPage: 30,
            } }
            tableVisible={ true }
            routeUrl={ routeUrl }
            currentPage={ currentPage }
            searchText={ searchText }
            objOrderBy={ [ { column: "CREATED_AT", order: "DESC" } ] }
            tableHelper={ tableHelper }
        >
            <div className="table-action-bar">

                <div className="col">

                    <Search
                        className="filter-search"
                        prefix={ <SearchOutlined /> }
                        placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_SearchKeyWords" }).props.children }
                        onChange={ (e) => setSearchText(e.target.value) }
                    />
                </div>

                <div className="col">

                    <Button
                        style={ { maxWidth: '100%' } }
                        type='primary'
                        onClick={ () => _setStandReport({
                            variables: {
                                stand_id: stand?.id,
                            }
                        }) }
                    >
                        <Icons.Download /> <Localize>GLOBAL.Button_Text_ExportCsv</Localize>
                    </Button>

                </div>
            </div>

        </Tables.Main>
    );
};

export default StandLogsSubPage;

