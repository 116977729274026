import SubcontractorEditForm   from "./edit-form";
import SubcontractorCreateForm from "./create-form";
import SubcontractorDeleteForm from "./delete-form";
import SubcontractorFormItems from "./fields";


const SubcontractorForms = {
    Edit   : SubcontractorEditForm,
    Create : SubcontractorCreateForm,
    Delete : SubcontractorDeleteForm,
    Fields : SubcontractorFormItems,
};

export default SubcontractorForms;