import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import { useMutation } from "@apollo/client";
import { HALL_UPDATE } from "graphql/mutation/hall-gql";
import HallForms from "../../forms";
import { errorNotification, successNotification } from "components/request-result";
import { ModalStandard, Localize } from 'components/service';
import HallConst from "components/hall/hall-const";


const parrentPath = HallConst.parrentPath;
const basePath = HallConst.basePath;


const HallEditForm = ({ hall }) => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [ hallUpdateRequest, { loading } ] = useMutation(HALL_UPDATE,
        {
            update(cache, { data }) {

                const {
                    hallUpdate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if (data?.hallUpdate?.hall.id)
                {

                    history.push(`${ parrentPath }/${ hall.ee_id }${ basePath }`);

                }

            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (
        <>
            <Form
                key="edit-hall"
                layout="vertical"
                form={ form }
                className="hall-form"
                initialValues={ {
                    title: hall.title,
                    number: hall.number
                } }
                onFinish={ (values) => {
                    hallUpdateRequest({
                        variables: {
                            input: {
                                id: hall.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    name="title"
                    label={ <Localize>FORMS.Input_Label_HallName</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_HallName</Localize> }
                    ] }
                >
                    <Input name="label" placeholder={ Localize({ children: "FORM_RULES.Required_HallName" }).props.children } />
                </Form.Item>

                <Form.Item
                    name="number"
                    label={ <Localize>FORMS.Input_Label_HallNumber</Localize> }
                    rules={ [
                        { required: true, message: <Localize>FORM_RULES.Required_HallNumber</Localize> }
                    ] }
                >
                    <Input name="label" placeholder={ Localize({ children: "FORM_RULES.Required_HallNumber" }).props.children } />
                </Form.Item>

                <div className="form-btn-holder">

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button className="ant-btn-secondary">
                                <Localize>HALL.Button_Text_Delete</Localize>
                            </Button>
                        }>

                        <strong className="modal-title"><Localize>FORMS.Title_DeleteHall</Localize></strong>

                        <HallForms.Delete hall={ hall } />

                    </ModalStandard>

                    <Button
                        className="btn-right"
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );
}

export default HallEditForm;