import { useMutation } from "@apollo/client";

import { EVENT_ATTCHMENT_DELETE } from "graphql/mutation/event-gql";

import { successNotification, errorNotification } from "components/request-result";



const useMutationEventAttachmentDelete = (id, documentCreate) => {

  const [ _setMutationEventAttachmentDelete, { loading } ] = useMutation(EVENT_ATTCHMENT_DELETE,
    {
      update(cache, { data }) {

        const {
          attachmentDelete: {
            label,
            message
          }
        } = data;


        cache.evict({
          id: cache.identify({ id, __typename: "Attachment" })
        });
        cache.gc();


        if (!documentCreate)
        {

          successNotification({
            title: label.toUpperCase(),
            description: message
          });
        }
      },

      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationEventAttachmentDelete,
    loadingMutationEventAttachmentDelete: loading,
  }
};

export default useMutationEventAttachmentDelete;