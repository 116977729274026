import { useMutation } from "@apollo/client";
import { FURNISHING_IMPORT_REIMPORT } from "graphql/mutation/furnishing-gql";
import { GET_FURNISHINGS } from "graphql/query/furnishing-gql";


import { successNotification, errorNotification } from "components/request-result";


const useMutationFurnishingImportReimport = ({ variables }) => {


    const [ _setFurnishingImportReimport, { data, loading } ] = useMutation(FURNISHING_IMPORT_REIMPORT,
        {
            update(cache, { data }) {

                const {
                    furnishingImportReImport: {
                        label,
                        message,
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },


            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_FURNISHINGS,
                    variables
                },
            ]
        }
    );


    return {
        _setFurnishingImportReimport,
        dataMutationFurnishingImportReimport: data,
        loadingMutationFurnishingImportReimport: loading,
    }
};

export default useMutationFurnishingImportReimport;