import StandFields from "./fields";
import StandForms from "./forms";
import StandActions from "./actions";
import StandConst from "./stand-const";
import StandHelpers from "./helpers";
import StandFilters from "./filters";
import { standUseMutation } from "./hooks";


const Stands = {
  Actions: StandActions,
  Filters: StandFilters,
  Fields: StandFields,
  Forms: StandForms,
  Const: StandConst,
  Helpers: StandHelpers,
  Hooks: standUseMutation
}

export default Stands;