import Stands from "components/stands";
import Event from "components/events";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const basePath = Stands.Const.basePath;


const StandOverviewSubPage = ({ event, stand, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ Event.Const.basePath }`
        },
        {
            label: event?.title,
            path: `${ Event.Const.basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }`
        },
        {
            label: (stand && `${ stand?.exhibitor }`) || Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
            path:  (stand && `${ Event.Const.basePath }/${ event?.id }${ basePath }/${ stand?.id }` ) || `${ Event.Const.basePath }/${ event?.id }${ basePath }/create`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <div className="row-grid column-gap-60 col-lg-2">
            <div className="col-left">
                <Stands.Forms.Edit eventID={ event?.id } stand={ stand } history={ history } />
            </div>
            <div className="col-right">
                { stand?.id && <Stands.Actions.UploadDownload stand={ stand } /> }
            </div>
        </div>
    )
}


export default StandOverviewSubPage;
