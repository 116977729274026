import UserGroupTitleLinkField from "./usergroup-title-link-field";
import UserGroupRightsAllField from "./usergroup-rights-all-field";
import UserGroupUsersViewField from "./usergroup-users-view-field";
import UserGroupActionField    from "./usergroup-action-field";    
import UserGroupRemoveUserField from "./usergroup-remove-user-field";

const UserGroupFields = {
    EditLink   : UserGroupTitleLinkField,
    RightsAll  : UserGroupRightsAllField,
    UsersView  : UserGroupUsersViewField,
    Action     : UserGroupActionField,
    RemoveUser : UserGroupRemoveUserField
}

export default UserGroupFields;