import React, { useState } from "react";
import { Form, Input, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import Icons from "components/icons";
import { standUseMutation } from '../../hooks';
import { EntityRemoveButton, Localize } from 'components/service';


const StandKeysForm = ({ stand }) => {

    const [ form ] = Form.useForm();

    const [ isActive, setActive ] = useState(false);
    const [ keyID, setKeyID ] = useState();

    const standID = +stand?.id;

    const {
        _setStandKeyCreate,
        loadingMutationStandKeyCreate
    } = standUseMutation.keyCreate(stand);

    const {
        _setStandKeyUpdate,
        loadingMutationStandKeyUpdate
    } = standUseMutation.keyUpdate(stand);

    const {
        _setMutationStandKeyDelete,
        loadingMutationStandKeyDelete,
    } = standUseMutation.keyDelete(keyID);


    return (
        <>
            <ul className={ `keys-list ${ isActive ? "active" : "" }` }>

                { stand?.keys.map((key, index) => (

                    <li key={ +key.id }>
                        <Form
                            key="edit-stand-form"
                            layout="vertical"
                            className="model-form edit-stand-form"
                            onFinish={ (values) => {

                                const id = +key.id;
                                setKeyID(id);

                                _setStandKeyUpdate({
                                    variables: {
                                        input: {
                                            id: id ? id : undefined,
                                            stand_id: standID,
                                            ...values,
                                        }
                                    }
                                })
                            } }>

                            <strong className="title">Key { index + 1 }</strong>

                            <div className="holder">

                                <div className="row-grid grid-gap-15 row-gap-0 col-md-2">
                                    <Form.Item
                                        name="title"
                                        label={ <Localize>FORMS.Input_Label_KeyName</Localize> }
                                        initialValue={ key.title }
                                        rules={ [
                                            { required: true, message: <Localize>FORM_RULES.Required_KeyName</Localize> }
                                        ] }
                                    >
                                        <Input name="title" />
                                    </Form.Item>

                                    <Form.Item
                                        name="number"
                                        label={ <Localize>FORMS.Input_Label_Key</Localize> }
                                        initialValue={ key.number }
                                        rules={ [
                                            { required: true, message: <Localize>FORM_RULES.Required_KeyNumber</Localize> }
                                        ] }
                                    >
                                        <Input name="number" />
                                    </Form.Item>

                                </div>

                                <div className="btn-holder">
                                    <Button
                                        className="ant-btn-secondary"
                                        type="primary"
                                        htmlType="submit"
                                        loading={ +key.id === keyID ? loadingMutationStandKeyUpdate : false }
                                    >
                                        <Localize>GLOBAL.Button_Text_Save</Localize>
                                    </Button>

                                    <EntityRemoveButton
                                        modalButton={
                                            <span><Icons.Delete /></span>
                                        }
                                        nameEntity={ 'Stand Key' }
                                        dataNameEntity={ `${ key.title }` }
                                        loading={ loadingMutationStandKeyDelete }
                                        deleteMutation={ _setMutationStandKeyDelete }
                                        variables={ {
                                            id: +key.id,
                                            stand_id: standID,
                                        } }
                                    />
                                </div>
                            </div>
                        </Form>
                    </li>
                )
                ) }

                <li key="new-key">
                    <Form
                        key="edit-stand-form"
                        layout="vertical"
                        className="model-form edit-stand-form"
                        form={ form }
                        onFinish={ (values) => {

                            _setStandKeyCreate({
                                variables: {
                                    input: {
                                        stand_id: standID,
                                        ...values,
                                    }
                                }
                            });

                            form.resetFields();
                        } }>

                        <strong className="title">New Key</strong>

                        <div className="holder">

                            <div className="row-grid grid-gap-15 row-gap-0 col-md-2">
                                <Form.Item
                                    name="title"
                                    label={ <Localize>FORMS.Input_Label_KeyName</Localize> }
                                    rules={ [
                                        { required: true, message: <Localize>FORM_RULES.Required_KeyName</Localize> }
                                    ] }
                                >
                                    <Input name="title" />
                                </Form.Item>

                                <Form.Item
                                    name="number"
                                    label={ <Localize>FORMS.Input_Label_Key</Localize> }
                                    rules={ [
                                        { required: true, message: <Localize>FORM_RULES.Required_KeyNumber</Localize> }
                                    ] }
                                >
                                    <Input name="number" />
                                </Form.Item>

                            </div>
                            <div className="btn-holder">
                                <Button
                                    className="ant-btn-secondary"
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loadingMutationStandKeyCreate }
                                >
                                    <Localize>GLOBAL.Button_Text_Save</Localize>
                                </Button>
                                <span style={ { visibility: 'hidden' } }><Icons.Delete /></span>
                            </div>

                        </div>

                    </Form>
                </li>
            </ul>
            <div className="form-btn-holder">
                <Button
                    type="primary"
                    onClick={
                        (e) => {
                            e.preventDefault();
                            setActive(!isActive);
                        }
                    }
                >
                    <PlusOutlined /> <Localize>FORMS.Button_Text_AddKey</Localize>
                </Button>
            </div>
        </>
    )
};

export default StandKeysForm;


