import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { GET_CRAFTS } from "graphql/query/craft-gql";

import Events from 'components/events';
import Stands from 'components/stands';
import Craft from 'components/craft';

import { Tables } from "components/layout";
import { ModalStandard, Localize } from 'components/service';
import { useBreadCrumbs } from "components/use-hooks";


const eventBasePath = Events.Const.basePath;
const standBasePath = Stands.Const.basePath;
const craftBasePath = Craft.Const.basePath;
const tableHelper = Craft.Helpers.TableMain;


const StandCraftsSubPage = ({ event, stand }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ eventBasePath }`
        },
        {
            label: event?.title,
            path: `${ eventBasePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }`
        },
        {
            label: stand?.exhibitor,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Crafts" }).props.children,
            path: `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }${ craftBasePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const [ variables, setVariables ] = useState({});

    const routeUrl = `${ eventBasePath }/${ event?.id }${ standBasePath }/${ stand?.id }${ craftBasePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);
    const currentPage = match ? parseInt(match.params.pageNum) : 1;


    return (
        <Tables.Main
            model="crafts"
            query={ GET_CRAFTS }
            varSet={ {
                stand_id: stand?.id,
                perPage: 30,
            } }
            routeUrl={ routeUrl }
            currentPage={ currentPage }
            setVariables={ setVariables }
            tableHelper={ tableHelper }
            objOrderBy={ [] }
        >
            <div className="table-action-bar">

                <div className="col">

                    <ModalStandard
                        width={ 380 }
                        extraClass={ 'modal-form' }
                        modalButton={
                            <Button type="primary">
                                <PlusOutlined /> <Localize>CRAFT.Button_Text_AddCraft</Localize>
                            </Button>
                        }>

                        <strong className='modal-title'><Localize>FORMS.Title_CreateCraft</Localize></strong>
                        <Craft.Forms.Edit standID={ stand?.id } variables={ variables } />

                    </ModalStandard>
                </div>
            </div>

        </Tables.Main>
    );
};

export default StandCraftsSubPage;

