import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages }  from "components/pages";


export default function EventsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.Events />
            </Route>
            <Route path={ `${ path }/page/:pageNum` } exact>
                <Pages.Events />
            </Route>
            <Route path={ `${ path }/:eeID` } >
                { ({ match, history }) => <Pages.Event match={ match } history={ history } exact /> }
            </Route>
            <Route path={ `${ path }/create` } exact>
                <Pages.Event />
            </Route>
        </Switch>
    );
}

