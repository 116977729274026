import React from "react";
import { Form, Select, Button } from "antd";
import JobFormItems from "../fields";
import Craft from "components/craft";
import Icons from "components/icons";
import { Localize } from 'components/service';


const JobFiltersForm = ({ action, setCount, setFilters, standID, setFilterList, filterList, gqlBuilderWhere, form }) => {

    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        value.toString().length > 0 ?

            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "EQ",
                    value: value
                }
            }) : setFilterList({ ...filterList, [ name ]: {} });
    }

    return (
        <div className="ant-form ant-form-vertical filters-form">

            <JobFormItems.SelectType
                form={ form }
                rules={ false }
                initialValue={ Localize({ children: "FORMS.Input_Placeholder_ChooseType" }).props.children }
                name="TYPE"
                onChangeSetFilter={ onChangeSetFilter }
            />

            <Form.Item
                name="STATUS"
                label={ <Localize>FORMS.Input_Label_Status</Localize> }
                initialValue={ Localize({ children: "FORMS.Input_Placeholder_ChooseStatus" }).props.children }
            >
                <Select
                    showSearch
                    suffixIcon={ <Icons.Arrow /> }
                    className="select-serch-input"
                    onChange={ value => onChangeSetFilter(false, "STATUS", value) }
                    dropdownClassName="select-serch-dropdown job-select"
                    optionFilterProp="value"
                >
                    <Select.Option key="waiting" value="waiting"><span> <Localize>JOB.Status_Text_Open</Localize></span></Select.Option>
                    <Select.Option key="in_progress" value="in_progress"> <span><Localize>FORMS.Select_OptionLabel_InProgress</Localize></span></Select.Option>
                    <Select.Option key="completed" value="completed"> <span><Localize>FORMS.Select_OptionLabel_Completed</Localize></span></Select.Option>

                </Select>
            </Form.Item>

            <Craft.Forms.Fields.SelectId
                selectBy="type"
                name="CRAFT_TYPE"
                form={ form }
                initialValue={ Localize({ children: "FORMS.Input_Placeholder_ChooseCraftType" }).props.children }
                standID={ standID }
                rules={ false }
                filterElement="mp_control"
                onChangeSetFilter={ onChangeSetFilter }
            />

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere(filterList));
                        setCount(filterList);
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>

                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        form.resetFields();
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default JobFiltersForm;