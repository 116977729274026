import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/halls/sub-pages';


const HallRoute = ({ hall }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/stands` } >
                <SubPage.Stands hall={ hall } />
            </Route>
            <Route path={ `${ path }` }>
                <SubPage.OverView hall={ hall } />
            </Route>
        </Switch>
    );
};

export default HallRoute;
