import Fields from "../fields";
import { Localize } from "components/service";


const hallsGroupsColumns = [
    {
        title       : <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex   : 'title',
        columnIndex : 'TITLE',
        sorter      : true,
    },
    {
        title       : <Localize>TABLES.Column_Title_HallNumber</Localize>,
        dataIndex   : 'number',
        columnIndex : 'NUMBER',
        sorter      : true,
    },
    {
        title       : <Localize>TABLES.Column_Title_NumberStands</Localize>,
        dataIndex   : 'stands_count',
        columnIndex : 'STANDS_COUNT',
        sorter      : true,
    },
    {
        title       : <Localize>TABLES.Column_Title_HallPlan</Localize>,
        dataIndex   : 'hall_plan',
        columnIndex : 'HALL_PLAN',
    },
    {
        // title     : 'action',
        dataIndex : 'action',
        align     : 'right',
        width     : '4%'
    }
];

const hallsData = ( halls ) => {

    if( !halls ){
        return [];
    }

    return halls.map( hall => {

        return {
            key          : hall.id,
            title        : <Fields.EditLink hall={ hall } />,
            number       : hall.number,
            stands_count : <Fields.StandsView hall={ hall } />,
            hall_plan    : <Fields.Plan hall={ hall } />,
            action       : <Fields.Action hall={ hall } />,
        };

    });

};

const HallsTableHelper = {
    columns : hallsGroupsColumns,
    data    : hallsData,
}

export default HallsTableHelper;