import UserGroupsPage      from "./usergroups-page";
import UserGroupCreatePage from "./usergroup-create-page";
import UserGroupSinglePage from "./usergroup-single-page";
import UserGroupSubPage    from "./sub-page";


const UserGroupPages = {
    Archive : UserGroupsPage,
    Create  : UserGroupCreatePage,
    Single  : UserGroupSinglePage,
    SubPage : UserGroupSubPage
}

export default UserGroupPages;
