import React from 'react';
import { Tag, Tooltip } from 'antd';

import './editable-tag-group.scss';



const EditableTagGroup = ({ children, setTags, tags }) => {

  const handleClose = removedTag => {
    tags = tags.filter(({ value }) => value !== removedTag).map(({ value }) => value);
    setTags(tags);
  };

  return (
    <>
      { tags?.map(({ label, value }, index) => {

        const isLongTag = label.length > 20;

        const tagElem = (
          <Tag
            className="tag-main edit-tag"
            key={ +value }
            closable={ index !== -1 }
            onClose={ () => handleClose(value) }
          >
            <span
              className="tag-text"
            >
              { isLongTag ? `${ label.slice(0, 20) }...` : label }
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={ label } key={ +value }>
            { tagElem }
          </Tooltip>
        ) : (
          tagElem
        );
      }) }

      { children }
    </>
  );

}

export default EditableTagGroup;

