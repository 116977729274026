import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { STAND_UPDATE, STAND_CREATE } from "graphql/mutation/stand-gql";

import Events from 'components/events';
import Stands from 'components/stands';
import { successNotification, errorNotification } from "components/request-result";


const useMutationStandCreateUpdate = ({standID, redirect = true, eventID}) => {


    const history = useHistory();

    const STAND_CREATE_UPDATE = standID ? STAND_UPDATE : STAND_CREATE;
    const standCreateUpdate = standID ? 'standUpdate' : 'standCreate';

    const [ _setStandCreateUpdate, { loading } ] = useMutation(STAND_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ standCreateUpdate ]: {
                        label,
                        message,
                    }
                } = data;

                redirect && history.push(`${ Events.Const.basePath }/${ eventID }${ Stands.Const.basePath }`);

                successNotification({
                    title: label,
                    description: message
                });
            },
            onError(error) {
                errorNotification(error);
            }
        }
    );



    return {
        _setStandCreateUpdate,
        loadingMutationStandCreateUpdate: loading,
    }
};

export default useMutationStandCreateUpdate;

