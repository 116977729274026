import StandTitleField from "./stand-title-field";
import StandActionField from "./stand-action-field";
import StandMaterialsField from "./stand-materials-field";
import StandCraftsField from "./stand-crafts-field";
import StandLinkField from "./stand-link-field";
import StandUserInfoField from "./stand-user-info-field";
import StandMessage from "./stand-message-field";


const StandFields = {
    Title: StandTitleField,
    Action: StandActionField,
    Materials: StandMaterialsField,
    Crafts: StandCraftsField,
    Link: StandLinkField,
    UserInfo: StandUserInfoField,
    Message: StandMessage,
}

export default StandFields;