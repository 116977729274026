import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { EVENT_DELETE } from "graphql/mutation/event-gql";

import EventConst from '../event-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationEventDelete = (id) => {

  const history = useHistory();

  const [ _setMutationEventDelete, { loading } ] = useMutation(EVENT_DELETE,
    {
      update(cache, { data }) {

        const {
          eeDelete: {
            label,
            message
          }
        } = data;

        history.push(`${ EventConst.basePath }/`);

        cache.evict({
          id: cache.identify({ id, __typename: "ExhibitionEvent" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationEventDelete,
    loadingMutationEventDelete: loading,
  }
};

export default useMutationEventDelete;