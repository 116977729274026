import React from "react";
import { Form, DatePicker, Button } from "antd";
import moment from 'moment';
import { Localize } from "components/service";

const { RangePicker } = DatePicker;


const EventFiltersForm = ({ action, setCount, setFilters, setFilterList, filterList, gqlBuilderWhere, eventsFilters }) => {


    const initialDate = (date, fieldName) => {
        const val = date?.[ fieldName ]?.value;
        if (val) return [ moment(val[ 0 ]), moment(val[ 1 ]) ];
    }


    return (

        <div className="ant-form ant-form-vertical filters-form" >
            <Form.Item
                initialValue={ initialDate(eventsFilters, 'constractionStartAt') }
                name="constractionStartAt"
                label={ <Localize>FORMS.Input_Label_ConstructionStarts</Localize> }
            >

                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                constractionStartAt: {
                                    column: "CONSTRUCTIONSTART_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                constractionStartAt: {}
                            }) } />
            </Form.Item>

            <Form.Item
                initialValue={ initialDate(eventsFilters, 'constractionEndtAt') }
                name="constractionEndtAt"
                label={ <Localize>FORMS.Input_Label_ConstructionEnds</Localize> }
            >
                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                constractionEndtAt: {
                                    column: "CONSTRUCTIONEND_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                constractionEndtAt: {}
                            }) } />
            </Form.Item>

            <Form.Item
                initialValue={ initialDate(eventsFilters, 'eventStartAt') }
                name="eventStartAt"
                label={ <Localize>FORMS.Input_Label_ExhibitionStarts</Localize> }
            >
                <RangePicker
                    separator="&ndash;"
                    placeholder={ [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                    onChange={ (_, dateSrings) =>
                        dateSrings[ 0 ] !== '' ?
                            setFilterList({
                                ...filterList,
                                eventStartAt: {
                                    column: "START_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                }
                            }) : setFilterList({
                                ...filterList,
                                eventStartAt: {}
                            }) } />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...eventsFilters, ...filterList }));
                        setCount({ ...eventsFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("eventsFilters");
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default EventFiltersForm;