import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { Form, Radio, Skeleton } from "antd";
import { useQuery } from "@apollo/client";
import { GET_SUBCONTRACTOR_CURSOR } from "graphql/query/subcontractor-gql";
import Icons from "components/icons";
import MultiSelect from 'components/layout/form-elements/multi-select';
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";

import './subcontractor-select.scss';


// const { Option } = Select;


const SubcontractorSelect = ({
    form,
    disabled = false,
    rules = false,
    mode = '',
    initialValue,
    defaultValue,
    name = 'subcontractor_id',
    model = "subcontractorsCursor",
    label = Localize({ children: "FORMS.Input_Label_Subcontractor" }).props.children
}) => {

    const subcontractorId = Form.useWatch([ name ], form);

    const [ searchSubcontractor, setSearchSubcontractor ] = useState("");

    let { data, loading, fetchMore } = useQuery(GET_SUBCONTRACTOR_CURSOR, {
        variables: {
            text: useDebounce(searchSubcontractor),
            first: 25,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    let {
        // onPopupScroll,
        fetchMoreAction,
        nodes,
        pageInfo
    } =
        cursorPagination({
            model,
            data,
            loading,
            fetchMore
        });


    nodes = [ { value: null, label: "Not assigned" }, ...nodes ];


    return (
        <Form.Item
            initialValue={ initialValue }
            name={ name }
            label={ label }
            rules={ rules && [ { required: true, message: `${ Localize({ children: "FORM_RULES.Required_Choose" }).props.children }` } ] }
            className="multi-select-subcontractor"
        >
            {/* <Select
                mode={ mode }
                disabled={ disabled }
                suffixIcon={ <Icons.Arrow /> }
                placeholder={ `${ Localize({ children: "FORMS.Input_Placeholder_Choose" }).props.children }` }
                onChange={ value => form.setFieldsValue({ [ name ]: value }) }
                defaultValue={ defaultValue?.label }
                value={ subcontractorId }
                onPopupScroll={ onPopupScroll }>

                <Option key="default" value={ null }>
                    <span>Not assigned</span>
                </Option>

                { nodes?.map(({ label, value }) => (
                    <Option key={ value } value={ value }>
                        { label }
                    </Option>
                )) }

            </Select> */}

            <ModalStandard
                width={ 380 }
                extraClass={ 'modal-form' }
                modalButton={
                    <div className="d-flex align-items-center w-100">
                        <span className="select-model-modal-button w-100">
                            { subcontractorId ?
                                nodes?.filter(({ value }) => value === subcontractorId)[ 0 ]?.label || initialValue?.label || defaultValue?.label : "Not assigned" }
                            { <Icons.Arrow className='arrow-down' /> }
                        </span>
                        <strong className="btn-text"><Localize>MODAL.Button_Text_Choose</Localize></strong>
                    </div>
                }>
                <strong className="modal-title"><Localize>FORMS.Title_ChooseSubcontractor</Localize> </strong>

                <MultiSelect
                    setSearch={ setSearchSubcontractor }
                    placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Subcontractor" }).props.children }
                >

                    { searchSubcontractor && loading ? <Skeleton active={ true } paragraph={ { rows: 6 } } /> :
                        <Radio.Group
                            className='group-radio radio-dark'
                            options={ nodes }
                            defaultValue={ defaultValue?.value }
                            value={ subcontractorId }
                            onChange={ e => form.setFieldsValue({ [ name ]: e.target.value }) }
                        />
                    }


                    { pageInfo?.hasNextPage &&

                        <>
                            { loading && <Loader style={ { marginTop: '-30px' } } /> }
                            <Waypoint onEnter={ () => fetchMoreAction() } />
                        </>
                    }

                </MultiSelect>
            </ModalStandard>
        </Form.Item>
    )
};

export default SubcontractorSelect;