import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";

import { HALL_DELETE } from "graphql/mutation/hall-gql";

import HallConst from '../hall-const';

import Events from 'components/events';

import { successNotification, errorNotification } from "components/request-result";



const useMutationHallDelete = (hallID, eventID) => {

  const history = useHistory();

  const [ _setMutationHallDelete, { loading } ] = useMutation(HALL_DELETE,
    {
      update(cache, { data }) {

        const {
          hallDelete: {
            label,
            message
          }
        } = data;

        history.push(`${ Events.Const.basePath }/${ eventID }${ HallConst.basePath }`);

        cache.evict({
          id: cache.identify({ id: hallID, __typename: "Hall" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationHallDelete,
    loadingMutationHallDelete: loading,
  }
};

export default useMutationHallDelete;