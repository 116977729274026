import React from "react";
import Subcontractor from 'components/subcontractor';
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const basePath = Subcontractor.Const.basePath;

const SubcontractorOverViewSubPage = ({ subcontractor }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Subcontractors" }).props.children,
            path: `${ basePath }`
        },
        {
            label: subcontractor?.title,
            path: `${ basePath }/${ subcontractor?.id }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-lg-2">
            <div className="col-left">
                <Subcontractor.Forms.Edit subcontractor={ subcontractor } />
            </div>
        </div>
    );
};

export default SubcontractorOverViewSubPage;