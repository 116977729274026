import FurnishingActionField from "./furnishing-action-field";
import FurnishingTypeField from "./furnishing-title-field";
import FurnishingImageField from "./furnishing-image-field";


const FurnishingFields = {
    Action: FurnishingActionField,
    Title: FurnishingTypeField,
    Image: FurnishingImageField,
}


export default FurnishingFields;