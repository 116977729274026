import { Link } from 'react-router-dom';
import Events from 'components/events';


const StandLinkField = ({ eeID, id, title }) => {

    return (
        <Link to={ {
            pathname: `${ Events.Const.basePath }/${ eeID }/halls/${ id }`,
            state: {
                breadcrumbSlug: {
                    id,
                    label: title
                }
            }
        } }
        >
            { title }
        </Link>
    );
};

export default StandLinkField;
