import { Localize } from "components/service";


const craftBasePath = '/crafts';

const craftTypes = [
   {
      "title": <Localize num={ '' }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Floor</Localize>,
      "type": "floor",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Floor</Localize>,
      "type": "floor2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Floor</Localize>,
      "type": "floor3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning3",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Additional</Localize>,
      "type": "additional",
   }
   ,
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Additional</Localize>,
      "type": "additional2",
   }
   ,
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Additional</Localize>,
      "type": "additional3",
   }
   ,
   {
      "title": <Localize>CRAFTS.label_MpControl</Localize>,
      "type": "mp_control",
   }
   ,
   {
      "title": <Localize>CRAFTS.label_Protocol</Localize>,
      "type": "protocol",
   },
   {
      "title": <Localize num={ '' }>CRAFTS.label_Service</Localize>,
      "type": "service",
   },
   {
      "title": <Localize num={ 2 }>CRAFTS.label_Service</Localize>,
      "type": "service2",
   },
   {
      "title": <Localize num={ 3 }>CRAFTS.label_Service</Localize>,
      "type": "service3",
   }
];

const craftTypesFilter = [
   {
     "title": <Localize num={ '' }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Logistics</Localize>,
      "type": "logistics_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Floor</Localize>,
      "type": "floor",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Floor</Localize>,
      "type": "floor_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Floor</Localize>,
      "type": "floor_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Assembly</Localize>,
      "type": "assembly_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Electrics</Localize>,
      "type": "electrics_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Graphic</Localize>,
      "type": "graphic_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Furniture</Localize>,
      "type": "furniture_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Multimedia</Localize>,
      "type": "multimedia_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Cleaning</Localize>,
      "type": "cleaning_3",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Additional</Localize>,
      "type": "additional",
   }
   ,
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Additional</Localize>,
      "type": "additional_2",
   }
   ,
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Additional</Localize>,
      "type": "additional_3",
   }
   ,
   {
     "title": <Localize>CRAFTS.label_MpControl</Localize>,
      "type": "mp_control",
   }
   ,
   {
     "title": <Localize>CRAFTS.label_Protocol</Localize>,
      "type": "protocol",
   },
   {
     "title": <Localize num={ '' }>CRAFTS.label_Service</Localize>,
      "type": "service",
   },
   {
     "title": <Localize num={ 2 }>CRAFTS.label_Service</Localize>,
      "type": "service_2",
   },
   {
     "title": <Localize num={ 3 }>CRAFTS.label_Service</Localize>,
      "type": "service_3",
   }
];



const CraftConst = {
   basePath: craftBasePath,
   craftTypes,
   craftTypesFilter,
};

export default CraftConst;









