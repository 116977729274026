import React from "react";
import Hall from "components/hall";
import Event from "components/events";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const basePath = Hall.Const.basePath;

const HallOverViewSubPage = ({ hall }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ Event.Const.basePath }`
        },
        {
            label: hall?.exhibitionEvent?.title,
            path: `${ Event.Const.basePath }/${ hall?.ee_id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Halls" }).props.children,
            path: `${ Event.Const.basePath }/${ hall?.ee_id }${ basePath }`
        },
        {
            label: `${ hall?.title }`,
            path: `${ Event.Const.basePath }/${ hall?.ee_id }${ basePath }/${ hall?.id }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <div className="row-grid column-gap-60 col-lg-2">
            <div className="col-left">
                <Hall.Forms.Edit hall={ hall } />
            </div>
            <div className="col-right">
                <Hall.Actions.UploadDownload hall={ hall } />
            </div>
        </div>
    );
};

export default HallOverViewSubPage;