import React from "react";
import { Link } from 'react-router-dom';
import User from 'components/users';

const StandUserInfoField = ({ userInfo: { id, userFullName } }) => {

    return (
        <Link
            to={ {
                pathname: `${ User.Const.basePath }/${ id }`,
                state: {
                    breadcrumbSlug: {
                        id,
                        label: userFullName
                    }
                }
            } }
        >{ userFullName }</Link>
    );
};

export default StandUserInfoField;