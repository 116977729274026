import { Link } from 'react-router-dom';
import Events from 'components/events';


const StandTitleField = ({ eeID, id, title }) => {


    return (

        <Link to={ {
            pathname: `${ Events.Const.basePath }/${ eeID }/stands/${ id }`,
            state: {
                breadcrumbSlug: {
                    id,
                    label: title
                }
            }
        } }
        >
            { title }
        </Link>
    );
};

export default StandTitleField;
