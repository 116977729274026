import React from "react";
import { useQuery } from "@apollo/client";

import { GET_HALL } from "graphql/query/hall-gql";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout";
import HallRoute from 'components/routes/halls/hall-route';
import { Localize } from "components/service";


const HallSinglePage = ({ match }) => {

    useActiveMenuItem([ "exhibitions" ], [ "exhibitions" ]);

    const hallID = match ? parseInt(match.params.hallID) : null;

    const { data: { hall } = {}, loading } = useQuery(GET_HALL, {
        variables: {
            id: hallID
        }
    });

    const hallExist = !loading && hall && hall.ee_id === match.params.eeID;

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: "overview"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Stands" }).props.children,
            path: "stands"
        } ];

    return (
        <PageWrapDefault
            className="page-hall"
            loading={ loading }
            title={ hallExist ? hall.title : "Page 404" }
            dataExist={ hallExist }
            pageNavbar={ hallExist ? pageNavbar : false }
            staticPath={ `${ match.url }` }
        >

            <HallRoute hall={ hall } />

        </PageWrapDefault>
    );
};

export default HallSinglePage;