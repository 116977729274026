import { useMutation } from "@apollo/client";
import { EVENT_ATTCHMENT_UPDATE, EVENT_ATTCHMENT_CREATE } from "graphql/mutation/event-gql";
import { GET_EVENT_ATTACHMENTS } from "graphql/query/event-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationEventAttachmentCreateUpdate = ({ attachmentID, variables }) => {

    const EVENT_ATTCHMENT_CREATE_UPDATE = attachmentID ? EVENT_ATTCHMENT_UPDATE : EVENT_ATTCHMENT_CREATE;
    const attachmentCreateUpdate = attachmentID ? 'attachmentUpdate' : 'attachmentCreate';

    const [ _setAttachmentCreateUpdate, { loading, data } ] = useMutation(EVENT_ATTCHMENT_CREATE_UPDATE,
        {
            update(cache, { data }) {

                const {
                    [ attachmentCreateUpdate ]: {
                        label,
                        message,


                    }
                } = data;


                if (attachmentID)
                {
                    successNotification({
                        title: label,
                        description: message
                    })
                }
            },

            onError(error) {
                errorNotification(error);
            },


            refetchQueries: [
                {
                    query: GET_EVENT_ATTACHMENTS,
                    variables
                },
            ]
        }
    );



    return {
        _setAttachmentCreateUpdate,
        loadingMutationAttachmentCreateUpdate: loading,
        dataMutationAttachmentCreateUpdate: data
    }
};

export default useMutationEventAttachmentCreateUpdate;

