const deutsch = {
    'USER.Button_Text_Create': 'Benutzer erstellen',
    'USER.Button_Text_Delete': 'Benutzer löschen',
    'GROUP.Button_Text_Create': 'Gruppe erstellen',
    'GROUP.Button_Text_Delete': 'Gruppe löschen',
    'SUBCONTRACTOR.Button_Text_Create': 'Subunternehmer erstellen',
    'SUBCONTRACTOR.Button_Text_Delete': 'Subunternehmer löschen',
    'SUBCONTRACTOR.Button_Text_Import': 'Subunternehmer importieren',
    'STAND.Button_Text_Create': 'Stand erstellen',
    'STAND.Button_Text_Delete': 'Stand löschen',
    'EXHIBITION.Button_Text_Create': 'Veranstaltung erstellen',
    'EXHIBITION.Button_Text_Delete': 'Veranstaltung löschen',
    'EXHIBITION.Button_Text_Import': 'Veranstaltung importieren',
    'JOB.Button_Text_Create': 'Job erstellen',
    'JOB.Button_Text_Add': 'Job hinzufügen',
    'JOB.Type_Text_Claim': 'MP Reklamation',
    'JOB.Type_Text_CustomerClaim': 'Kunden Reklamation',
    'JOB.Type_Text_LastMinute': 'Lastminute',
    'JOB.Type_Text_Damage': 'Schaden',
    'JOB.Type_Text_ProtocolDefect': 'Mangel',
    'JOB.Status_Text_Open': 'Offen',
    'JOB.Status_Text_InProgress': 'In Bearbeitung',
    'JOB.Status_Text_Completed': 'Fertiggestellt',
    'HALL.Button_Text_Create': 'Halle erstellen',
    'HALL.Button_Text_Delete': 'Halle löschen',
    'FURNISHING.Button_Text_AddFurnishing': 'Ausstattung hinzufügen',
    'FURNISHING.Button_Text_FurnishingImport': 'AUSSTATTUNG IMPORT',
    'CRAFT.Button_Text_AddCraft': 'Gewerk hinzufügen',
    'CRAFT.Button_Text_CreateCraft': 'Gewerk anlegen',
    'TABLES.Column_Title_Name': 'Name',
    'TABLES.Column_Title_Phone': 'Telefon',
    'TABLES.Column_Title_Email': 'E-Mail',
    'TABLES.Column_Title_Company': 'Firma',
    'TABLES.Column_Title_UserGroup': 'Benutzergruppe',
    'TABLES.Column_Title_User': 'Benutzer',
    'TABLES.Column_Title_Exhibition': 'Veranstaltung',
    'TABLES.Column_Title_Time': 'Zeit',
    'TABLES.Column_Title_Hall': 'Halle',
    'TABLES.Column_Title_Halls': 'Hallen',
    'TABLES.Column_Title_DamageFilesCount': 'Schadensdateien',
    'TABLES.Column_Title_StandNumber': 'Stand-Nr.',
    'TABLES.Column_Title_Craft': 'Gewerk',
    'TABLES.Column_Title_Crafts': 'Gewerke',
    'TABLES.Column_Title_JobNumber': 'Job-Nr.',
    'TABLES.Column_Title_Jobs': 'Jobs',
    'TABLES.Column_Title_JobTableNumber': 'Nummer',
    'TABLES.Column_Title_CadNumber': 'CAD-Nr.',
    'TABLES.Column_Title_JobType': 'Jobtyp',
    'TABLES.Column_Title_Type': 'Typ',
    'TABLES.Column_Title_Message': 'Mitteilung',
    'TABLES.Column_Title_Label': 'User Rolle',
    'TABLES.Column_Title_GroupRights': 'Gruppenrechte',
    'TABLES.Column_Title_NumberUsers': 'User Anzahl',
    'TABLES.Column_Title_NumberStands': 'Anzahl Stände',
    'TABLES.Column_Title_Stands': 'Stände',
    'TABLES.Column_Title_Title': 'Name',
    'TABLES.Column_Title_HallNumber': 'Hallennummer',
    'TABLES.Column_Title_Number': 'Kreditornummer',
    'TABLES.Column_Title_Exhibitor': 'Aussteller',
    'TABLES.Column_Title_AcquiseNumber': 'AKQ',
    'TABLES.Column_Title_ProaufNumber': 'PROAUF',
    'TABLES.Column_Title_HallName': 'Halle',
    'TABLES.Column_Title_Corridor': 'Gang',
    'TABLES.Column_Title_Block': 'Block',
    'TABLES.Column_Title_DeliveryTime': 'Deadline',
    'TABLES.Column_Title_Materials': 'Anhänge',
    'TABLES.Column_Title_Status': 'Status',
    'TABLES.Column_Title_Description': 'Beschreibung',
    'TABLES.Column_Title_Created': 'Erstellt',
    'TABLES.Column_Title_InProgress': 'in Bearbeitung',
    'TABLES.Column_Title_Completed': 'Fertiggestellt',
    'TABLES.Column_Title_Attachments': 'Anhänge',
    'TABLES.Column_Title_Acqusition': 'AKQ Nr.',
    'TABLES.Column_Title_HallPlan': 'Hallenplan',
    'TABLES.Column_Title_ConstractionStarts': 'Aufbaubeginn',
    'TABLES.Column_Title_ConstractionEnds': 'Aufbauende',
    'TABLES.Column_Title_EventStarts': 'Veranstaltungsbeginn',
    'TABLES.Column_Title_ElfeOnline': 'ELFE online Status',
    'TABLES.Column_Title_TimeCompletion': 'Deadline',
    'TABLES.Column_Title_Subcontractor': 'Subunternehmer',
    'TABLES.Column_Title_ID': 'ID',
    'TABLES.Column_Sorting_Tooltip_Ascending': 'Aufsteigend sortieren',
    'TABLES.Column_Sorting_Tooltip_Descending': 'Absteigend sortieren',
    'TABLES.Column_Sorting_Tooltip_Cancel': 'Sortierung abbrechen',
    'TABLES.Column_Title_FileName': 'Dateiname',
    'TABLES.Column_Title_FurnishingDescription': 'Artikelbezeichnung',
    'TABLES.Column_Title_Sku': 'Artikelnummer',
    'TABLES.Column_Title_Image': 'Foto',
    'TABLES.Column_Title_LastMinutePrice': 'Last Minute Preis',
    'TABLES.Column_Title_Quantity': 'verfügbare Menge',
    'FORM_RULES.Min_NewPassword': 'Ihr Passwort muss mindestens { minLength } Zeichen enthalten.',
    'FORM_RULES.Required_NewPassword': 'Neues Passwort eingeben.',
    'FORM_RULES.Required_ConfirmPassword': 'Neues Passwort bestätigen.',
    'FORM_RULES.Required_ConfirmPasswordMatch': 'Die eingegebenen Passwörter stimmen nicht überein.',
    'FORM_RULES.Required_Name': 'Vornamen eingeben.',
    'FORM_RULES.Required_Surname': 'Nachnamen eingeben.',
    'FORM_RULES.Required_Email': 'Die eingegebene E-Mail-Adresse ist ungültig.',
    'FORM_RULES.Required_Choose': 'Bitte auswählen',
    'FORM_RULES.Required_Phone': 'Telefonnummer eingeben',
    'FORM_RULES.Required_GroupName': 'Gruppennamen eingeben',
    'FORM_RULES.Required_Title': 'Titel eingeben',
    'FORM_RULES.Required_Number': 'Nummer eingeben',
    'FORM_RULES.Required_Where': 'Bitte auswählen',
    'FORM_RULES.Required_DeliveryTime': 'Deadline festlegen',
    'FORM_RULES.Required_Exhibitor': 'Austellernamen eingeben',
    'FORM_RULES.Required_ProafNumber': 'PROAUF eingeben',
    'FORM_RULES.Required_StandNumber': 'Standnummer eingeben',
    'FORM_RULES.Required_CadNumber': 'Job fertiggestellt',
    'FORM_RULES.Required_KeyName': 'Schlüsselnamen eingeben',
    'FORM_RULES.Required_KeyNumber': 'Schlüsselnummer eingeben',
    'FORM_RULES.Required_HallName': 'Bitte eingeben',
    'FORM_RULES.Required_HallNumber': 'Bitte eingeben',
    'FORM_RULES.Required_Password': 'Passwort eingeben',
    'FORMS.Input_Label_FurnishingDescr': 'Artikelbezeichnung',
    'FORMS.Input_Label_Sku': 'Artikelnummer',
    'FORMS.Input_Label_LastMinutePrice': 'Last Minute Preis',
    'FORMS.Input_Label_LastMinuteQuantity': 'Menge',
    'FORMS.Input_Label_Password': 'Passwort',
    'FORMS.Input_Label_Login': 'Login',
    'FORMS.Input_Label_NewPassword': 'Neues Passwort',
    'FORMS.Input_Label_ConfirmPassword': 'Neues Passwort bestätigen',
    'FORMS.Input_Label_Language': 'Sprache',
    'FORMS.Input_Label_Craft': 'Gewerk',
    'FORMS.Input_Label_CraftType': 'Gewerketyp',
    'FORMS.Input_Label_CraftTitle': 'Gewerkename',
    'FORMS.Input_Label_TimeOfCompletion': 'Deadline',
    'FORMS.Input_Label_Name': 'Vorname',
    'FORMS.Input_Label_Surname': 'Nachname',
    'FORMS.Input_Label_Phone': 'Telefonnummer (Login)',
    'FORMS.Input_Label_Email': 'E-Mail',
    'FORMS.Input_Label_UsersGroup': 'Usergruppe',
    'FORMS.Input_Label_GroupName': 'Gruppenname',
    'FORMS.Input_Label_GroupColor': 'Gruppenfarbe',
    'FORMS.Input_Label_CrmRights': 'CRM Userrechte',
    'FORMS.Input_Label_Attachment': 'Anhang',
    'FORMS.Input_Label_HallPlan': 'Hallenplan',
    'FORMS.Input_Label_AppRights': 'ELFE App Userrechte',
    'FORMS.Input_Label_Subcontractor': 'Subunternehmer',
    'FORMS.Input_Label_AssignedExhibitions': 'Zugewiesene Veranstaltungen',
    'FORMS.Input_Label_Title': 'Name',
    'FORMS.Input_Label_Number': 'Kreditornummer',
    'FORMS.Input_Label_Where': 'Ort',
    'FORMS.Input_Label_Hall': 'Halle',
    'FORMS.Input_Label_NewDeliveryTime': 'Neue Deadline',
    'FORMS.Input_Label_Exhibitor': 'Ausstellername',
    'FORMS.Input_Label_AcqstnNumber': 'AKQ-Nr.',
    'FORMS.Input_Label_ProaufNumber': 'PROAUF-Nr.',
    'FORMS.Input_Label_Proauf': 'PROAUF',
    'FORMS.Input_Label_Block': 'Block',
    'FORMS.Input_Label_Corridor': 'Gang',
    'FORMS.Input_Label_Stand': 'Stand',
    'FORMS.Input_Label_StandNum': 'Stand-Nr.',
    'FORMS.Input_Label_StandNumber': 'Standnummer',
    'FORMS.Input_Label_CadNumber': 'CAD-Nummer',
    'FORMS.Input_Label_Time': 'Zeit',
    'FORMS.Input_Label_DeliveryTime': 'Deadline',
    'FORMS.Input_Label_Acquisition': 'Akquisition',
    'FORMS.Input_Label_KeyName': 'Schlüssel für',
    'FORMS.Input_Label_Key': 'Schlüsselnummer',
    'FORMS.Input_Label_JobType': 'Jobtyp',
    'FORMS.Input_Label_JobStatus': 'Jobstatus',
    'FORMS.Input_Label_JobNum': 'Job-Nr.',
    'FORMS.Input_Label_Exhibition': 'Veranstaltung',
    'FORMS.Input_Label_ExhibitionName': 'Veranstaltungsname',
    'FORMS.Input_Label_ExhibitionStarts': 'Veranstaltungsbeginn',
    'FORMS.Input_Label_ExhibitionEnds': 'Veranstaltungsende',
    'FORMS.Input_Label_ElfeOnline': 'Veranstaltung ist Online',
    'FORMS.Input_Label_ConstructionStarts': 'Aufbaubeginn',
    'FORMS.Input_Label_ConstructionEnds': 'Aufbauende',
    'FORMS.Input_Label_ExhibitionLink': 'Veranstaltungslink',
    'FORMS.Input_Label_Description': 'Beschreibung',
    'FORMS.Input_Label_ChooseType': 'Typ wählen',
    'FORMS.Input_Label_Status': 'Status',
    'FORMS.Input_Label_ChooseStatus': 'Status wählen',
    'FORMS.Input_Label_HallName': 'Hallenbezeichnung',
    'FORMS.Input_Label_HallNumber': 'Hallennummer',
    'FORMS.Input_Label_HallToMoveStandsTo': 'Stände in Halle verschieben',
    'FORMS.Input_Label_Company': 'Firma',
    'FORMS.Input_Label_NewFinishingTime': 'Neue Deadline festlegen',
    'FORMS.Title_ChangePassword': 'Passwort ändern',
    'FORMS.Title_SendPassword': 'Passwort versenden',
    'FORMS.Title_AddUsers': 'Benutzer hinzufügen',
    'FORMS.Title_CopyFromEvent': 'Aus Veranstaltung kopieren',
    'MODAL.Button_Text_CopyFromEvent': 'Aus veranstaltung kopieren',
    'FORMS.Title_AddSubcontractor': 'Subunternehmer hinzufügen',
    'FORMS.Title_AssignSubcontractors': 'Subunternehmer zuweisen',
    'FORMS.Title_ChooseSubcontractor': 'Subunternehmer auswählen',
    'FORMS.Title_ChooseType': 'Wähle den Typ',
    'FORMS.Title_AddExhibitions': 'Veranstaltung hinzufügen',
    'FORMS.Title_DeliveryTime': 'Deadline ändern',
    'FORMS.Title_CraftTime': 'Gewerke Deadline ändern',
    'FORMS.Title_CraftDone': 'Gewerk fertiggestellt',
    'FORMS.Title_CraftInProcess': 'Gewerk in Bearbeitung',
    'FORMS.Title_CraftAdditional': 'Zusätzliches Gewerk erstellen',
    'FORMS.Title_CraftDelete': 'Gewerk löschen',
    'FORMS.Title_CreateLastMinJob': 'Last-Minute-Job erstellen',
    'FORMS.Title_DeleteHall': 'Halle löschen',
    'FORMS.Title_EditJob': 'Auftrag bearbeiten',
    'FORMS.Title_EditFurnishing': 'LH Ausstattung bearbeiten',
    'FORMS.Title_AddFurnishing': 'Ausstattung hinzufügen',
    'FORMS.Title_EditCraft': 'Gewerk bearbeiten',
    'FORMS.Title_CreateCraft': 'Gewerk erstellen',
    'FORMS.Title_Text_Filters': 'Filter',
    'FORMS.Title_Text_CreateJob': 'Job erstellen',
    'FORMS.Title_AddFile': 'Datei hinzuzufügen',
    'FORMS.Title_EditFile': 'Datei bearbeiten',
    'FORMS.Select_OptionLabel_English': 'Englisch',
    'FORMS.Select_OptionLabel_Deutsch': 'Deutsch',
    'FORMS.Select_OptionLabel_ChooseColor': 'Farbe wählen',
    'FORMS.Select_OptionLabel_SelectedStands': 'in den ausgewählten Ständen ({ length })',
    'FORMS.Select_OptionLabel_AllStandsHall': 'Alle Stände der Halle',
    'FORMS.Select_OptionLabel_AllStandsExhibition': 'Alle Stände der Veranstaltung',
    'FORMS.Select_OptionLabel_HallNotSelected': 'Halle nicht ausgewählt',
    'FORMS.Select_OptionLabel_Completed': 'Fertiggestellt',
    'FORMS.Select_OptionLabel_InProgress': 'in Bearbeitung',
    'FORMS.Radio_Label_Company': 'Unternehmen',
    'FORMS.Radio_Label_Subcontractor': 'Subunternehmer',
    'FORMS.Radio_Label_ChoosenCraft': 'Gewähltes Gewerk',
    'FORMS.Radio_Label_AllCrafts': 'Alle Gewerke',
    'FORMS.Checkbox_Label_JobCompleted': 'Job fertiggestellt',
    'FORMS.Checkbox_Label_CraftCompleted': 'Gewerk fertiggestellt',
    'FORMS.Input_Placeholder_Email': 'E-Mail',
    'FORMS.Input_Placeholder_Password': 'Passwort',
    'FORMS.Input_Placeholder_Choose': 'Bitte auswählen',
    'FORMS.Input_Placeholder_GroupName': 'Gruppennamen eingeben',
    'FORMS.Input_Placeholder_ChooseColor': 'Farbe wählen',
    'FORMS.Input_Placeholder_Where': 'Bitte auswählen',
    'FORMS.Input_Placeholder_ChooseHall': 'Halle auswählen',
    'FORMS.Input_Placeholder_ChooseExhibition': 'Veranstaltung auswählen',
    'FORMS.Input_Placeholder_ChooseStand': 'Stand auswählen',
    'FORMS.Input_Placeholder_DeliveryTime': 'Deadline festlegen',
    'FORMS.Input_Placeholder_AcqstnNumber': 'AKQ eingeben',
    'FORMS.Input_Placeholder_ProaufNumber': 'PROAUF eingeben',
    'FORMS.Input_Placeholder_CorridorNumber': 'Gangnummer',
    'FORMS.Input_Placeholder_BlockNumber': 'Blocknummer',
    'FORMS.Input_Placeholder_StandNumber': 'Standnummer',
    'FORMS.Input_Placeholder_CadNumber': 'CAD-Nummer',
    'FORMS.Input_Placeholder_ShortDescription': 'Kurzbeschreibung eingeben',
    'FORMS.Input_Placeholder_ChooseStatus': 'Status wählen',
    'FORMS.Input_Placeholder_ChooseType': 'Typ wählen',
    'FORMS.Input_Placeholder_ChooseCraftType': 'Gewerketyp wählen',
    'FORMS.Input_Placeholder_ExhibitionName': 'Veranstaltungsnamen hinzufügen',
    'FORMS.Input_Placeholder_HallName': 'Hallennamen hinzufügen',
    'FORMS.Input_Placeholder_CraftCompletion': 'Bitte Deadline auswählen',
    'FORMS.Input_Placeholder_SelectDate': 'Bitte auswählen',
    'FORMS.Input_Placeholder_StartDate': 'Anfangsdatum',
    'FORMS.Input_Placeholder_EndDate': 'Endtermin',
    'FORMS.Input_Placeholder_Company': 'Firma',
    'SEARCH.Input_Placeholder_User': 'Benutzer suchen',
    'SEARCH.Input_Placeholder_Table_User': 'Namen, E-Mail, Telefonnummer suchen',
    'SEARCH.Input_Placeholder_Subcontractor': 'Subunternehmer suchen',
    'SEARCH.Input_Placeholder_Table_Subcontractor': 'ID, Titel, Nummer suchen',
    'SEARCH.Input_Placeholder_Exhibition': 'Veranstaltungen suchen',
    'SEARCH.Input_Placeholder_Stand': 'Nach Stand suchen',
    'SEARCH.Input_Placeholder_Table_Stand': 'Stand suchen',
    'SEARCH.Input_Placeholder_Table_SearchKeyWords': 'Keywords suchen',
    'SEARCH.Input_Placeholder_MultiSelect': 'Suchen',
    'ACTIONS.List_Item_Label_StandPlan': 'Standplan',
    'ACTIONS.List_Item_Label_Graphic': 'Grafik',
    'ACTIONS.List_Item_Label_Photo': 'Standfoto',
    'ACTIONS.List_Item_Label_Info': 'Info',
    'ACTIONS.List_Item_Label_HallPlan': 'Hallenplan',
    'ACTIONS.List_Item_Label_Image': 'foto',
    'GLOBAL.Button_Text_Save': 'Speichern',
    'GLOBAL.Button_Text_Send': 'Senden',
    'GLOBAL.Button_Text_SaveSend': 'Speichern und Senden',
    'GLOBAL.Button_Text_SaveChanges': 'Änderungen speichern',
    'GLOBAL.Button_Text_UserToGroup': 'Benutzer zur Gruppe hinzufügen',
    'GLOBAL.Button_Text_Update': 'Update',
    'GLOBAL.Button_Text_Reimport': 'Erneut importieren',
    'GLOBAL.Button_Text_Cancel': 'Abbrechen',
    'GLOBAL.Button_Text_Delete': 'Löschen',
    'GLOBAL.Button_Text_Remove': 'Entfernen',
    'GLOBAL.Button_Text_Confirm': 'Bestätigen',
    'GLOBAL.Button_Text_AddFile': 'Datei hinzuzufügen',
    'GLOBAL.Button_Text_UploadFile': 'Datei hochladen',
    'GLOBAL.Button_Text_UserGroup': 'Usergruppe',
    'GLOBAL.Button_Text_Login': 'Login',
    'GLOBAL.Button_Text_BackHome': 'Zurück zur Startseite',
    'GLOBAL.Modal_Text_UserSendPass': 'Neues Passwort wirklich versenden?',
    'GLOBAL.Modal_Text_RemoveText': '{ deleteObjectType } wirklich entfernen?',
    'GLOBAL.Modal_Text_DeleteText': '{ deleteObjectType } wirklich löschen?',
    'GLOBAL.Modal_Text_RemoveFrom': 'aus',
    'GLOBAL.Modal_Text_DeleteHallStands': 'Du löschst eine Halle, welche noch Stände enthält. Bitte weise Diesen zuvor eine andere Halle zu.',
    'GLOBAL.Modal_Text_DeleteHall': 'Halle wirklich löschen?',
    'GLOBAL.Modal_Text_ResetFilters': 'Filter löschen',
    'GLOBAL.Button_Text_ExportCsv': 'exportieren',
    'GLOBAL.Button_Text_SubcontractorsFile': 'Die Subunternehmer-Datei wird importiert',
    'GLOBAL.Button_Text_DownloadReport': 'Bericht downloaden',
    'GLOBAL.Button_Text_Actions': 'Aktionen',
    'GLOBAL.Link_Text_Download': 'Download',
    'GLOBAL.Link_Text_Unassigned': 'nicht zugeordnet',
    'GLOBAL.Link_Text_Assigned': 'Zugeordnet',
    'GLOBAL.Text_NoData': 'Keine Einträge',
    'GLOBAL.Text_Status_Disabled': 'Gesperrt',
    'GLOBAL.Text_Status_InProgress': 'In Bearbeitung',
    'GLOBAL.Text_Status_Completed': 'Fertiggestellt',
    'GLOBAL.Text_Status_Overdue': 'Überfällig',
    'MODAL.Button_Text_Choose': 'Suchen',
    'GLOBAL.Text_Status_ChangePassUser': 'Passwort gesendet von { user } um { dateTime }',
    'MODAL.Button_Text_AddExhibition': 'Veranstaltung hinzufügen',
    'MODAL.Button_Text_Stands': 'Stände',
    'MODAL.Button_Text_Crafts': 'Gewerke',
    'MODAL.Button_Text_DeliveryTime': 'Deadline ändern',
    'MODAL.Button_Text_CraftTime': 'Deadline ändern',
    'MODAL.Button_Text_AssignSubcontractors': 'Subunternehmer zuweisen',
    'MODAL.Button_Text_CraftDone': 'Gewerk fertiggestellt',
    'MODAL.Button_Text_CraftInProcess': 'Gewerk in Bearbeitung',
    'MODAL.Button_Text_CraftAdditional': 'Zusätzliches Gewerk erstellen',
    'MODAL.Button_Text_CraftDelete': 'Gewerk löschen',
    'MODAL.Button_Text_JobCreate': 'Auftrag erstellen',
    'MODAL.Button_Text_AddUserEvent': 'Benutzer zu Veranstaltung hinzufügen',
    'FORMS.Button_Text_ApplyFilters': 'Filter anwenden',
    'FORMS.Button_Text_ResetFilters': 'Filter löschen',
    'FORMS.Button_Text_AddKey': 'Neuen Schlüssel hinzufügen',
    'ENTITY.Modal_Title_User': 'Benutzer',
    'ENTITY.Modal_Title_Group': 'Gruppe',
    'ENTITY.Modal_Title_ExhibitionStand': 'Messestand',
    'ENTITY.Modal_Title_ExhibitionEvent': 'Veranstaltung',
    'ENTITY.Modal_Title_Exhibition': 'Veranstaltung',
    'ENTITY.Modal_Title_Subcontractor': 'Subunternehmer',
    'ENTITY.Modal_Title_StandKey': 'Standschlüssel',
    'ENTITY.Modal_Title_Stand': 'Stand',
    'ENTITY.Modal_Title_Craft': 'Gewerk',
    'ENTITY.Modal_Title_Job': 'Auftrag',
    'ENTITY.Modal_Title_Furnishing': 'Ausstattung',
    'ENTITY.Modal_Title_Document': 'dokumentieren',
    'ENTITY.Modal_Title_Downloading': 'Warten Sie mit dem Herunterladen der Datei...',
    'ENTITY.Modal_Title_Importing': 'Die Ereignisdatei wird importiert...',
    'ENTITY.Modal_Title_Imported': 'Die Termindatei wurde importiert:',
    'MENUS.Label_Exhibitions': 'Veranstaltungen',
    'MENUS.Label_Users': 'Benutzer',
    'MENUS.Label_Subcontractors': 'Subunternehmer',
    'MENUS.Label_LastMin': 'Last Minute',
    'MENUS_DROP.Label_MyProfile': 'Mein Userprofil',
    'MENUS_DROP.Label_Logout': 'Logout',
    'MENUS_DROP.Label_Edit': 'Bearbeiten',
    'MENUS_DROP.Label_ViewLogs': 'Logs öffnen',
    'MENUS_DROP.Label_ViewKeys': 'Schlüssel Nr.',
    'MENUS_DROP.Label_SendPass': 'Passwort versenden',
    'MENUS_DROP.Label_Delete': 'Löschen',
    'MENUS_DROP.Label_Mark': 'Markieren',
    'MENUS_DROP.Label_InProgress': 'In Bearbeitung',
    'MENUS_DROP.Label_Open': 'Offen',
    'MENUS_DROP.Label_Done': 'Fertiggestellt',
    'MENUS_DROP.Label_Make': 'Machen',
    'MENUS_DROP.Label_Online': 'Online',
    'MENUS_DROP.Label_Offline': 'Offline',
    'MENUS_DROP.Label_UploadHallPlan': 'Hallenplan hochladen',
    'MENUS_DROP.Label_LastMinReport': 'Bericht "Last Minute"',
    'MENUS_DROP.Label_LastMinOrders': 'Last Minute Aufträge',
    'MENUS_DROP.Label_ComplainsReport': 'Bericht "MP Reklamation"',
    'MENUS_DROP.Label_ExhibitorReport': 'Bericht "Aussteller Reklamation"',
    'MENUS_DROP.Label_DamageReport': 'Bericht "kontrollierte Schäden"',
    'MENUS_DROP.Label_DamageReportAll': 'Bericht "alle Schäden"',
    'MENUS_DROP.Label_ElfeOnline': 'ELFE online setzen',
    'MENUS_DROP.Label_ElfeOffline': 'ELFE offline setzen',
    'MENUS_DROP.Label_StandsQrCode': 'Stände QR-Codes',
    'NAVBAR_PAGES.Label_Overview': 'Übersicht',
    'NAVBAR_PAGES.Label_Logs': 'Logs',
    'NAVBAR_PAGES.Label_Users': 'Benutzer',
    'NAVBAR_PAGES.Label_UserGroups': 'Benutzergruppen',
    'NAVBAR_PAGES.Label_GroupUsers': 'Gruppenmitglieder',
    'NAVBAR_PAGES.Label_Crafts': 'Gewerke',
    'NAVBAR_PAGES.Label_Jobs': 'Jobs',
    'NAVBAR_PAGES.Label_Keys': 'Schlüssel',
    'NAVBAR_PAGES.Label_Stands': 'Stände',
    'NAVBAR_PAGES.Label_Halls': 'Hallen',
    'NAVBAR_PAGES.Label_Documents': 'Dokumente',
    'NAVBAR_PAGES.Label_Furnishings': 'LM Ausstattung',
    'BREADCRUMBS.Label_Logs': 'Logs',
    'BREADCRUMBS.Label_User': 'Benutzer',
    'BREADCRUMBS.Label_Users': 'Benutzer',
    'BREADCRUMBS.Label_UserGroups': 'Benutzergruppen',
    'BREADCRUMBS.Label_GroupUsers': 'Gruppenmitglieder',
    'BREADCRUMBS.Label_Crafts': 'Gewerke',
    'BREADCRUMBS.Label_Jobs': 'Jobs',
    'BREADCRUMBS.Label_Keys': 'Schlüssel',
    'BREADCRUMBS.Label_Stands': 'Stände',
    'BREADCRUMBS.Label_Halls': 'Hallen',
    'BREADCRUMBS.Label_Exhibitions': 'Veranstaltungen',
    'BREADCRUMBS.Label_Furnishings': 'Ausstattung',
    'BREADCRUMBS.Label_Subcontractors': 'Subunternehmer',
    'BREADCRUMBS.Label_LastMin': 'Last Minute',
    'BREADCRUMBS.Label_Create': 'erstellen',
    'BREADCRUMBS.Label_Documents': 'dokumente',
    'PAGES.Title_Dashboard': 'Dashboard',
    'PAGES.Title_Users': 'Benutzer',
    'PAGES.Title_CreateUser': 'Benutzer erstellen',
    'PAGES.Title_CreateGroupUser': 'Benutzergruppe erstellen',
    'PAGES.Title_UserGroups': 'Benutzergruppen',
    'PAGES.Title_Subcontractors': 'Subunternehmer',
    'PAGES.Title_SubcontractorCreate': 'Subunternehmer erstellen',
    'PAGES.Title_CreateStand': 'Stand erstellen',
    'PAGES.Title_LastMinJobs': 'Last Minute Jobs',
    'PAGES.Title_CreateHall': 'Halle erstellen',
    'PAGES.Title_Exhibitions': 'Veranstaltungen',
    'PAGES.Title_CreateExhibitions': 'Veranstaltung erstellen',
    'PAGES.Text_AllExisting': 'Alle aktiven Last-Minute-Aufträge des Standes',
    'PAGES.Text_NoExisting': 'Es gibt keine aktiven Last-Minute-Aufträge für diesen Stand',
    'PAGES.Text_404': 'Die Seite, die du suchst, existiert leider nicht.',
    'CRAFTS.label_Logistics': 'Logistik { num }',
    'CRAFTS.label_Floor': 'Boden { num }',
    'CRAFTS.label_Assembly': 'Montage { num }',
    'CRAFTS.label_Electrics': 'Elektrik { num }',
    'CRAFTS.label_Graphic': 'Grafik { num }',
    'CRAFTS.label_Furniture': 'Möbel { num }',
    'CRAFTS.label_Multimedia': 'multimedia { num }',
    'CRAFTS.label_Cleaning': 'Reinigung { num }',
    'CRAFTS.label_Additional': 'Sonstiges { num }',
    'CRAFTS.label_MpControl': 'MP Kontroll',
    'CRAFTS.label_Protocol': 'Protokoll',
    'CRAFTS.label_Service': 'Service { num }',
    'DATE.Time_Zone': 'de-de',
};

export default deutsch;