import JobEditForm from "./job-edit-form";
import JobFiltersForm from "./job-filters-form";
import JobLastMinuteForm from "./job-last-minute-form";
import JobFormItems from "./fields";


import './forms.scss';

const JobForms = {
    Fields  : JobFormItems,
    Edit: JobEditForm,
    LastMinute: JobLastMinuteForm,
    Filter: JobFiltersForm
};

export default JobForms;
