import React, { useState, useEffect } from "react";

import { Input, Button, Form } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import JobForms from '../forms';
import Icons from 'components/icons';
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;


const JobTableFilter = ({ standID, setSearchText, setFilters = false, craftJobsCount = false }) => {

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState({ craftJobsCount });

    const [ count, setCount ] = useState();

    const filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {
        if (setFilters)
        {
            setFilters(gqlBuilderWhere(filterList));
            setCount(filterList);
        }
    }, []);
    /*eslint-enable */


    return (
        <>
            <Search
                className="filter-search"
                prefix={ <SearchOutlined /> }
                placeholder={ Localize({ children: "SEARCH.Input_Placeholder_Table_Stand" }).props.children }
                onChange={ (e) => setSearchText(e.target.value) }
            />

            { setFilters &&
                <Form
                    key="filters-form"
                    className="clear-css-prop table-filter-form"
                    layout="vertical"
                    form={ form }>

                    <DrawerStandart
                        width="287"
                        extraClass={ 'modal-form filters' }
                        modalButton={
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title"><Localize>FORMS.Title_Text_Filters</Localize></strong>

                        <JobForms.Filter
                            setCount={ setCount }
                            setFilters={ setFilters }
                            standID={ standID }
                            setFilterList={ setFilterList }
                            filterList={ filterList }
                            gqlBuilderWhere={ gqlBuilderWhere }
                            form={ form } />

                    </DrawerStandart>

                    <Button
                        type="link"
                        onClick={ () => {
                            form.resetFields();
                            setFilterList({});
                            setCount({});
                            setFilters(gqlBuilderWhere({}));
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );
};

export default JobTableFilter;
