import { useMutation } from "@apollo/client";
import { EVENT_ATTCHMENT_ORDER_SET } from "graphql/mutation/event-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationEventAttachmentOrderSet = () => {

    const [ _setAttachmentOrderSet, { loading } ] = useMutation(EVENT_ATTCHMENT_ORDER_SET,
        {
            update(cache, { data }) {

                const {
                    attachmentsOrderSet: {
                        label,
                        message,


                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                })
            },

            onError(error) {
                errorNotification(error);
            },

        }
    );


    return {
        _setAttachmentOrderSet,
        loadingMutationAttachmentOrderSet: loading,
    }
};

export default useMutationEventAttachmentOrderSet;

