import { gql } from '@apollo/client';


export const LOGS_USER_REPORT = gql`
  mutation LogsUserReportExel($user_id: ID!) {
    logsUserReportExel(user_id: $user_id) {
      label
      message
      file
    }
  }`;


export const LOGS_STAND_REPORT = gql`
  mutation LogsStandReportExel($stand_id: ID!) {
    logsStandReportExel(stand_id: $stand_id) {
      label
      message
      file
  }
}`;







