import { Localize } from "components/service";

const jobBasePath = '/jobs';
const jobBasePathLastMinute = '/last-minute';


const jobTypes = [
   {
      "label": <Localize>JOB.Type_Text_Claim</Localize>,
      "value": "claims",
   },
   {
      "label": <Localize>JOB.Type_Text_CustomerClaim</Localize>,
      "value": "customer_claims",
   },
   {
      "label": <Localize>JOB.Type_Text_LastMinute</Localize>,
      "value": "last_minutes",
   },
   {
      "label": <Localize>JOB.Type_Text_Damage</Localize>,
      "value": "damages",
   },
   {
      "label": <Localize>JOB.Type_Text_ProtocolDefect</Localize>,
      "value": "protocol_defects",
   }
];


const jobStatus = [
   {
      "label": <Localize>JOB.Status_Text_Open</Localize>,
      "value": "waiting",
   },
   {
      "label": <Localize>JOB.Status_Text_InProgress</Localize>,
      "value": "in_progress",
   },
   {
      "label": <Localize>JOB.Status_Text_Completed</Localize>,
      "value": "completed",
   }
];



const JobConst = {
   basePath: jobBasePath,
   basePathLastMinute: jobBasePathLastMinute,
   jobTypes,
   jobStatus,
};

export default JobConst;









