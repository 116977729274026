import { useMutation } from "@apollo/client";
import { useHistory } from 'react-router-dom';
import { EVENT_IMPORT_REIMPORT } from "graphql/mutation/event-gql";
import { GET_EVENTS, GET_EVENT } from "graphql/query/event-gql";
import Event from "components/events";

import { successNotification, errorNotification } from "components/request-result";


const useMutationEventImportReimport = ({ variables, eventID }) => {

    const history = useHistory();

    const query = eventID ? GET_EVENT : GET_EVENTS;

    variables = eventID ? { id: eventID } : variables;

    const [ _setEventImportReimport, { data, loading } ] = useMutation(EVENT_IMPORT_REIMPORT,
        {
            update(cache, { data }) {

                const {
                    eeImportOrReImport: {
                        label,
                        message,
                        //eslint-disable-next-line
                        sync_info
                    }
                } = data;

                eventID && history.push(`${ Event.Const.basePath }/${ eventID }`);

                successNotification({
                    title: label,
                    description: message
                })
            },


            onError(error) {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: query,
                    variables
                },
            ]
        }
    );


    return {
        _setEventImportReimport,
        dataMutationEventImportReimport: data,
        loadingMutationEventImportReimport: loading,
    }
};

export default useMutationEventImportReimport;

