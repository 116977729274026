import { gql } from '@apollo/client';

export const FURNISHING_CREATE = gql`
    mutation FurnishingCreate(
        $input: FurnishingFieldsInput!
    ) {
        furnishingCreate(
            input: $input
        ){
            label
            message
            furnishing {
              id
              title
              sku
              price
              qty
              ee_id
              created_at
              updated_at
            }
        }
    }
`;


export const FURNISHING_UPDATE = gql`
    mutation FurnishingUpdate(
        $input: FurnishingFieldsInput!
    ) {
        furnishingUpdate(
            input: $input
        ){
            label
            message
            furnishing {
              id
              title
              sku
              price
              qty
              ee_id
              created_at
              updated_at
            }
        }
    }
`;


export const FURNISHING_IMPORT_REIMPORT = gql`
    mutation FurnishingImportReImport(
        $ee_id: ID!
        $file: Upload!
    ) {
        furnishingImportReImport(
            ee_id: $ee_id
            file: $file
        ) {
        label
        message
        }
    }
`;


export const FURNISHING_UPLOAD_FILE = gql`
    mutation FileUpload($input: FileUpload) {
        fileUpload(input: $input) {
        label
        message
        }
    }
`;


export const FURNISHING_DELETE = gql`
    mutation FurnishingDelete($id: ID!) {
        furnishingDelete(id: $id) {
        label
        message
        }
    }
`;