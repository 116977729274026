import React from "react";
import { Link } from "react-router-dom";
import Icons from "components/icons";
import { Localize } from "components/service";


import Subcontractor from "components/subcontractor";

const CraftLinkField = ({ craft }) => {

    return (
        <>
            { craft?.subcontractor?.id ?

                <Link
                    to={ {
                        pathname: `${ Subcontractor.Const.basePath }/${ craft?.subcontractor.id }`,
                        state: {
                            breadcrumbSlug: {
                                id: craft?.subcontractor.id,
                                label: craft?.subcontractor.title
                            }
                        }
                    } }>
                    <strong>{ craft?.subcontractor.title }</strong>
                </Link> : <strong className="unassigned color-red"><Icons.Attention /> <Localize>GLOBAL.Link_Text_Unassigned</Localize></strong>
            }
        </>

    );

};

export default CraftLinkField;