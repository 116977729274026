import EventConst from "components/events/event-const";

const hallBasePath    = '/halls';
const hallParrentPath = EventConst.basePath;

const HallConst = {
    parrentPath : hallParrentPath,
    basePath    : hallBasePath
};

export default HallConst;