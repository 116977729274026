import React from "react";
import { Link } from "react-router-dom";

import Events from 'components/events';
import Stands from 'components/stands';
import Job from 'components/job';


const CraftViewField = ({ craft }) => {


    return (

        <div className='view-box-field'>
            { craft?.jobs_count ?
                <Link
                    to={ {
                        pathname: `${ Events.Const.basePath }/${ craft?.ee_id }${ Stands.Const.basePath }/${ craft?.stand_id }${ Job.Const.basePath }`,
                        state: {
                            column: "CRAFT_TYPE",
                            operator: "EQ",
                            value: craft?.type
                        }
                    } }
                >
                    <span className="count">{ craft?.jobs_count }</span>
                    <span className="btn-view">view</span>
                </Link> : <strong>&mdash;</strong> }
        </div>
    );

};

export default CraftViewField;