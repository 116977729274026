import Event from "components/events";
import Stands from "components/stands";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";

const basePath = Stands.Const.basePath;


const StandKeysSubPage = ({ event, stand }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ Event.Const.basePath }`
        },
        {
            label: event?.title,
            path: `${ Event.Const.basePath }/${ event?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }`
        },
        {
            label: stand?.exhibitor,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }/${ stand?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Keys" }).props.children,
            path: `${ Event.Const.basePath }/${ event?.id }${ basePath }/${ stand?.id }/keys`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    return (

        <div className="row-grid col-lg-2">
            <div className="col-left">
                <Stands.Forms.Keys stand={ stand } />
            </div>
        </div>
    )
}


export default StandKeysSubPage;
