import React from "react";
import { Link, useHistory } from "react-router-dom";

import SubcontractorConst from '../subcontractor-const';


const SubcontractorTitleLinkField = ({ subcontractor }) => {

    let history = useHistory().location.pathname;


    return (
        <Link
            to={ {
                pathname: `${ SubcontractorConst.basePath }/${ subcontractor.id }`,
                state: {
                    breadcrumbSlug: {
                        id: subcontractor.id,
                        label: subcontractor.title,
                    },
                    path: history,
                }
            } }
        >
            <strong>{ subcontractor.title }</strong>
        </Link>
    );

};

export default SubcontractorTitleLinkField;