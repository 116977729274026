import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_STANDS_CURSOR } from "graphql/query/stand-gql";

import Stands from "components/stands";
import Events from "components/events";
import Hall from "components/hall";

import { Tables } from "components/layout";
import { Localize } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";


const basePath = Events.Const.basePath;
const tableHelper = Hall.Helpers.TableStands;


const HallStandsSubPage = ({ hall }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props.children,
            path: `${ basePath }`
        },
        {
            label: hall?.exhibitionEvent?.title,
            path: `${ basePath }/${ hall?.ee_id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Halls" }).props.children,
            path: `${ basePath }/${ hall?.ee_id }${ Hall.Const.basePath }`
        },
        {
            label: `${ hall?.title }`,
            path: `${ basePath }/${ hall?.ee_id }${ Hall.Const.basePath }/${ hall?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_Stands" }).props.children,
            path: `${ basePath }/${ hall?.ee_id }${ Hall.Const.basePath }/${ hall?.id }${ Stands.Const.basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const routeUrl = `${ basePath }/${ hall?.ee_id }${ Hall.Const.basePath }/${ hall?.id }${ Stands.Const.basePath }`;
    let match = useRouteMatch(`${ routeUrl }/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");
    const [ filters, setFilters ] = useState();
    const [ arrStands, setArrStands ] = useState([]);
    const [ variables, setVariables ] = useState({});

    const rowSelection = {
        onChange: (selectedRowKeys) => setArrStands(selectedRowKeys)
    };


    return (
        <Tables.Cursor
            model="standsCursor"
            query={ GET_STANDS_CURSOR }
            varSet={ {
                perPage: 30,
                ee_id: +hall?.ee_id,
                hall_id: +hall?.id,
            } }
            tableLayout="auto"
            routeUrl={ routeUrl }
            currentPage={ match ? parseInt(match.params.pageNum) : 1 }
            searchText={ searchText }
            objFilter={ filters }
            setVariables={ setVariables }
            tableHelper={ tableHelper }
            rowSelection={ rowSelection }
        >
            <div className="table-action-bar">

                <div className="col">
                    <Stands.Filters.Table setSearchText={ setSearchText } setFilters={ setFilters } />
                    <Stands.Actions.Dropdown event={ hall?.exhibitionEvent } arrStands={ arrStands } variables={ variables } />
                </div>

                <div className="col"></div>
            </div>
        </Tables.Cursor>
    );
};

export default HallStandsSubPage;