import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function LastMinuteRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.LastMinute />
            </Route>
            <Route path={ `${ path }/page/:pageNum` } exact>
                <Pages.LastMinute />
            </Route>
        </Switch>
    );
}


